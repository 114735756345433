import PropTypes from 'prop-types';

import { useEffect } from 'react';

import {
  Button, Form, Input, Card, Row, Col,
} from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import styles from '../../Login/index.module.scss';

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

// eslint-disable-next-line max-len
const title = 'Bitte geben Sie Ihre E-Mail-Adresse ein,\n um einen Link zum Zurücksetzen des Passworts zu erhalten.';

const ResetPasswordForm = ({
  onSubmit, formRef, submitting, initialValues,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    formRef.current = form;
  }, [formRef, form]);

  return (
    <Form
      form={form}
      className={styles.authForm}
      {...formItemLayout}
      initialValues={{
        remember: true,
        ...initialValues,
      }}
      onFinish={onSubmit}
    >
      <Card
        title={`${title}`}
        bordered
        level={4}
        className={styles.title}
      >
        <Row gutter={[16, 16]} className={styles.row}>
          <Col span={24}>
            <Form.Item
              className={styles.formItemAlignment}
              name='email'
              rules={
                [
                  {
                    required: true,
                    message: 'Bitte geben Sie ihre E-Mail-Adresse ein!',
                  },
                  {
                    type: 'email',
                    message: 'Die Eingabe ist keine gültige E-Mail!',
                  },
                ]
              }
            >
              <Input
                placeholder='Email'
                prefix={<MailOutlined className='icon' />}
                className={styles.input}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              className={styles.formItemAlignment}
              style={{ textAlign: 'center' }}
            >
              <Link to='/login'>zurück zum Login</Link>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              className={styles.formItemAlignment}
            >
              <Button type='primary' htmlType='submit' className={styles.buttonAlign} loading={submitting}>
                Senden
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  formRef: PropTypes.object,
  initialValues: PropTypes.object,
};
ResetPasswordForm.defaultProps = {
  onSubmit: () => {},
  submitting: false,
  formRef: { current: null },
  initialValues: {},
};

export default ResetPasswordForm;
