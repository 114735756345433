import {
  Col, message, PageHeader, Row, Spin,
} from 'antd';

import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';

import { useHistory, useParams } from 'react-router-dom';
import { fetchAllQuestions } from '../../../store/questions/actions';
import { fetchAllAnswers } from '../../../store/answers/actions';

import AnswerQuestion from './AnswerQuestion/AnswerQuestion';

import styles from './index.module.scss';
import Stepper from '../Stepper/Stepper';

const UserAnswers = () => {
  const [step, setStep] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [lastQuestion, setLastQuestion] = useState({});
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [category, setCategory] = useState({});
  const [last, setLast] = useState(false);

  const history = useHistory();

  const { categorySlug, assessmentSlug, userSlug } = useParams();
  const dispatch = useDispatch();

  const back = useCallback(() => {
    const currentStep = step - 1;
    setStep(currentStep);
    const current = questions[currentStep];
    if (current) {
      setCurrentQuestion(current);
    } else {
      const state = history.location.state ? history.location.state : null;
      history.push({ pathname: `/wizard/${assessmentSlug}/${userSlug}` }, state);
    }
  }, [assessmentSlug, history, questions, step, userSlug]);

  const next = useCallback(() => {
    const currentStep = step + 1;
    setStep(currentStep);
    const current = questions[currentStep];
    if (current) {
      setCurrentQuestion(current);
    } else {
      const state = history.location.state ? history.location.state : null;
      history.push({ pathname: `/wizard/${assessmentSlug}/${userSlug}` }, state);
    }
  }, [assessmentSlug, history, questions, step, userSlug]);

  const allQuestions = useMemo(() => {
    const arr = questions.map((item) => {
      if (item.is_skipped && item?.answer === 'false' && item.type === 'BOOLEAN') {
        return {
          ...item,
          status: 'finish',
        };
      }
      if (item.is_skipped && item.type !== 'BOOLEAN') {
        return {
          ...item,
          status: 'skip',
        };
      }
      if (!item.is_answered) {
        return {
          ...item,
          status: 'wait',
        };
      }
      if (item.is_answered) {
        return {
          ...item,
          status: 'finish',
        };
      }
      return item;
    });
    return arr.map((item) => (item.slug === currentQuestion?.slug ? { ...currentQuestion, status: 'process' } : item));
  }, [questions, currentQuestion]);

  const statuses = useMemo(() => allQuestions.map(({ status }) => status), [allQuestions]);

  const titleContent = (
    <div className={styles.wrapper}>
      <Row className={styles.scroll}>
        <Row>
          <Col className={styles.title}>
            <h1>{category.name || ''}</h1>
          </Col>
          <Stepper statuses={statuses} />
        </Row>
      </Row>
    </div>
  );

  useEffect(() => {
    if ((lastQuestion && currentQuestion) && lastQuestion?.slug === currentQuestion?.slug) {
      setLast(true);
    } else {
      setLast(false);
    }
  }, [currentQuestion, lastQuestion]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const arr = [];
        const allQ = await dispatch(fetchAllQuestions({ assessmentSlug, categorySlug }))
          .then((res) => res.data.sort((a, b) => a.order - b.order));
        const answers = await dispatch(fetchAllAnswers(assessmentSlug)).then((res) => res.data);
        if (answers.length) {
          const filtered = answers.filter((item) => item.user === userSlug);
          const slugs = filtered.map((item) => item.question);
          allQ.forEach((question) => {
            if (slugs.includes(question.slug)) {
              const searchA = filtered.find((item) => item.question === question.slug);
              arr.push({ ...searchA, ...question, answerSlug: searchA.slug });
            } else {
              arr.push({
                ...question, is_skipped: false, is_answered: false, answer: '',
              });
            }
          });
        } else {
          allQ.map((item) => ({
            ...item, is_skipped: false, is_answered: false, answer: '',
          })).forEach((item) => arr.push(item));
        }
        const ids = [...new Set(
          Array.prototype.concat.apply([], arr.filter((question) => question.is_skipped)
            .map(({ logic }) => logic.skip)),
        )];
        const result = arr.map((item) => {
          if (ids.includes(item.slug)) {
            return {
              ...item,
              is_skipped: true,
            };
          }
          return item;
        });
        const lastQ = result.find((item, ind) => ind === result.length - 1);
        setQuestions(result || []);
        setCurrentQuestion(result?.[0] || {});
        setCategory(result?.[0]?.category || {});
        setLastQuestion(lastQ || {});
      } catch (e) {
        setLoading(false);
        return message.error('General server error');
      }
      return setLoading(false);
    })();
    return () => {
      setQuestions([]);
      setCurrentQuestion({});
      setLoading(false);
    };
  }, [assessmentSlug, categorySlug, dispatch, userSlug]);

  return (
    <div>
      <Spin spinning={loading}>
        <PageHeader title={titleContent} className={styles.pageHeader} />
        <AnswerQuestion
          next={next}
          back={back}
          question={currentQuestion}
          isLast={last}
        />
      </Spin>
    </div>
  );
};

export default UserAnswers;
