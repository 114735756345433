import PropTypes from 'prop-types';

import {
  Col, Row, Tag, Tooltip,
} from 'antd';

const Tags = ({
  tags, deleteUser, category,
}) => (
  <Row gutter={[1, 2]} align='middle'>
    {tags.map((tag) => {
      const isLongTag = tag.label.length > 20;
      const tagElem = (
        <Col key={tag.value}>
          <Tag
            visible
            closable
            onClose={() => deleteUser({ [category]: tag })}
          >
            <span>
              {isLongTag ? `${tag.label.slice(0, 20)}...` : tag.label}
            </span>
          </Tag>
        </Col>
      );

      return isLongTag ? (
        <Tooltip title={tag.label} key={tag.value}>
          {tagElem}
        </Tooltip>
      ) : (
        tagElem
      );
    })}
  </Row>
);

Tags.propTypes = {
  tags: PropTypes.array,
  deleteUser: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
};
Tags.defaultProps = {
  tags: [],
};

export default Tags;
