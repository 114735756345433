import { Spin } from 'antd';

import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import queryString from 'query-string';

import QuestionnaireConfiguration from '../../components/common/QuestionnaireConfiguration/QuestionnaireConfiguration';
import { loadingSelector, templatesSelector } from '../../store/assessments/selectors';

const Questionnaire = () => {
  const [props, setProps] = useState({});
  const { customerSlug } = useParams();
  const location = useLocation();
  const templates = useSelector(templatesSelector);
  const isLoading = useSelector(loadingSelector);

  const categorySlug = queryString.parse(location.search).category;

  useEffect(() => {
    const obj = templates.find((item) => String(item.slug) === categorySlug);
    setProps({ ...obj, id: customerSlug || '', category: categorySlug });
  }, [categorySlug, templates, customerSlug]);

  return (
    <Spin spinning={isLoading}>
      <QuestionnaireConfiguration {...props} />
    </Spin>
  );
};

export default Questionnaire;
