import { FETCH_CATEGORIES, FETCH_CATEGORY, SET_DEFAULT_CATEGORIES_STATE } from './types';

export const fetchCategories = () => ({
  type: FETCH_CATEGORIES,
  request: {
    method: 'GET',
    url: 'categories/',
  },
});

export const fetchCategory = (slug) => ({
  type: FETCH_CATEGORY,
  request: {
    method: 'GET',
    url: `categories/${slug}`,
  },
});
export const setDefaultCategoriesState = () => ({
  type: SET_DEFAULT_CATEGORIES_STATE,
});
