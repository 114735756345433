import {
  Input, Row, Form, Col,
} from 'antd';

import styles from '../../index.module.scss';

const { TextArea } = Input;
const { Item } = Form;

const Formulation = () => (
  <>
    <Row justify='center'>
      <Col span={11}>
        <Item name='identifier' label='Identifier' rules={[{ required: true, message: 'Identifier is required' }]}>
          <Input />
        </Item>
      </Col>
    </Row>
    <Row justify='center'>
      <Col span={11}>
        <Item name='question' label='Frage/Aussage' rules={[{ required: true, message: 'Formulation is required' }]}>
          <TextArea style={{ minHeight: '180px' }} />
        </Item>
      </Col>
    </Row>
    <Row justify='center'>
      <Col span={11}>
        <Item
          name='hint'
          label={(
            <>
              <span>Hinweis</span>
              <span className={styles.optionText}>(optional)</span>
            </>
)}
        >
          <TextArea style={{ minHeight: '180px' }} />
        </Item>
      </Col>
    </Row>

  </>
);

export default Formulation;
