import PropTypes from 'prop-types';

import {
  useCallback, useMemo,
} from 'react';

import {
  Card, Col, PageHeader, Row, Button, Table, Modal, Form, Upload,
} from 'antd';
import {
  PlusOutlined, UploadOutlined,
} from '@ant-design/icons';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';

import { loadingSelector } from '../../../store/assessments/selectors';

import styles from '../../../views/Questionnaire/index.module.scss';
import EditQuestion from '../EditQuestion/EditQuestion';

import { TITLES } from '../../../utils/constants';

import SkipForm from './components/SkipForm';
import AddNewQuestion from '../../../views/Questionnaire/components/AddNewQuestion';
import useColumns from './hooks/useColumns';
import useController from './hooks/useController';

const { Item } = Form;

const QuestionnaireConfiguration = ({
  category, name, types, children, hideWeight, onChangeState,
}) => {
  const {
    onConfirmMode,
    onEditMode,
    onSkipMode,
    onCancelModal,
    saveEditedQuestion,
    updated,
    form,
    visible,
    modal,
    state,
    ids,
    handleChangeWeighting,
    handleChangeVisibleQuestion,
    findLastSkipQuestion,
    changeVersionTemplate,
    onSort,
    addNewQ,
    onFinish,
    skipFormRef,
    formRef,
    delQuestion,
    onChange,
    payload,
    handleChangeVisible,
  } = useController({ types, category, onChangeState });

  const columns = useColumns({
    children,
    ids,
    handleChangeWeighting,
    handleChangeVisibleQuestion,
    onEditMode,
    onSkipMode,
    findLastSkipQuestion,
  });
  const isLoading = useSelector(loadingSelector);

  const SortableItem = sortableElement((props) => <tr {...props} />);
  const SortableContainer = sortableContainer((props) => <tbody {...props} />);

  // eslint-disable-next-line consistent-return,max-len
  const onSortEndPerformance = useCallback(({ oldIndex, newIndex }) => onSort('PERFORMANCE_CHECK', oldIndex, newIndex), [onSort]);

  // eslint-disable-next-line consistent-return,max-len
  const onSortEndStatusAnalyse = useCallback(({ oldIndex, newIndex }) => onSort('DEEP_DIVE', oldIndex, newIndex), [onSort]);

  const DraggableBodyRowStatusAnalyse = useCallback(({ ...restProps }) => {
    const index = state.DEEP_DIVE.findIndex((x) => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  }, [state]);

  const DraggableBodyRowPerformanceCheck = useCallback(({ ...restProps }) => {
    const index = state.PERFORMANCE_CHECK.findIndex((x) => x.index === restProps['data-row-key']);
    return <SortableItem key={index} index={index} {...restProps} />;
  }, [state]);

  const DraggableContainerPerformance = useCallback((props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging'
      onSortEnd={onSortEndPerformance}
      {...props}
    />
  ), [onSortEndPerformance]);

  const DraggableContainerStatusAnalyse = useCallback((props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging'
      onSortEnd={onSortEndStatusAnalyse}
      {...props}
    />
  ), [onSortEndStatusAnalyse]);

  const containers = useMemo(() => ({
    PERFORMANCE_CHECK: DraggableContainerPerformance,
    DEEP_DIVE: DraggableContainerStatusAnalyse,
  }), [DraggableContainerPerformance, DraggableContainerStatusAnalyse]);

  const components = useMemo(() => ({
    PERFORMANCE_CHECK: DraggableBodyRowPerformanceCheck,
    DEEP_DIVE: DraggableBodyRowStatusAnalyse,
  }), [DraggableBodyRowPerformanceCheck, DraggableBodyRowStatusAnalyse]);

  const renderContent = useCallback((item, index) => (
    <Col span={children ? 11 : 12} key={index.toString()} data-cy={`questions-${item.type}`}>
      <Card title={TITLES[item.type]} className={styles.card}>
        <Row gutter={[16, 16]}>
          {!children
          && (
            <Col span={24} className={styles.actionButton}>
              <Button
                onClick={() => addNewQ(item.type)}
                type='primary'
                icon={<PlusOutlined />}
              >
                neue Frage
              </Button>
            </Col>
          )}
          <Col span={24}>
            <Table
              dataSource={state[item.type]}
              columns={Object.keys(hideWeight).length && !hideWeight[item.type]
                ? columns.filter((i) => i.key !== 'logic.weight')
                : columns}
              rowKey='index'
              pagination={false}
              components={{
                body: {
                  wrapper: containers[item.type],
                  row: components[item.type],
                },
              }}
            />
          </Col>
        </Row>
      </Card>
    </Col>
  ), [hideWeight, containers, components, children, columns, state, addNewQ]);

  const headline = (
    <>
      <Row justify='end'>
        <Button
          style={{ position: 'absolute', top: '10px' }}
          type='primary'
          size='small'
          onClick={onConfirmMode}
        >
          Neue Revision erstellen
        </Button>
      </Row>
      <Row justify='space-between'>
        <Col>Fragebogenkonfiguration</Col>
        <Col>{name || ''}</Col>

      </Row>
    </>
  );

  const skipFormInitialValues = useMemo(() => {
    if (modal.type === 'skip') {
      return (ids?.[modal.data.currentQuestion?.assessment_type] || [])
        .reduce((a, c) => Object.assign(a, { [c]: true }), {});
    }
    return {};
  },
  [ids, modal.type, modal.data.currentQuestion?.assessment_type]);

  return (
    <div>
      {!children && <PageHeader title={headline} className={styles.pageHeader} /> }
      {!visible
        ? (
          <Row
            justify='center'
            className={classNames(styles.cardContainer,
              { [styles.containerProjectOverview]: children })}
          >
            <Row>
              {children}
              {types?.map(renderContent)}
            </Row>
            {modal.type === 'confirm' && (
              <Modal
                maskClosable={!isLoading}
                cancelButtonProps={{ loading: isLoading, disabled: isLoading }}
                okButtonProps={{ loading: isLoading, disabled: isLoading }}
                centered
                title='Neue Revision erstellen'
                visible={modal.visible}
                onOk={changeVersionTemplate}
                onCancel={() => {
                  onCancelModal();
                  form.resetFields();
                }}
              >
                <span>
                  Wollen Sie die aktuellen Änderungen speichern und
                  eine neue Revision der Fragebogenvorlage erstellen?
                </span>
                <Row style={{ marginTop: '10px' }}>
                  <Form form={form}>
                    <Item
                      name='file'
                      valuePropName='file'
                      style={{ marginBottom: 0 }}
                    >
                      <Upload
                        multiple={false}
                        maxCount={1}
                        accept='.pdf'
                        beforeUpload={() => false}
                      >
                        <Button icon={<UploadOutlined />}>Hochladen</Button>
                      </Upload>
                    </Item>
                  </Form>
                </Row>
              </Modal>
            )}
            {modal.type === 'skip' && (
              <Modal
                visible={modal.visible}
                centered
                title={modal.data.currentQuestion.title}
                onCancel={onCancelModal}
                footer={null}
                destroyOnClose
              >
                <SkipForm
                  skipOptions={modal.data.lists}
                  onFinish={onFinish}
                  initialValues={skipFormInitialValues}
                  formRef={skipFormRef}
                />
              </Modal>
            )}
            {modal.type === 'edit' && (
              <EditQuestion
                updated={updated}
                formRef={formRef}
                deleteQuestion={delQuestion}
                data={modal}
                onCancel={onCancelModal}
                onSave={saveEditedQuestion}
                onChange={onChange}
              />
            )}
          </Row>
        )
        : <AddNewQuestion payload={payload} handleChangeVisible={handleChangeVisible} />}
    </div>
  );
};

QuestionnaireConfiguration.propTypes = {
  types: PropTypes.array,
  configuration: PropTypes.object,
  category: PropTypes.string,
  children: PropTypes.any,
  name: PropTypes.string,
  hideWeight: PropTypes.object,
  onChangeState: PropTypes.func,
};

QuestionnaireConfiguration.defaultProps = {
  types: [],
  configuration: {},
  category: '',
  children: null,
  name: '',
  hideWeight: {},
  onChangeState: () => {},
};

export default QuestionnaireConfiguration;
