import { message } from 'antd';
import { mainInstance } from '../index';

export function updateTemplatesPayload(templates, modifiedOrders, type, categorySlug) {
  return templates.map((item) => {
    if (item.slug === categorySlug) {
      return {
        ...item,
        types: item.types.map((i) => {
          if (i.type === type) {
            return {
              ...i,
              questions: modifiedOrders.map(({ index, category, ...res }) => res),
            };
          }
          return i;
        }),
      };
    }
    return item;
  });
}

export function modifyQuestion(templates, data) {
  return templates.map((category) => {
    if (category.slug === data.category) {
      return {
        ...category,
        types: category.types.map((assessment) => {
          if (assessment.type === data.assessment_type) {
            return {
              ...assessment,
              questions: assessment.questions.map((question) => {
                if (question.slug === data.slug) {
                  return {
                    ...question,
                    title: data.title,
                    hint: data.hint,
                    identifier: data?.identifier ? data.identifier : question.identifier,
                    question: data.question,
                    logic: data.logic,
                    is_visible: data.is_visible,
                  };
                }
                return question;
              }),
            };
          }
          return assessment;
        }),
      };
    }
    return category;
  });
}
export function findMatch(search, allIds) {
  const arr = [];
  for (let i = 0; i < allIds.length; i += 1) {
    for (let z = 0; z < search.length; z += 1) {
      if (allIds[i] === search[z]) {
        arr.push(i);
      }
    }
  }
  return arr;
}

export function responseHandler(response, callback) {
  if (response.error) {
    const errors = [];
    if (response.error.response?.status === 500) {
      message.error('General server error');
      return;
    }
    if (Array.isArray(response.error?.response?.data)) {
      response.error?.response?.data.map((err) => errors.push(err));
      message.error(`${errors.join('\n')}`);
      return;
    }
    if (response.error?.response?.data?.error) {
      message.error(response.error.response.data.error);
      return;
    }
    if (!response.error?.response?.data?.error && Object.keys(response.error?.response?.data || {}).length) {
      Object.keys(response.error.response.data).forEach((key) => {
        errors.push(`${key}: ${response.error.response.data[key]},`);
      });
      message.error(`${errors.join('\n')}`);
      return;
    }
    if (!Array.isArray(response.error?.response?.data)
      && (!Object.keys(response.error?.response?.data || {}).length || !!response.error?.response?.data?.error)) {
      message.error('General server error');
    }
  } else if (callback) {
    callback();
  }
}

export function reverseTypes(data) {
  return data.map((category) => {
    const { types } = category;
    const index = types.findIndex((item) => item.type === 'PERFORMANCE_CHECK');
    const item = types.find((i) => i.type === 'DEEP_DIVE');
    if (index === 0) {
      return category;
    }
    const modified = types.splice(index, 1);
    return { ...category, types: modified.concat(item) };
  });
}

export async function fetchAssessmentCategories(assessments, userSlug) {
  const promises = assessments.map((item) => mainInstance.get(`/assessments/${item.slug}/status/${userSlug}/`));
  const settled = await Promise.allSettled(promises);
  const result = settled.filter((item) => item.status === 'fulfilled')
    .map(({ value, ...rest }, idx) => ({
      ...assessments[idx],
      categories: value ? value.data : [],
      ...rest,
    }));
  return result;
}

export function setDefaultValuesAnswerForm(question, setPrimary, setVisible, form) {
  setPrimary({ yes: false, no: false, none: false });
  setVisible(false);
  form.resetFields();
  if (question.answer === 'false') {
    setPrimary({ yes: false, no: true, none: false });
    form.setFieldsValue({ answer: 'false', none: '', reason: question.reason || '' });
  } else if (question.answer === '0' || question.answer === '5') {
    setPrimary({ yes: false, no: false, none: false });
    form.setFieldsValue({
      answer: question.answer, suggestion: question.suggestion || '', none: '', reason: question.reason || '',
    });
    setVisible(true);
  } else if (question.answer === '-1') {
    setPrimary({ yes: false, no: false, none: true });
    form.setFieldsValue({ answer: '', none: '-1', reason: question.reason || '' });
  } else if (question.answer === 'true') {
    setPrimary({ yes: true, no: false, none: false });
    form.setFieldsValue({ answer: 'true', none: '', reason: question.reason || '' });
  } else {
    form.setFieldsValue({ none: '', answer: question.answer || '', reason: question.reason || '' });
  }
}

export function calculateProgress(user) {
  const totalQuestions = user?.total_questions || 0;
  const totalAnswers = user?.answered_questions || 0;
  const result = ((totalAnswers * 100) / totalQuestions) === 0
    ? 0
    : ((totalAnswers * 100) / totalQuestions).toFixed(2);
  return Number(result);
}

export function numberWithCommas(value) {
  if (value === null || value === undefined) {
    return 0;
  }
  const str = value.toLocaleString('de-DE');
  if (str.length > 5) return str;
  const parts = str.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parts.join(',');
}

export const addDynamicClass = (value) => {
  if (value <= 6) {
    return {
      backgroundColor: '#FFF1F0',
      color: 'rgba(255, 77, 79, 1)',
    };
  }
  if (value >= 9) {
    return {
      backgroundColor: '#F6FFED',
      color: 'rgba(82, 196, 26, 1)',
    };
  }
  if (!value) {
    return {
      backgroundColor: '#FAFAFA',
      color: 'rgba(0, 0, 0, 0.85)',
    };
  }
  return {
    backgroundColor: '#FAFAFA',
    color: 'rgba(0, 0, 0, 0.85)',
  };
};
