import thunk from 'redux-thunk';
import { applyMiddleware, createStore, combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import { handleRequests } from '@redux-requests/core';
import { createDriver } from '@redux-requests/axios';

import { mainInstance } from '../utils';
import assessments from './assessments/reducer';
import auth from './auth/reducer';
import company from './company/reducer';
import invite from './invite/reducer';
import categories from './categories/reducer';
import preassessments from './preassessments/reducer';
import answers from './answers/reducer';
import questions from './questions/reducer';

const loggerMiddleware = createLogger();

const { requestsReducer: requests, requestsMiddleware } = handleRequests({
  driver: createDriver(mainInstance),
  onError: (e) => {
    throw e;
  },
});

const rootReducer = combineReducers({
  requests,
  assessments,
  company,
  auth,
  invite,
  categories,
  preassessments,
  answers,
  questions,
});

const middlewares = [
  ...requestsMiddleware,
  thunk,
];

// Disable Logger at server side.
if (process.browser && process.env.NODE_ENV !== 'production') {
  middlewares.push(loggerMiddleware);
}

const store = createStore(
  rootReducer,
  undefined,
  composeWithDevTools(applyMiddleware(...middlewares)),
);

export default store;
