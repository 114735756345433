import {
  Button, Col, Image, Progress, Row, Spin, Typography,
} from 'antd';

import { useEffect, useState } from 'react';

import { UserOutlined } from '@ant-design/icons';

import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import {
  fetchNewAssessment, fetchStatusAssessment,
  fetchUserAssessment,
} from '../../../../store/assessments/actions';

import { newAssessmentSelector } from '../../../../store/assessments/selectors';

import { responseHandler } from '../../../../utils/helpers';

import styles from '../index.module.scss';
import { fetchCategories } from '../../../../store/categories/actions';

const { Title } = Typography;

const UserAssessmentDetail = () => {
  const [user, setUser] = useState({});
  const [assessmentData, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const assessment = useSelector(newAssessmentSelector);

  const history = useHistory();
  const dispatch = useDispatch();
  const { assessmentSlug, userSlug } = useParams();

  const goBack = () => {
    const state = history.location.state ? history.location.state : null;
    history.push({ pathname: `/wizard/${assessmentSlug}` }, state);
  };

  const seeAnswers = (item) => {
    if (item.is_assigned) {
      const state = history.location.state ? history.location.state : null;
      history.push({
        pathname: `/answers/${assessment.company}/${assessmentSlug}/${item.category.slug}/${userSlug}`,
      },
      state);
    }
  };

  const renderCategories = (item, idx) => (
    <Row
      onClick={() => seeAnswers(item)}
      align='center'
      className={classNames(styles.category, { [styles.disabled]: !item.is_assigned })}
      key={idx.toString()}
    >
      <Col>
        <Image
          preview={false}
          src={item.category.image[0].includes('/') ? item.category.image : `/${item.category.image}`}
          alt='icon category'
          width={20}
        />
        <span>{item.category.name}</span>
      </Col>
      <Col>
        {item.is_assigned && (
          <>
            <Progress
              percent={item?.percentage || 0}
              steps={item?.questions_count || 0}
              size='small'
              strokeColor='#1890ff'
            />
            <span className={styles.question}>
              {item?.questions_count || 0}
              /
              {item?.answered_questions_count || 0}
            </span>
          </>
        )}
      </Col>
    </Row>
  );

  const calculateProgress = () => {
    const totalQuestions = user?.total_questions || 0;
    const totalAnswers = user?.answered_questions || 0;
    const result = ((totalAnswers * 100) / totalQuestions) === 0
      ? 0
      : ((totalAnswers * 100) / totalQuestions).toFixed(2);
    return Number(result);
  };

  const renderTitle = () => {
    const name = user?.user?.first_name;
    const lastName = user?.user?.last_name;
    return (name && lastName) ? `${name} ${lastName}` : user?.user?.email;
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      dispatch(fetchNewAssessment(assessmentSlug));
      dispatch(fetchStatusAssessment(assessmentSlug)).then((res) => {
        if (res.error) {
          responseHandler(res);
        }
        const search = res?.data.find((item) => item.user.slug === userSlug);
        if (search) {
          setUser(search);
        }
      });
      const userCategories = await dispatch(fetchUserAssessment(
        { assessmentSlug, userSlug },
      )).then((res) => {
        if (res.error) {
          responseHandler(res);
        }
        return res.data;
      });
      const allCategories = await dispatch(fetchCategories()).then((res) => {
        if (res.error) {
          responseHandler(res);
        }
        return res.data;
      });
      const result = allCategories.reduce((source, category) => {
        const myCategory = userCategories.find((item) => item.category.slug === category.slug);
        if (!myCategory) {
          return source.concat({
            is_assigned: false,
            category: { ...category },
          });
        }
        const item = { ...category };
        if (myCategory) {
          Object.assign(item, {
            ...myCategory, is_assigned: true,
          });
        }
        return source.concat(item);
      }, []);
      setData(result);
      setLoading(false);
    })();
  }, [dispatch, assessmentSlug, userSlug]);

  return (
    <div>
      <Spin spinning={loading}>
        <div className={styles.progressContainer}>
          {!!assessmentData.length && (
            <>
              <Row justify='center'><Title level={3} style={{ textAlign: 'center' }}>{assessment?.title}</Title></Row>
              <Row justify='space-around' className={styles.userAssessmentDetails}>
                <Col span={4}>
                  <UserOutlined className={styles.userIcon} />
                  <Title level={3} style={{ textAlign: 'center' }}>{renderTitle()}</Title>
                  <Progress type='circle' percent={calculateProgress()} style={{ marginTop: '20px' }} />
                </Col>
                <Col span={18}>
                  <Col>
                    {(assessmentData || []).map(renderCategories)}
                  </Col>
                  <Col>
                    <Button
                      onClick={goBack}
                      type='primary'
                    >
                      Zurück
                    </Button>
                  </Col>
                </Col>
              </Row>
            </>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default UserAssessmentDetail;
