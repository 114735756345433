import {
  ADD_COMPANY, GET_COMPANIES, GET_COMPANY, UPDATE_COMPANY,
} from './types';

export const addNewCompany = (data) => ({
  type: ADD_COMPANY,
  request: {
    method: 'POST',
    url: 'companies/',
    data,
  },
});

export const getCompany = (slug) => ({
  type: GET_COMPANY,
  request: {
    method: 'GET',
    url: `companies/${slug}/`,
  },
});

export const updateCompany = (slug, data) => ({
  type: UPDATE_COMPANY,
  request: {
    method: 'PUT',
    url: `companies/${slug}/`,
    data,
  },
});

export const fetchCompanies = () => ({
  type: GET_COMPANIES,
  request: {
    method: 'GET',
    url: 'companies/',
  },
});
