import PropTypes from 'prop-types';

import { Modal } from 'antd';

import styles from './index.module.scss';

const ModalConfirm = ({
  visible, onOk, onCancel, title, text, closable, maskClosable,
}) => (
  <div className={styles.confirmModal}>
    <Modal
      style={{ zIndex: 99999999999 }}
      centered
      title={title}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      closable={closable}
      maskClosable={maskClosable}
    >
      <span>{text}</span>
    </Modal>
  </div>
);

ModalConfirm.propTypes = {
  closable: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  maskClosable: PropTypes.bool,
};
ModalConfirm.defaultProps = {
  closable: true,
  maskClosable: true,
  text: null,
};

export default ModalConfirm;
