import PropTypes from 'prop-types';

import { useEffect } from 'react';

import {
  Button, Form, Input, Card, Row, Col,
} from 'antd';

import styles from '../../Login/index.module.scss';

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const ResetPasswordConfirmForm = ({
  onSubmit, formRef, submitting, initialValues,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    formRef.current = form;
  }, [formRef, form]);

  return (
    <Form
      className={styles.authForm}
      {...formItemLayout}
      initialValues={{
        remember: true,
        ...initialValues,
      }}
      form={form}
      onFinish={onSubmit}
    >
      <Card bordered level={4}>
        <Row gutter={[16, 16]} className={styles.row}>
          <Col span={24}>
            <Form.Item
              className={styles.formItemAlignment}
              label='Neues Passwort'
              name='new_password1'
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie Ihr Passwort ein! ',
                },
              ]}
            >
              <Input.Password
                className={styles.input}
                name='new_password1'
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              className={styles.formItemAlignment}
              label='Bestätige neues Passwort'
              name='new_password2'
              dependencies={['new_password1']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Bitte Password erneut eingeben!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('new_password1') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error('Die beiden Passwörter stimmen nicht überein!'));
                  },
                }),
              ]}
            >
              <Input.Password className={styles.input} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item style={{ textAlign: 'center' }} className={styles.formItemAlignment}>
              <Button type='primary' htmlType='submit' loading={submitting} className={styles.buttonAlign}>
                Senden
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

ResetPasswordConfirmForm.propTypes = {
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  formRef: PropTypes.object,
  initialValues: PropTypes.object,
};
ResetPasswordConfirmForm.defaultProps = {
  onSubmit: () => {},
  submitting: false,
  formRef: { current: null },
  initialValues: {},
};

export default ResetPasswordConfirmForm;
