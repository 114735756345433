import axios from 'axios';

import { BASE_URL, ERROR_STATUSES } from './constants';

import { PublicRoutes } from '../routes';

axios.defaults.withCredentials = true;

export const mainInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json', 'Accept-Language': 'de' },
});

export const refreshInstance = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json', 'Accept-Language': 'de' },
});

mainInstance.interceptors.response.use((response) => response, async (error) => {
  const login = PublicRoutes.find((rout) => rout.name === 'login');
  const originalRequest = error.config;
  // eslint-disable-next-line no-underscore-dangle
  if (ERROR_STATUSES.includes(error.response.status) && !originalRequest._retry) {
    // eslint-disable-next-line no-underscore-dangle
    originalRequest._retry = true;
    try {
      const refreshRequest = await refreshInstance.post('/auth/token/refresh/');
      if (refreshRequest.status === 200) {
        originalRequest.cookies = refreshRequest.cookies;
        return mainInstance(originalRequest);
      }
    } catch (err) {
      window.location.href = login.path;
    }
  }
  // eslint-disable-next-line no-underscore-dangle
  if (ERROR_STATUSES.includes(error.response.status) && originalRequest._retry) {
    window.location.href = login.path;
  }
  throw error;
});
