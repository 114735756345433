import {
  Col, message, PageHeader, Row, Spin,
} from 'antd';

import {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from 'react';

import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import queryString from 'query-string';

import { fetchStatusAssessment } from '../../../store/assessments/actions';
import { fetchAllQuestions } from '../../../store/questions/actions';
import { fetchAllAnswers } from '../../../store/answers/actions';

import Stepper from '../Stepper/Stepper';
import YourAnswer from './YourAnswer/YourAnswer';

import styles from '../UserAnswers/index.module.scss';

const UsersAnswer = () => {
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [category, setCategory] = useState({});
  const [isLast, setLast] = useState(false);

  const location = useLocation();
  const questionRef = useRef(queryString.parse(location.search).question);
  const { categorySlug, assessmentSlug, userSlug } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const allQuestions = useMemo(() => {
    const arr = questions.map((item) => {
      if (item.is_skipped && item?.answer === 'false' && item.type === 'BOOLEAN') {
        return {
          ...item,
          status: 'finish',
        };
      }
      if (item.is_skipped && item.type !== 'BOOLEAN') {
        return {
          ...item,
          status: 'skip',
        };
      }
      if (!item.is_answered) {
        return {
          ...item,
          status: 'wait',
        };
      }
      if (item.is_answered) {
        return {
          ...item,
          status: 'finish',
        };
      }
      return item;
    });
    const last = arr.find((item, ind) => ind === arr.length - 1);

    if ((last && currentQuestion) && last?.slug === currentQuestion?.slug) {
      setLast(true);
    } else {
      setLast(false);
    }
    return arr.map((item) => (item.slug === currentQuestion?.slug ? { ...currentQuestion, status: 'process' } : item));
  }, [questions, currentQuestion]);

  const statuses = useMemo(() => allQuestions.map(({ status }) => status), [allQuestions]);

  const titleContent = (
    <div className={styles.wrapper}>
      <Row className={styles.scroll}>
        <Row>
          <Col className={styles.title}>
            <h1>{category.name || ''}</h1>
          </Col>
          <Stepper statuses={statuses} />
        </Row>
      </Row>
    </div>
  );

  const update = useCallback((question) => {
    const all = allQuestions.map((item) => {
      if (item.slug === question.slug) {
        return question;
      }
      return item;
    });
    const index = all.map((item) => item.slug).indexOf(question.slug);
    const current = all.find((it, ind) => ind === (index + 1));
    if (current) {
      history.push(`/users-answer/${assessmentSlug}/${categorySlug}/${userSlug}?question=${current.slug}`,
        { current, all });
    } else {
      history.push(`/wizard/${assessmentSlug}?weighting`);
    }
  }, [assessmentSlug, categorySlug, history, allQuestions, userSlug]);

  const goBack = useCallback((question) => {
    const index = questions.map((item) => item.slug).indexOf(question.slug);
    const current = questions.find((item, ind) => ind === (index - 1));
    if (current) {
      const all = questions.map((item) => {
        if (item.slug === current.slug) {
          return {
            ...current,
          };
        }
        return item;
      });
      history.push(`/users-answer/${assessmentSlug}/${categorySlug}/${userSlug}?question=${current.slug}`,
        { current, all });
    } else {
      history.push(`/wizard/${assessmentSlug}?weighting`);
    }
  }, [assessmentSlug, categorySlug, history, questions, userSlug]);

  useEffect(() => {
    setCategory(allQuestions?.[0]?.category || {});
  }, [allQuestions]);

  useEffect(() => {
    history.listen((loc) => {
      setCurrentQuestion(loc?.state?.current || {});
      setQuestions(loc?.state?.all || []);
    });
  }, [history]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        dispatch(fetchStatusAssessment(assessmentSlug));
        const allQ = await dispatch(fetchAllQuestions({ assessmentSlug, categorySlug }))
          .then((res) => res.data.sort((a, b) => a.order - b.order));
        const answers = await dispatch(fetchAllAnswers(assessmentSlug)).then((res) => res.data);
        const arr = allQ.reduce((source, question) => {
          const myAnswer = answers.find(
            (item) => item.question === question.slug && item.user === userSlug,
          );
          const usersAnswer = answers.filter(
            (item) => item.question === question.slug && item.user !== userSlug,
          );
          if (!myAnswer && usersAnswer.length === 0) {
            return source.concat({
              ...question,
              is_skipped: false,
              is_answered: false,
              answer: '',
            });
          }
          const item = { ...question, usersAnswer };
          if (myAnswer) {
            Object.assign(item, {
              ...myAnswer, slug: myAnswer.question, question: question.question, answerSlug: myAnswer.slug,
            });
          }
          return source.concat(item);
        }, []);

        if (questionRef?.current) {
          const searchQuestion = arr.find((item) => item.slug === questionRef.current);
          history.push(`/users-answer/${assessmentSlug}/${categorySlug}/${userSlug}?question=${questionRef.current}`,
            { current: searchQuestion, all: arr });
        } else {
          const current = arr?.find((item) => (!item.is_answered && !item.is_skipped));
          if (current) {
            setCurrentQuestion(current);
            history.replace({
              pathname: `/users-answer/${assessmentSlug}/${categorySlug}/${userSlug}`,
              search: `?question=${current.slug}`,
              isActive: true,
              state: { current, all: arr },
            });
          } else {
            history.replace({
              pathname: `/users-answer/${assessmentSlug}/${categorySlug}/${userSlug}`,
              search: `?question=${arr[0]?.slug}`,
              isActive: true,
              state: { current: arr[0], all: arr },
            });
          }
        }
      } catch (e) {
        setLoading(false);
        return message.error('General server error');
      }
      return setLoading(false);
    })();
    return () => {
      setQuestions([]);
      setCurrentQuestion({});
      setLoading(false);
      questionRef.current = null;
    };
  }, [assessmentSlug, categorySlug, dispatch, history, userSlug]);

  return (
    <div>
      <Spin spinning={loading}>
        <PageHeader title={titleContent} className={styles.pageHeader} />
        <YourAnswer
          question={currentQuestion}
          isLast={isLast}
          update={update}
          goBack={goBack}
        />
      </Spin>
    </div>
  );
};

export default UsersAnswer;
