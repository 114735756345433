import {
  Button, Typography,
  Col, Modal, PageHeader, Row, Spin, Steps, Table, List, Tabs,
} from 'antd';

import {
  useEffect, useMemo, useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import classNames from 'classnames';

import { userSelector } from '../../../../../store/auth/selectors';
import { assessmentsSelector } from '../../../../../store/assessments/selectors';

import { fetchAssessmentKPI, fetchQuestionsReport } from '../../../../../store/assessments/actions';

import { addDynamicClass, responseHandler } from '../../../../../utils/helpers';
import { KPI } from '../../../../../utils/constants';

import styles from './index.module.scss';

const { Step } = Steps;
const { TabPane } = Tabs;
const { Text } = Typography;

const AuswertungOverview = () => {
  const [listData, setListData] = useState([]);
  const [tabList, setTabList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [category, setCategory] = useState('');
  const [modalData, setModalData] = useState({ isVisible: false, data: {} });

  const location = useLocation();

  const { assessmentSlug, categorySlug } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const assessments = useSelector(assessmentsSelector);
  const user = useSelector(userSelector);

  const onBack = () => {
    const state = history.location.state ? history.location.state : null;
    if (location.pathname.includes('archive') || location.search.includes('archive')) {
      const to = assessmentSlug.includes('archive') ? assessmentSlug.split('?')[0] : assessmentSlug;
      history.push({ pathname: `/wizard/${to}`, search: 'archive' }, state);
    } else {
      history.push({ pathname: `/wizard/${assessmentSlug}` }, state);
    }
  };

  const onCancel = () => {
    setModalData({ isVisible: false, data: [] });
  };

  const columns = useMemo(() => {
    const searchCurrent = assessments.find((assessment) => assessment.slug === assessmentSlug);
    const source = [
      {
        title: 'Frage/Aussage',
        dataIndex: 'question',
        key: 'question',
        width: '80%',
        render: (question) => (
          <Row align='middle' className={styles.question}>
            <Col>{question}</Col>
          </Row>
        ),
      },
      {
        title: 'Gewichtung',
        dataIndex: 'weight',
        key: 'weight',
        width: '5%',
        render: (weight) => (
          <Row align='middle'>
            <Col><Text type='secondary'>{weight}</Text></Col>
          </Row>
        ),
      },
      {
        title: 'Durchschnitt',
        dataIndex: 'average',
        key: 'average',
        width: '5%',
        render: (average) => (
          <Row align='middle' className={styles.average} key={Math.random().toString()}>
            <Col>
              {average || 0}
            </Col>
          </Row>
        ),
        onCell: (record) => ({
          style: record.average ? addDynamicClass(+record.average) : addDynamicClass(0),
        }),
      },
      {
        title: 'Bewertung/en',
        dataIndex: 'answers',
        key: 'answers',
        width: '5%',
        render: (answers) => (
          <Row align='middle' className={styles.average} key={Math.random().toString()}>
            <Col span={24} className={styles.usersAnswer}>
              {answers.map((answer, index) => (
                <div key={index.toString()}>
                  { Number.isFinite(+answer?.answer) && answer?.answer !== '-1'
                    ? answer.answer
                    : '-'}
                </div>
              ))}
            </Col>
          </Row>
        ),
      },
    ];
    if (assessments.length) {
      if (searchCurrent?.type === 'PERFORMANCE_CHECK') {
        return source;
      }
      const filtered = source.filter((item) => item.title !== 'Durchschnitt');
      return filtered.map((item) => {
        if (item.title === 'Bewertung/en') {
          return {
            ...item,
            title: 'Bewertung',
            render: (answers, record) => {
              const evaluatorAnswer = record.answers.find((obj) => obj.is_evaluator);
              return evaluatorAnswer && (
                <Row align='middle' className={styles.average} key={Math.random().toString()}>
                  <Col>
                    {evaluatorAnswer && evaluatorAnswer?.answer !== '-1'
                      ? evaluatorAnswer.answer
                      : '-'}
                  </Col>
                </Row>
              );
            },
            onCell: (record) => {
              const evaluatorAnswer = record.answers.find((it) => it.is_evaluator);
              return evaluatorAnswer && {
                style: Number.isFinite(+evaluatorAnswer.answer) && evaluatorAnswer.answer !== '-1'
                  ? addDynamicClass(evaluatorAnswer.answer)
                  : addDynamicClass(),

              };
            },
          };
        }
        return item;
      });
    }
    return [];
  }, [assessmentSlug, assessments]);

  const renderUserData = (userData) => {
    const firstName = userData?.first_name;
    const lastName = userData?.last_name;
    return (firstName && lastName) ? `${firstName} ${lastName}` : userData?.email || 'user';
  };

  const setTabContent = (obj) => {
    const answer = {
      Answer: '',
      Anmerkung: obj.reason,
      Verbesserungsvorschlag: obj.suggestion,
      'Datei Anhänge': obj.files || [],
    };

    if (obj.answer === '-1') {
      Object.assign(answer, {
        Answer: 'Keine Antwort möglich',
      });
    }
    if (obj.answer === 'true' || obj.answer === 'false') {
      Object.assign(answer, {
        Answer: obj.answer === 'true' ? 'Ja' : 'Nein',
      });
    }
    if (['0', '5', '8', '10'].includes(obj.answer)) {
      Object.assign(answer, {
        Answer: obj.answer,
      });
    }
    if (!(obj.answer === 'true' || obj.answer === 'false') && !['0', '5', '8', '10'].includes(obj.answer)
      && obj.answer !== '-1') {
      Object.assign(answer, {
        Answer: obj.answer,
      });
    }

    const list = [];
    Object.keys(answer).forEach((key) => {
      list.push({ title: key, value: answer[key] });
    });
    setListData(list);
  };

  useEffect(() => {
    setLoading(true);
    const slug = assessmentSlug.includes('archive') ? assessmentSlug.split('?')[0] : assessmentSlug;
    if (location.search && location.search !== '?archive') {
      dispatch(fetchAssessmentKPI(slug, KPI.search[location.search])).then((res) => {
        if (res.error) {
          responseHandler(res);
        } else {
          setQuestions(res.data?.questions || []);
        }
        setLoading(false);
      });
    } else {
      dispatch(fetchQuestionsReport(slug)).then((res) => {
        if (res.error) {
          responseHandler(res);
        } else {
          const currentCategory = res.data.find((item) => item.slug === categorySlug);
          setCategory(currentCategory.name);
          setQuestions(currentCategory.questions || []);
        }
        setLoading(false);
      });
    }
  }, [assessmentSlug, categorySlug, dispatch, location]);

  return (
    <Spin spinning={loading}>
      <Modal
        width='800px'
        closable={false}
        visible={modalData.isVisible}
        onCancel={onCancel}
        onOk={onCancel}
        centered
        destroyOnClose
        footer={<Button type='primary' onClick={onCancel}>Ok</Button>}
      >
        <Row className={styles.categoryQuestion}>
          <Typography.Title level={4}>{modalData.data.question}</Typography.Title>
        </Row>
        <Row className={styles.categoryQuestion}>
          <Typography.Text>{modalData.data?.hint}</Typography.Text>
        </Row>
        <Tabs
          type='card'
          onChange={(key) => {
            const obj = modalData.data.answers.find((item) => item.slug === key);
            setTabContent(obj);
          }}
        >
          {tabList.map((item) => (
            <TabPane tab={item.tab} key={item.key}>
              <List
                itemLayout='vertical'
                size='large'
                dataSource={listData}
                renderItem={(obj, index) => (
                  <>
                    { Array.isArray(obj.value)
                      ? (
                        <List.Item
                          className={classNames({ [styles.hidden]: !obj.value || obj.value.length < 1 })}
                          key={index.toString()}
                        >
                          <List.Item.Meta title={obj.title} />
                          { obj.value.map((file, idx) => (
                            <p key={idx.toString()}>
                              <a href={file.file || 'https://google.com/'} target='_blank' rel='noreferrer'>
                                {file.name || file}
                              </a>
                            </p>
                          ))}
                        </List.Item>
                      )

                      : (
                        <List.Item
                          className={classNames({ [styles.hidden]: !obj.value })}
                          key={index.toString()}
                        >
                          <List.Item.Meta
                            title={obj.title}
                            description={obj.value}
                          />

                        </List.Item>
                      )}
                  </>
                )}
              />
            </TabPane>
          ))}
        </Tabs>
      </Modal>
      <div className={styles.container} style={{ minHeight: `${window.innerHeight - 90}px` }}>
        <div style={{ minHeight: `${window.innerHeight - 90}px` }}>
          <Steps current={history.location.state.step}>
            { history.location.state.config[user.role].map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <Row justify='center' style={{ margin: '20px 0 20px 0' }}>
            <PageHeader title={category || KPI.title[location.search]} />
          </Row>
          <Table
            onRow={(record) => ({
              onClick: () => {
                const list = record.answers.map((item) => {
                  const userData = renderUserData(item.created_by);
                  return {
                    key: item.slug,
                    tab: userData,
                  };
                });
                setTabList(list);
                if (record.answers.length) {
                  setTabContent(record.answers[0]);
                }
                setModalData({ isVisible: true, data: record });
              },
            })}
            dataSource={questions}
            columns={columns}
            rowKey='slug'
            pagination
          />
          <Button onClick={onBack} type='primary' className={styles.btn}>Zurück</Button>
        </div>
      </div>
    </Spin>
  );
};
export default AuswertungOverview;
