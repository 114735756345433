export const assessmentsSelector = (state) => state.assessments.assessments;
export const formDataSelector = (state) => state.assessments.newAssessmentFormData;
export const templatesSelector = (state) => state.assessments.templates;
export const loadingSelector = (state) => state.assessments.loading;
export const newAssessmentSelector = (state) => state.assessments.newAssessment;
export const assessmentUsersSelector = (state) => state.assessments.assessmentUsers;
export const userAssessmentsSelector = (state) => state.assessments.userAssessments;
export const statusAssessmentSelector = (state) => state.assessments.statusAssessment;
export const userAssessmentStatusSelector = (state) => state.assessments.userAssessmentStatus;
export const companyTemplatesSelector = (state) => state.assessments.companyTemplates;
export const globalLoadingSelector = (state) => state.assessments.globalLoading;
export const assessmentProgressSamAdminSelector = (state) => state.assessments.assessmentProgressSamAdmin;
export const assessmentReportSelector = (state) => state.assessments.assessmentReport;
