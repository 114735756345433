import { Button, Image } from 'antd';

import { useCallback, useState } from 'react';

import { useSelector } from 'react-redux';

import { userSelector } from '../../store/auth/selectors';

import NewCompanyModal from '../../components/common/modals/NewCompanyModal/NewCompanyModal';
import NewAssessmentModal from '../../components/common/modals/NewAssessmentModal/NewAssessmentModal';
import ClientHome from './ClientHome';

import companySelector from '../../store/company/selectors';

import styles from './index.module.scss';

const Home = () => {
  const [isVisible, setVisible] = useState({
    company: false,
    assessment: false,
  });
  const companies = useSelector(companySelector);
  const user = useSelector(userSelector);

  const openModal = (key) => {
    setVisible((s) => ({
      ...s,
      [key]: true,
    }));
  };

  const onCancel = useCallback((form, key) => {
    form.resetFields();
    setVisible((s) => ({
      ...s,
      [key]: false,
    }));
  }, []);

  const renderContent = (src, buttonText, modalKey) => (
    <>
      <div className={styles.divIllustrationAlign}>
        <Image
          width='30%'
          preview={false}
          alt='Illustration'
          src={src}
        />
      </div>
      <div className={styles.divButtonAlign}>
        <Button
          data-cy='openModal'
          onClick={() => openModal(modalKey)}
          type='primary'
          htmlType='submit'
          className={styles.buttonAlign}
        >
          { buttonText }
        </Button>
      </div>
    </>
  );

  return (
    <>
      {user.role === 'CLIENT'
        ? <ClientHome />
        : (
          <div className={styles.homePageContainer}>
            <NewAssessmentModal companies={companies} isVisible={isVisible.assessment} onCancel={onCancel} />
            <NewCompanyModal isVisible={isVisible.company} onCancel={onCancel} />
            { user.role === 'ADMIN' && (
              renderContent('/static/images/adminIllustration.png', 'Firma erstellen', 'company')
            )}
            { user.role === 'SAM' && (
              renderContent('/static/images/samIllustration.png', 'Assessment erstellen', 'assessment')
            )}
          </div>
        )}
    </>

  );
};

export default Home;
