import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Select,
  Button, Form, Input, Modal,
} from 'antd';

import { CheckOutlined } from '@ant-design/icons';

import { useEffect, useState } from 'react';

import styles from './index.module.scss';
import { setFormData } from '../../../../store/assessments/actions';

const { Item } = Form;

const tailLayout = {
  wrapperCol: { span: 32 },
};

const NewAssessmentModal = ({ isVisible, onCancel, companies }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const [options, setOptions] = useState([]);

  const handleSubmit = async () => {
    const formData = await form.validateFields();
    const result = { ...formData };
    dispatch(setFormData(result));
    history.push('/create-assessment');
    onCancel(form);
  };

  useEffect(() => {
    setOptions(companies.map((item) => ({
      value: item.slug,
      label: item.name,
    })));
  }, [companies]);

  return (
    <Modal
      centered
      closable={false}
      visible={isVisible}
      title='Neues Assessment starten'
      footer={null}
      onCancel={() => onCancel(form, 'assessment')}
    >
      <Form form={form} onFinish={handleSubmit} layout='vertical'>
        <Item name='title' label='Bezeichnung' rules={[{ required: true, message: 'Dieses Feld wird benötigt.' }]}>
          <Input placeholder='Assessment Bezeichnung' autoFocus />
        </Item>
        <Item
          name='company'
          label='Firma'
          rules={[{ required: true, message: 'Dieses Feld wird benötigt.' }]}
        >
          <Select
            showArrow
            options={options}
            placeholder='Firma auswählen'
          />
        </Item>
        <Item {...tailLayout} className={styles.actionContainer}>
          <Button htmlType='submit' icon={<CheckOutlined />} />
        </Item>
      </Form>
    </Modal>
  );
};

NewAssessmentModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  companies: PropTypes.array,
};

NewAssessmentModal.defaultProps = {
  companies: [],
};

export default NewAssessmentModal;
