import PropTypes from 'prop-types';

import { useEffect, useState } from 'react';

import {
  Card, Row, Col, List, message, Spin,
} from 'antd';
import { FileExcelOutlined, FileOutlined } from '@ant-design/icons';

import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { assessmentsSelector } from '../../../store/assessments/selectors';
import { fetchAssessments, resetStatusAssessment } from '../../../store/assessments/actions';

import styles from '../index.module.scss';

import { mainInstance } from '../../../utils';

const EXTENSIONS = {
  'application/pdf': '.pdf',
  'application/ms-excel': '.xls',
  'text/csv': '.csv',
};

const IcpAssessment = ({ activeTab }) => {
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { customerSlug } = useParams();

  const assessment = useSelector(assessmentsSelector);

  const goUsersProgress = async (item) => {
    dispatch(await resetStatusAssessment());
    history.push({ pathname: `/wizard/${item.slug}`, state: { company: item.company } });
  };
  const downloadFile = (link) => {
    setLoading(true);
    mainInstance(link, {
      responseType: 'blob',
    }).then((res) => {
      const extension = EXTENSIONS[res.headers['content-type']];
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement('a');
      a.href = url;
      a.download = `file${extension}`;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
      }, 0);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
      message.error('Genereller Fehler!');
    });
  };
  useEffect(() => {
    if (activeTab === 'icp-assessments') {
      dispatch(fetchAssessments()).then((res) => {
        if (res.error) {
          if (res.error?.response?.data?.error) {
            message.error(res.error.response.data.error);
          } else {
            message.error('General server error');
          }
        }
      });
    }
  }, [activeTab, customerSlug, dispatch]);

  return (
    <Spin spinning={loading}>
      <Card title='Assessments' bordered={false} className={styles.assessmentCard}>
        <List
          pagination
          dataSource={assessment.filter((item) => (item.company === customerSlug && item.state !== 'WEIGHTING'))}
          renderItem={(item, idx) => (
            <Row key={idx.toString()} align='middle' justify='space-between'>
              <List.Item style={{
                flexWrap: 'wrap', padding: '10px 10px 10px 30px', borderBottom: '1px solid #f0f0f0', width: '100%',
              }}
              >
                <Col xs={24} sm={24} md={6} lg={8} xl={12} xxl={12}>
                  <span>{item.title || 'no title'}</span>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={16}
                  lg={10}
                  xl={8}
                  xxl={7}
                  style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap',
                  }}
                >
                  <Col className={styles.downloadFilesContainer}>
                    {item.state === 'ENDED' && (
                      <>
                        <Col>
                          <FileExcelOutlined />
                          <span
                            role='presentation'
                            onClick={() => downloadFile(`export/${item.slug}/csv/`)}
                          >
                            Anworten exportieren
                          </span>
                        </Col>
                        <Col>
                          <FileOutlined />
                          <span
                            role='presentation'
                            onClick={() => downloadFile(`export/${item.slug}/zip/`)}
                          >
                            Dateien exportieren
                          </span>
                        </Col>
                      </>
                    )}
                  </Col>
                  <Col className={styles.actionsContainer}>
                    <div className={styles.assessmentStatus}>{`(${item.state})`}</div>
                    <span
                      onClick={() => goUsersProgress(item)}
                      className={styles.actionLink}
                      role='presentation'
                    >
                      Anzeigen
                    </span>
                  </Col>
                </Col>
              </List.Item>
            </Row>
          )}
        />
      </Card>
    </Spin>
  );
};

IcpAssessment.propTypes = {
  activeTab: PropTypes.string.isRequired,
};

export default IcpAssessment;
