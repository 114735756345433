import ResetPasswordConfirm from '../views/ResetPasswordConfirm/ResetPasswordConfirm';
import Index from '../views/Home';
import CreateAssessment from '../views/CreateAssessment/CreateAssessment';
import Wizard from '../views/Wizard/index';
import ResetPassword from '../views/ResetPassword/ResetPassword';
import Login from '../views/Login';
import ProjectOverview from '../views/ProjectOverview';
import Settings from '../views/Settings';
import Questionnaire from '../views/Questionnaire';
import UserAssessmentDetail from '../components/common/StatusAssessment/UserAssessmentDetail/UserAssessmentDetail';
import UserAssessmentProgress from '../views/UserAssessmentProgress/UserAssessmentProgress';
import UserCategoryQuestions from '../views/UserCategoryQuestions/UserCategoryQuestions';
import UserAnswers from '../components/common/UserAnswers/UserAnswers';
import UsersAnswer from '../components/common/UsersAnswer/UsersAnswer';
import AuswertungOverview from '../views/Wizard/WizardSteps/Auswertung/AuswertungOverview/AuswertungOverview';
import AuswertungCompare from '../views/Wizard/WizardSteps/Auswertung/AuswertungCompare/AuswertungCompare';
import Help from '../views/Help';

export const PrivateRoutes = [
  {
    path: '/',
    component: Index,
  },
  {
    path: '/help',
    component: Help,
  },
  {
    path: '/wizard/:assessmentSlug?',
    component: Wizard,
  },
  {
    path: '/auswertung/:assessmentSlug/compare',
    component: AuswertungCompare,
  },
  {
    path: '/auswertung/:assessmentSlug?',
    component: AuswertungOverview,
  },
  {
    path: '/auswertung/:assessmentSlug/:categorySlug',
    component: AuswertungOverview,
  },
  {
    path: '/wizard/:assessmentSlug/:userSlug',
    component: UserAssessmentDetail,
  },
  {
    path: '/create-assessment',
    component: CreateAssessment,
  },
  {
    path: '/project-overview/:customerSlug?',
    component: ProjectOverview,
  },
  {
    path: '/settings',
    component: Settings,
  },
  {
    path: '/questionnaire-edit/:customerSlug?',
    component: Questionnaire,
  },
  {
    path: '/questionnaire-configuration/:customerSlug?',
    component: Questionnaire,
  },
  { path: '/assessment/:slug', component: UserAssessmentProgress },
  { path: '/assessment/:slug/:categorySlug?', component: UserCategoryQuestions },
  // { path: '/users-progress/:companySlug/:assessmentSlug', component: UsersProgress },
  { path: '/user-progress/:companySlug/:assessmentSlug/:userSlug', component: UserAssessmentDetail },
  { path: '/answers/:companySlug/:assessmentSlug/:categorySlug/:userSlug?', component: UserAnswers },
  { path: '/users-answer/:assessmentSlug/:categorySlug/:userSlug', component: UsersAnswer },
];

export const PublicRoutes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  /* {
    path: '/register',
    component: Register,
  }, */
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPassword,
  },
  {
    path: '/reset-password/confirm/:uid/:token',
    name: 'resetPasswordConfirm',
    component: ResetPasswordConfirm,
  },
];
