import {
  CONFIRM_2FA,
  CREATE_USER,
  DELETE_2FA,
  ENABLE_2FA,
  GET_USER,
  GET_USERS,
  LOG_OUT,
  LOGIN_USER,
  RESET_PASSWORD, RESET_PASSWORD_CONFIRM,
  SUBMIT_2FA,
  UPDATE_USER,
} from './types';

export const loginUser = (data) => ({
  type: LOGIN_USER,
  request: {
    url: '/auth/login/',
    method: 'POST',
    data,
  },
});

export const submitTwoFa = (data) => ({
  type: SUBMIT_2FA,
  request: {
    url: 'auth/login/verify/',
    method: 'POST',
    data,
  },
});

export const logout = () => ({
  type: LOG_OUT,
  request: {
    url: '/auth/logout/',
    method: 'POST',
  },
});

export const getUser = () => ({
  type: GET_USER,
  request: {
    url: '/auth/me/',
    method: 'GET',
  },
});

export const updateUser = (data) => ({
  type: UPDATE_USER,
  request: {
    url: '/auth/me/',
    method: 'PUT',
    data,
  },
});

export const enableTwoFa = () => ({
  type: ENABLE_2FA,
  request: {
    url: '/auth/two-factor/',
    method: 'GET',
  },
});

export const confirmTwoFa = () => ({
  type: CONFIRM_2FA,
  request: {
    url: '/auth/two-factor/?confirm=true',
    method: 'GET',
  },
});

export const deleteTwoFa = () => ({
  type: DELETE_2FA,
  request: {
    url: '/auth/two-factor/',
    method: 'DELETE',
  },
});

export const getUsers = (slug) => ({
  type: GET_USERS,
  request: {
    method: 'GET',
    url: '/accounts/',
    params: { company_slug: slug },
  },
});

export const register = (data) => ({
  type: CREATE_USER,
  request: {
    method: 'POST',
    url: '/auth/registration/',
    data,
  },
});

export const resetPassword = (data) => ({
  type: RESET_PASSWORD,
  request: {
    method: 'POST',
    url: '/auth/password/reset/',
    data,
  },
});

export const confirmResetPassword = (data) => ({
  type: RESET_PASSWORD_CONFIRM,
  request: {
    method: 'POST',
    url: '/auth/password/reset/confirm/',
    data,
  },
});
