import { useCallback, useEffect, useState } from 'react';

import {
  Button, Col, Row, Spin, Steps, Table,
} from 'antd';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { userSelector } from '../../../../../store/auth/selectors';
import styles from '../AuswertungOverview/index.module.scss';
import { fetchPreAssessments } from '../../../../../store/preassessments/actions';
import { responseHandler } from '../../../../../utils/helpers';

import { fetchAssessmentReport } from '../../../../../store/assessments/actions';
import { assessmentReportSelector } from '../../../../../store/assessments/selectors';
import { preAssessmentsSelector } from '../../../../../store/preassessments/selectors';
import { fetchCategories } from '../../../../../store/categories/actions';
import { categoriesSelector } from '../../../../../store/categories/selectors';

const { Step } = Steps;

const AuswertungCompare = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setSource] = useState([]);

  const history = useHistory();
  const { assessmentSlug } = useParams();
  const dispatch = useDispatch();

  const categories = useSelector(categoriesSelector);
  const preAssessments = useSelector(preAssessmentsSelector);
  const assessmentReport = useSelector(assessmentReportSelector);
  const user = useSelector(userSelector);
  const location = useLocation();

  const onBack = () => {
    const state = history.location.state ? history.location.state : null;
    if (location.pathname.includes('archive') || location.search.includes('archive')) {
      const to = assessmentSlug.includes('archive') ? assessmentSlug.split('?')[0] : assessmentSlug;
      history.push({ pathname: `/wizard/${to}`, search: 'archive' }, state);
    } else {
      history.push({ pathname: `/wizard/${assessmentSlug}` }, state);
    }
    setSource([]);
  };

  const columns = [{
    title: 'Category',
    dataIndex: 'name',
    key: 'name',
    render: (name) => (
      <Row align='middle' className={classNames({ [styles.total]: name === 'Total' })}>
        <Col>
          {name}
        </Col>
      </Row>
    ),
  },
  {
    title: 'Selbsteinschätzung',
    dataIndex: 'preassessment',
    key: 'preassessment',
    width: '10%',
    onCell: (record) => ({
      style: record?.isLastColumn && { fontWeight: 500 },
      className: classNames(styles.progress, {
        [styles.green]: +record.preassessment >= 90,
        [styles.red]: +record.preassessment <= 60,
        [styles.gray]: +record.preassessment > 61 && record.preassessment < 89,
      }),
    }),
    render: (preassessment) => (
      <Row align='middle' justify='center'>
        <Col>
          {preassessment && `${preassessment} %`}
        </Col>
      </Row>
    ),
  }, {
    title: 'ICP-Perfomance',
    dataIndex: 'percentage',
    key: 'percentage',
    width: '11%',
    onCell: (record) => ({
      style: record?.isLastColumn && { fontWeight: 500 },
      className: classNames({
        [styles.green]: +record.percentage >= 90,
        [styles.red]: +record.percentage <= 60,
        [styles.gray]: +record.percentage > 61 && record.percentage < 89,
      }),
    }),
    render: (percentage) => (
      <Row align='middle' justify='center'>
        <Col>
          {`${percentage} %`}
        </Col>
      </Row>
    ),
  }];

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(fetchAssessmentReport(assessmentSlug)).then((res) => {
        if (res.error) {
          responseHandler(res);
        }
      });
      await dispatch(fetchPreAssessments(assessmentSlug)).then((res) => {
        if (res.error) {
          responseHandler(res);
        }
      });
      await dispatch(fetchCategories()).then((res) => {
        if (res.error) {
          responseHandler(res);
        }
      });
      setLoading(false);
    })();
  }, [dispatch, assessmentSlug]);

  const validate = useCallback((val) => {
    if (Number.isFinite(val)) {
      if (String(val).length > 3) {
        return val.toFixed(2);
      }
      return val;
    }
    return 0;
  }, []);

  useEffect(() => {
    const data = categories.reduce((source, item) => {
      const category = assessmentReport.find((report) => report?.category === item.slug);
      const preassessment = preAssessments.find((assessment) => assessment?.category === item.slug);
      if (!category) {
        return source.concat({
          ...item,
          percentage: 0,
          preassessment: preassessment?.value,
        });
      }
      return source.concat({
        ...item,
        percentage: category?.percentage || 0,
        preassessment: preassessment?.value || 0,
      });
    }, []);
    const totalPreassessment = (data.map((item) => item.preassessment).reduce((a, b) => a + b, 0) / data.length);
    const totalICP = (data.map((item) => item.percentage).reduce((a, b) => a + b, 0) / data.length);

    data.push({
      isLastColumn: true,
      slug: 'total',
      name: 'Total',
      percentage: validate(totalICP),
      preassessment: validate(totalPreassessment),
    });
    setSource(data);
  }, [assessmentReport, assessmentSlug, categories, preAssessments, validate]);

  return (
    <Spin spinning={loading}>
      <div className={styles.container} style={{ minHeight: `${window.innerHeight - 90}px` }}>
        <div style={{ minHeight: `${window.innerHeight - 90}px` }}>
          <Steps current={history.location.state.step}>
            { history.location.state.config[user.role].map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <Table
            className={styles.tableCompare}
            dataSource={dataSource}
            columns={columns}
            rowKey='slug'
            pagination={false}
          />
          <Button onClick={onBack} type='primary' className={styles.btn}>Zurück</Button>
        </div>
      </div>
    </Spin>
  );
};

export default AuswertungCompare;
