import PropTypes from 'prop-types';

import { Layout } from 'antd';

import styles from './index.module.scss';
import Logo from './components/Logo';

const DefaultLayout = ({ children, ...props }) => (
  <Layout style={{ minHeight: '100vh', overflow: 'auto' }}>
    <Layout.Content
      className={styles.siteLayoutBackground}
      style={{
        minHeight: '100vh',
        overflow: '',
      }}
      {...props}
    >
      <Logo src='/static/images/icp_logo.webp' defaultLayout />
      { children }
    </Layout.Content>
  </Layout>
);

DefaultLayout.propTypes = {
  children: PropTypes.any,
};

DefaultLayout.defaultProps = {
  children: null,
};

export default DefaultLayout;
