export const SET_FORM_DATA = 'SET_FORM_DATA';
export const RESET_FORM_DATA = 'RESET_FORM_DATA';
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const ADD_NEW_QUESTION = 'ADD_NEW_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const MODIFY_TEMPLATES = 'MODIFY_TEMPLATES';
export const DELETE_TEMPLATES = 'DELETE_TEMPLATES';
export const GET_CUSTOMER_TEMPLATES = 'GET_CUSTOMER_TEMPLATES';
export const UPDATE_ASSESSMENT = 'UPDATE_ASSESSMENT';
export const UPDATE_ALL_TEMPLATES = 'UPDATE_ALL_TEMPLATES';
export const FETCH_ASSESSMENTS = 'FETCH_ASSESSMENTS';
export const UPDATE_ASSESSMENT_QUESTION = 'UPDATE_ASSESSMENT_QUESTION';
export const CREATE_ASSESSMENT = 'CREATE_ASSESSMENT';
export const CLEAR_CUSTOMER_ASSESSMENTS = 'CLEAR_CUSTOMER_ASSESSMENTS';
export const SET_DEFAULT_ASSESSMENT_STATE = 'SET_DEFAULT_ASSESSMENT_STATE';
export const FETCH_NEW_ASSESSMENT = 'FETCH_NEW_ASSESSMENT';
export const FETCH_ASSESSMENT_USERS = 'FETCH_ASSESSMENT_USERS';
export const UPDATE_ASSESSMENT_USERS = 'UPDATE_ASSESSMENT_USERS';
export const DELETE_ASSESSMENT_USER = 'DELETE_ASSESSMENT_USER';
export const CREATE_ASSESSMENT_USER = 'CREATE_ASSESSMENT_USER';
export const SET_USER_ASSESSMENTS = 'SET_USER_ASSESSMENTS';
export const FETCH_USER_ASSESSMENT = 'FETCH_USER_ASSESSMENT';
export const UPDATE_USER_ASSESSMENTS = 'UPDATE_USER_ASSESSMENTS';
export const FETCH_STATUS_ASSESSMENT = 'FETCH_STATUS_ASSESSMENT';
export const RESET_NEW_ASSESSMENT = 'RESET_NEW_ASSESSMENT';
export const RESET_STATUS_ASSESSMENT = 'RESET_STATUS_ASSESSMENT';
export const RESET_USER_ASSESSMENT_STATUS = 'RESET_USER_ASSESSMENT_STATUS';
export const UPDATE_COMPANY_TEMPLATE = 'UPDATE_COMPANY_TEMPLATE';
export const UPDATE_TEMPLATES = 'UPDATE_TEMPLATES';
export const CHANGE_GLOBAL_LOADING = 'CHANGE_GLOBAL_LOADING';
export const UPDATE_ASSESSMENT_VERSION = 'UPDATE_ASSESSMENT_VERSION';
export const CREATE_ASSESSMENT_VERSION_WITH_FILE = 'CREATE_ASSESSMENT_VERSION_WITH_FILE';
export const FETCH_ALL_ACCOUNTS = 'FETCH_ALL_ACCOUNTS';
export const FETCH_ASSESSMENT_REPORT = 'FETCH_ASSESSMENT_REPORT';
export const FETCH_ASSESSMENT_KPI = 'FETCH_ASSESSMENT_KPI';
export const FETCH_QUESTIONS_REPORT = 'FETCH_QUESTIONS_REPORT';
export const FETCH_ASSESSMENT_PROGRESS_SAM_ADMIN = 'FETCH_ASSESSMENT_PROGRESS_SAM_ADMIN';
