import PropTypes from 'prop-types';

import {
  Button, Card, Col, Input, PageHeader, Radio, Row, Space, Typography,
} from 'antd';

import styles from '../Home/index.module.scss';

const { TextArea } = Input;
const { Text } = Typography;

export default function Help({ title }) {
  return (
    <>
      <PageHeader
        title={title || 'Folgende Bewertungsmöglichkeiten sind Teil des Assessments:'}
        className={styles.pageHeader}
      />
      <div className={styles.content}>
        <Row justify='space-around' gutter={[10, 10]}>
          <Col span={21}>
            <Card
              title='Wertung'
            >
              <div>
                <Radio.Group defaultValue='0' disabled className={styles.radio}>
                  <Space direction='vertical'>
                    <Row className={styles.wrapper}>
                      <Col><Radio value='0'>0</Radio></Col>
                      <Col className={styles.explanationWrapper}>
                        <Text>
                          Die Anforderungen werden nicht erfüllt. Es gibt akuten Handlungsbedarf. Es ist
                          zwingend eine entsprechende Begründung und Verbesserungsmaßnahme zu notieren.
                        </Text>
                      </Col>
                    </Row>
                    <Row className={styles.wrapper}>
                      <Col>
                        <Radio value='5'>5</Radio>
                      </Col>
                      <Col className={styles.explanationWrapper}>
                        <Text>
                          Die Anforderungen werden teilweise erfüllt. Es gibt Verbesserungsbedarf. Es ist
                          zwingend eine entsprechende Begründung und Verbesserungsmaßnahme zu notieren.
                        </Text>
                      </Col>
                    </Row>
                    <Row className={styles.wrapper}>
                      <Col><Radio value='8'>8</Radio></Col>
                      <Col className={styles.explanationWrapper}>
                        <Text>
                          Die Anforderungen werden weitestgehend erfüllt. Kleine Details können noch
                          ausgebessert werden.
                        </Text>
                      </Col>
                    </Row>
                    <Row className={styles.wrapper}>
                      <Col><Radio value='10'>10</Radio></Col>
                      <Col className={styles.explanationWrapper}>
                        <Text>Die Anforderungen werden vollumfänglich erfüllt oder sogar übertroffen.</Text>
                      </Col>
                    </Row>
                  </Space>
                </Radio.Group>
                <Row className={styles.noAnswerWrapper}>
                  <Col>
                    <Button disabled size='small'>
                      Keine Antwort möglich
                    </Button>
                  </Col>
                  <Col className={styles.explanationWrapper}>
                    <Text>Wenn Antworten unzureichend für Bewertung</Text>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
          <Col span={5} md={14} sm={24} xs={24} xxl={5} xl={7} lg={9}>
            <Card
              title='Antwortauswahl'
              className={styles.answerExplain}
            >
              <Radio.Group defaultValue='0' disabled>
                <Space direction='vertical'>
                  <Radio value='0'>Option A</Radio>
                  <Radio value='1'>Option B</Radio>
                  <Radio value='2'>Option C</Radio>
                  <Radio value='3'>Option D</Radio>
                </Space>
              </Radio.Group>
            </Card>
          </Col>
          <Col span={5} md={14} sm={24} xs={24} xxl={5} xl={7} lg={9}>
            <Card
              title='Freitext'
              bordered
              className={styles.answerExplain}
            >
              <TextArea
                readOnly
                placeholder='Freitext Anwort. Die Höhe der Eingabebox kann individuell angepasst werden.'
              />
            </Card>
          </Col>
          <Col span={5} md={14} sm={24} xs={24} xxl={5} xl={7} lg={9}>
            <Row className={styles.answer}>
              <Col>
                <Card
                  title='Ja/Nein'
                  className={styles.answerExplain}
                >
                  <Radio.Group defaultValue='0' disabled>
                    <Space direction='vertical'>
                      <Radio value='0'>JA</Radio>
                      <Radio value='1'>NEIN</Radio>
                    </Space>
                  </Radio.Group>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}

Help.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Help.defaultProps = {
  title: '',
};
