import PropTypes from 'prop-types';

import {
  Button, Card, Col, Divider, Form, Input, message, Modal, Upload, Radio, Row, Tooltip,
} from 'antd';

import {
  LeftOutlined, RightOutlined, SaveOutlined, UploadOutlined,
} from '@ant-design/icons';

import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import classNames from 'classnames';

import { useDispatch } from 'react-redux';
import styles from '../../index.module.scss';

import { createAnswer, updateAnswer } from '../../../../store/answers/actions';
import { responseHandler, setDefaultValuesAnswerForm } from '../../../../utils/helpers';

const { TextArea } = Input;
const { Item } = Form;
const { Group } = Radio;

const Question = ({
  question, update, goBack, last, allQuestions,
}) => {
  const [primary, setPrimary] = useState({ yes: false, no: false, none: false });
  const [isVisible, setVisible] = useState(false);
  const [modal, setModal] = useState({ isVisible: false, text: undefined, isBooleanQuestion: false });
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { slug } = useParams();

  const renderTextModal = (idx) => (
    <span>
      Durch die Auswahl der Antwort, werden die Fragen
      <span style={{ fontWeight: 'bold' }}>
        {' '}
        [
        {idx.join(', ')}
        ]
        {' '}
      </span>
      übersprungen.
    </span>
  );
  const onFinish = async () => {
    const answer = form.getFieldValue('answer');
    if (answer === 'false' && question.logic?.skip?.length) {
      const indexes = [];

      question.logic.skip.forEach((id) => {
        const index = allQuestions.findIndex((item) => item.slug === id);
        if (index !== -1) {
          indexes.push(index + 1);
        }
      });
      setModal({
        isVisible: true,
        text: renderTextModal(indexes),
        isBooleanQuestion: true,
      });
    } else {
      try {
        setLoading(true);
        let result = {};
        const none = form.getFieldValue('none');
        if (none === '-1') {
          const formData = form.getFieldsValue();
          result = { ...formData, answer: formData.none };
        } else {
          const formData = await form.validateFields();
          Object.assign(result, formData);
        }
        const payload = {
          files: [],
          suggestion: result.suggestion || '',
          question: question.slug,
          answer: result.answer,
          reason: result.reason,
          is_answered: true,
          is_skipped: false,
        };
        const skipQuestionPayload = {
          files: [],
          suggestion: '',
          answer: '',
          reason: '',
          is_answered: false,
          is_skipped: false,
        };
        let response = {};
        let skippedQuestionResponse = [];

        if (question.files?.length) {
          Object.assign(payload, { files: question.files });
        }
        if (result?.files?.fileList?.length) {
          Object.assign(payload, { files: result.files.fileList });
        }
        if (question?.answerSlug) {
          if (question?.logic?.skip?.length && answer === 'true') {
            const promises = question.logic.skip.map(async (id) => {
              const search = allQuestions.find((item) => item.slug === id);
              if (search && search?.answerSlug && !search?.answer) {
                return dispatch(updateAnswer({
                  ...skipQuestionPayload,
                  question: search.slug,
                  answerSlug: search.answerSlug,
                }));
              }
              return [];
            });
            skippedQuestionResponse = await Promise.all(promises);
          }
          response = await dispatch(updateAnswer({
            ...payload,
            answerSlug: question.answerSlug,
          })).then((res) => {
            if (res.error) {
              responseHandler(res);
            }
            return res.data;
          });
        } else {
          if (question?.logic?.skip?.length) {
            const promises = question.logic.skip.map(async (id) => {
              const search = allQuestions.find((item) => item.slug === id);
              if (search && search.answerSlug) {
                return dispatch(createAnswer({
                  ...skipQuestionPayload,
                  answerSlug: search.answerSlug,
                }));
              }
              return [];
            });
            skippedQuestionResponse = await Promise.all(promises);
          }
          response = await dispatch(createAnswer(payload)).then((res) => {
            if (res.error) {
              responseHandler(res);
            }
            return res.data;
          });
        }
        if (response) {
          form.resetFields();
          update({
            ...question,
            ...payload,
            files: response?.files || [],
            answerSlug: response.slug,
            question: question.question,
            answer: payload.answer,
            is_answered: true,
            is_skipped: false,
            isSavedProgress: false,
          }, skippedQuestionResponse);
          if (last) {
            history.push(`/assessment/${slug}`);
          }
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        if (e?.errorFields?.[0]?.name?.[0] === 'suggestion') {
          setModal({
            isVisible: true,
            text: 'Bei Bewertungen mit „0“ oder „5“ ist ein Verbesserungsvorschlag zu notieren.',
            isBooleanQuestion: false,
          });
        }
      }
    }
  };

  const skipQuestion = useCallback(async () => {
    const { none, ...rest } = form.getFieldsValue();
    try {
      setLoading(true);
      const payload = {
        files: [],
        answer: 'false',
        suggestion: rest.suggestion || '',
        reason: rest.reason || '',
        question: question.slug,
        is_answered: false,
        is_skipped: true,
      };
      const skipQuestionPayload = {
        files: [],
        suggestion: '',
        answer: '',
        reason: '',
        is_answered: false,
        is_skipped: true,
      };
      let response = {};
      let skippedQuestionResponse = [];

      if (question.files?.length) {
        Object.assign(payload, { files: question.files });
      }
      if (rest?.files?.fileList?.length) {
        Object.assign(payload, { files: rest.files.fileList });
      }
      if (question?.answerSlug) {
        if (question?.logic?.skip?.length) {
          const promises = question.logic.skip.map(async (id) => {
            const search = allQuestions.find((item) => item.slug === id);
            if (search && search.answerSlug) {
              return dispatch(updateAnswer({
                ...skipQuestionPayload,
                question: search.slug,
                answerSlug: search.answerSlug,
              }));
            }
            if (search && !search.answerSlug) {
              return dispatch(createAnswer({ ...skipQuestionPayload, question: search.slug }));
            }
            return [];
          });
          skippedQuestionResponse = await Promise.all(promises);
        }
        response = await dispatch(updateAnswer({
          ...payload,
          answerSlug: question.answerSlug,
        })).then((res) => {
          if (res.error) {
            responseHandler(res);
          }
          return res.data;
        });
      } else {
        if (question?.logic?.skip?.length) {
          const promises = question?.logic?.skip.map(async (id) => {
            const search = allQuestions.find((item) => item.slug === id);
            if (search) {
              return dispatch(createAnswer({ ...skipQuestionPayload, question: search.slug }));
            }
            return {};
          });
          skippedQuestionResponse = await Promise.all(promises);
        }
        response = await dispatch(createAnswer(payload)).then((res) => {
          if (res.error) {
            responseHandler(res);
          }
          return res.data;
        });
      }
      if (response) {
        form.resetFields();
        setModal({ isVisible: false, text: '', isBooleanQuestion: false });
        update({
          ...question,
          ...rest,
          answerSlug: response.slug,
          is_answered: false,
          is_skipped: true,
          isSavedProgress: false,
          files: response?.files || [],
        }, skippedQuestionResponse);
        if (last) {
          history.push(`/assessment/${slug}`);
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      message.error('General server error');
    }
  }, [allQuestions, dispatch, form, history, last, question, slug, update]);

  const answerLater = useCallback(async () => {
    try {
      setLoading(true);
      const formData = form.getFieldsValue();
      const payload = {
        files: [],
        suggestion: formData.suggestion || '',
        reason: formData.reason || '',
        answer: formData.answer || '',
        question: question.slug,
        is_answered: false,
        is_skipped: false,
      };

      let response = {};

      if (question.files?.length) {
        Object.assign(payload, { files: question.files });
      }
      if (formData?.files?.fileList?.length) {
        Object.assign(payload, { files: formData.files.fileList });
      }
      if (question.answerSlug) {
        response = await dispatch(updateAnswer({
          ...payload,
          answerSlug: question.answerSlug,
        })).then((res) => {
          if (res.error) {
            responseHandler(res);
          }
          return res.data;
        });
      } else {
        response = await dispatch(createAnswer(payload)).then((res) => {
          if (res.error) {
            responseHandler(res);
          }
          return res.data;
        });
      }
      if (response) {
        form.resetFields();
        setModal({ isVisible: false, text: '', isBooleanQuestion: false });
        update({
          ...question,
          files: response?.files || [],
          answerSlug: response.slug,
          suggestion: formData.suggestion || '',
          reason: formData.reason,
          answer: formData.answer,
          is_answered: false,
          is_skipped: false,
          isSavedProgress: false,
        });
        if (last) {
          history.push(`/assessment/${slug}`);
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      message.error('General server error');
    }
  }, [dispatch, form, history, last, question, slug, update]);

  const modalButtons = useMemo(() => {
    if (!modal.isBooleanQuestion) {
      return [
        <Button
          key='close'
          onClick={() => setModal({ isVisible: false, text: '', isBooleanQuestion: false })}
        >
          Abbrechen
        </Button>,
        <Button key='next' type='primary' onClick={answerLater}>
          Weiter und später begründen
        </Button>];
    }
    return [
      <Row
        key='skip'
        justify='end'
        style={{ width: '100%' }}
      >
        <Button type='primary' onClick={skipQuestion}>Okay</Button>
      </Row>];
  }, [modal.isBooleanQuestion, skipQuestion, answerLater]);

  const actions = [
    <Button
      icon={<LeftOutlined />}
      key='back'
      onClick={() => goBack(question)}
      disabled={loading}
      size='small'
    >
      Vorherige Frage
    </Button>,
    <Button
      disabled={!Object.keys(question).length}
      icon={<RightOutlined />}
      key='next'
      type='default'
      size='small'
      onClick={onFinish}
      loading={loading}
    >
      {!last ? 'nächste Frage' : 'Fertig'}
    </Button>,
  ];

  const saveProgress = async () => {
    const result = form.getFieldsValue();
    try {
      setLoading(true);
      const payload = {
        files: result?.files?.fileList.length ? result.files.fileList : [],
        suggestion: result.suggestion || '',
        question: question.slug,
        answer: result.answer || '',
        reason: result.reason || '',
        is_answered: false,
        is_skipped: false,
      };
      let response = {};
      if (question?.answerSlug) {
        response = await dispatch(updateAnswer({
          ...payload,
          answerSlug: question.answerSlug,
        })).then((res) => {
          if (res.error) {
            responseHandler(res);
          }
          return res.data;
        });
      } else {
        response = await dispatch(createAnswer(payload)).then((res) => {
          if (res.error) {
            responseHandler(res);
          }
          return res.data;
        });
      }
      if (response) {
        form.resetFields();
        message.success('Ihr Fortschritt wurde erfolgreich gespeichert');
        update({
          ...question,
          ...payload,
          isSavedProgress: true,
          files: response?.files || [],
          answerSlug: response.slug,
          question: question.question,
          answer: payload.answer,
          is_answered: true,
          is_skipped: false,
        });
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  const titleContent = () => (
    <Row justify='space-between'>
      {question?.title?.length >= 110
        ? (
          <Tooltip placement='top' title={question?.title}>
            {question?.title.slice(0, 110)}
            ...
          </Tooltip>
        )
        : <Col>{question?.title}</Col>}
      {question?.identifier && (
        <>
          <Col className={styles.container}>
            <Tooltip placement='topLeft' title='Speichern'>
              <Button type='link' icon={<SaveOutlined className={styles.saveIcon} />} onClick={saveProgress} />
            </Tooltip>
          </Col>
        </>
      )}
    </Row>
  );

  const handleChangeFormValue = useCallback((value) => {
    const [[key]] = Object.entries(value);
    if (key === 'none') {
      form.resetFields(['answer']);
      form.setFieldsValue({ answer: '', none: '-1' });
      setVisible(false);
      setPrimary({ none: true, yes: false, no: false });
    } else if (key === 'yes') {
      form.setFieldsValue({ answer: 'true', none: '' });
      setPrimary({ none: false, yes: true, no: false });
    } else {
      form.setFieldsValue({ answer: 'false', none: '' });
      setPrimary({ none: false, yes: false, no: true });
    }
  }, [form]);

  const answerType = useMemo(() => {
    if (question.type === 'BOOLEAN') {
      return (
        <Item name='answer' rules={[{ required: true, message: 'Bitte geben Sie Ihre Antwort ein!' }]}>
          <Row className={classNames(styles.booleanAnswer)}>
            <Col span={12}>
              <Button
                type={primary.yes ? 'primary' : 'dashed'}
                size='large'
                onClick={() => handleChangeFormValue({ yes: true })}
              >
                Ja
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type={primary.no ? 'primary' : 'dashed'}
                size='large'
                onClick={() => handleChangeFormValue({ no: false })}
              >
                Nein
              </Button>
            </Col>
          </Row>
        </Item>
      );
    }
    if (question.type === 'TEXT') {
      return (
        <Item
          name='answer'
          rules={[{ required: true, message: 'Bitte geben Sie Ihre Antwort ein!' }]}
        >
          <TextArea placeholder='Ihre Antwort' />
        </Item>
      );
    }
    if (question.type === 'MULTIPLE_CHOICE') {
      return (
        <Item
          name='answer'
          rules={[{ required: true, message: 'Bitte geben Sie Ihre Antwort ein!' }]}
          className={styles.radio}
        >
          <Group style={{ width: '100%' }}>
            <Row>
              {(question?.logic?.answer_options || []).map((item, index) => (
                <Col key={index.toString()} span={24} className={styles.answerOption}>
                  <Radio value={item.option.value}>{ item.option.value }</Radio>
                </Col>
              ))}
            </Row>
          </Group>
        </Item>
      );
    }
    return (
      <Item
        name='answer'
        rules={[{ required: true, message: 'Bitte geben Sie Ihre Antwort ein!' }]}
        className={styles.radio}
      >
        <Group>
          <Radio value='0'>0</Radio>
          <Radio value='5'>5</Radio>
          <Radio value='8'>8</Radio>
          <Radio value='10'>10</Radio>
        </Group>
      </Item>
    );
  }, [question, primary, handleChangeFormValue]);

  const onFormChanged = useCallback((value) => {
    const [[key]] = Object.entries(value);
    if (key === 'answer') {
      form.setFieldsValue({ none: '' });
      setPrimary({ yes: false, none: false, no: false });
    }
    const val = form.getFieldValue('answer');
    if (val === '0' || val === '5') {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [form]);

  const renderFiles = (item, index) => (
    <div key={index.toString()}>
      <a href={item.file} target='_blank' rel='noreferrer'>{item.name}</a>
    </div>
  );

  useEffect(() => {
    if (Object.keys(question).length) {
      setDefaultValuesAnswerForm(question, setPrimary, setVisible, form);
    }
    return () => {
      setLoading(false);
      setVisible(false);
      setPrimary({ yes: false, no: false, none: false });
      setModal({ isVisible: false, text: undefined, isBooleanQuestion: false });
    };
  }, [question, form]);

  return (
    <div className={styles.questionCard}>
      <Modal
        className={styles.warningModal}
        centered
        visible={modal.isVisible}
        onCancel={null}
        closable={false}
        footer={modalButtons}
      >
        <span>{modal.text}</span>
      </Modal>
      <Form form={form} layout='vertical' onValuesChange={onFormChanged}>
        <Card title={titleContent()} actions={actions}>
          {!!Object.keys(question).length && (
            <>
              <Row className={styles.questionContainer}>
                <Col className={styles.question}><p>{question.question}</p></Col>
                <Col className={styles.hint}>
                  <p>{question?.hint}</p>
                </Col>
              </Row>
              <Row justify='space-between'>
                <Col span={18}>{ answerType }</Col>
                <Col span={6} className={styles.answerNoneContainer}>
                  <Item name='none'>
                    <Button
                      className={classNames({ [styles.hidden]: question.type === 'BOOLEAN' })}
                      type={primary.none ? 'primary' : ''}
                      onClick={() => handleChangeFormValue({ none: true })}
                    >
                      keine Antwort möglich
                    </Button>
                  </Item>
                </Col>
              </Row>
            </>
          )}
          <Divider style={{ margin: 0 }} />
        </Card>
        {isVisible && (
          <Card title='Verbesserungsvorschlag' className={styles.reasonCard}>
            <Item
              name='suggestion'
              rules={[{ required: true, message: 'Bitte geben Sie einen Verbesserungsvorschlag ein!' }]}
            >
              <TextArea />
            </Item>
          </Card>
        ) }
        <Card title='Anmerkung' className={styles.reasonCard}>
          <Item
            name='reason'
          >
            <TextArea />
          </Item>
        </Card>
        {question.has_file_upload && (
          <Card title='Datei Anhänge' className={styles.uploadFileCard}>
            {question.files && question.files.map(renderFiles)}
            <Item
              name='files'
              valuePropName='files'
            >
              <Upload
                multiple
                accept='.pdf, .jpg, .png'
                beforeUpload={() => false}
              >
                <Button icon={<UploadOutlined />}>Hochladen</Button>
              </Upload>
            </Item>
          </Card>
        )}
      </Form>
    </div>
  );
};

Question.propTypes = {
  question: PropTypes.object,
  update: PropTypes.func.isRequired,
  last: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
  allQuestions: PropTypes.array,
};

Question.defaultProps = {
  question: {},
  allQuestions: [],
};

export default Question;
