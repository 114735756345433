import PropTypes from 'prop-types';

import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Button, Row, Col, Image, Card, Modal, Slider,
} from 'antd';

import { responseHandler } from '../../../utils/helpers';
import { fetchPreAssessments, updatePreAssessment } from '../../../store/preassessments/actions';

import styles from './index.module.scss';

const Preview = ({
  categories, assessmentSlug, title,
}) => {
  const [data, setData] = useState({});
  const [modal, setModal] = useState({ visible: false, data: {} });
  const [value, setValue] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = () => {
    history.push(`/wizard/${assessmentSlug}`);
  };

  const handleChange = (val) => {
    setValue(val);
  };

  const onCancel = () => {
    setModal({ visible: false, data: {} });
    setValue({});
  };

  const handleOpen = useCallback((item) => {
    setModal({ visible: true, data: { categorySlug: item.slug, ...item, questionSlug: data[item.slug].slug } });
    setValue({ [item.slug]: data[item.slug]?.value });
  }, [data]);

  const formatter = (val) => `${val} %`;

  const onOk = () => {
    const [[, val]] = Object.entries(value);
    const payload = {
      slug: modal.data.questionSlug,
      value: val,
    };
    dispatch(updatePreAssessment(payload)).then((res) => responseHandler(res, () => {
      const [[key, v]] = Object.entries(value);
      setData({ ...data, [key]: { ...data[key], value: v } });
      onCancel();
    }));
  };

  useEffect(() => {
    dispatch(fetchPreAssessments()).then((res) => {
      if (res.error) {
        responseHandler(res);
      }
      const filtered = res.data.filter((item) => item.assessment === assessmentSlug);
      const result = {};
      filtered.forEach((item) => {
        Object.assign(result, { [item.category]: { value: item.value, slug: item.slug } }, {});
      });
      setData(result);
    });
  }, [dispatch, assessmentSlug]);

  const content = useMemo(() => categories.map((item, idx) => (
    <Row
      justify='space-between'
      key={idx.toString()}
      className={styles.row}
      onClick={() => handleOpen(item)}
    >
      <div style={{ display: 'flex' }}>
        <Image preview={false} className={styles.img} src={item.image || ''} alt='Icon' />
        <Col className={styles.col}>{item.name}</Col>
      </div>
      <Col className={styles.col}>{`${data[item.slug]?.value || 0} %`}</Col>
    </Row>
  )), [categories, handleOpen, data]);

  return (
    <>
      <Modal
        visible={modal.visible}
        onCancel={onCancel}
        centered
        closable={false}
        destroyOnClose
        onOk={onOk}
        data-cy='update-preassessment'
      >
        <Card title={modal.data.title} className={styles.content}>
          <Image
            className={styles.img3}
            src={modal.data.image || ''}
            preview={false}
          />
          <p style={{ margin: 0 }}>{ modal.data.name }</p>
          <Slider
            className={styles.sliderRail}
            value={value[modal.data.slug]}
            tipFormatter={formatter}
            marks={{
              0: '0',
              25: '25',
              50: '50',
              75: '75',
              100: '100',
            }}
            trackStyle={{ backgroundColor: 'transparent' }}
            onChange={(e) => {
              handleChange({ [modal.data.slug]: e });
            }}
          />
        </Card>
      </Modal>
      <Card
        className={styles.title}
        bordered
        title={title}
      >
        <Row justify='space-between'>
          <Col>
            {' '}
            <p>Bewertungskategorie</p>
          </Col>
          <Col>
            <p>
              Compliance
              <br />
              Performance
            </p>
          </Col>
        </Row>
        {content}
        <Button
          type='primary'
          onClick={handleSubmit}
          className={styles.btn}
        >
          Selbsteinschätzung abgeben
        </Button>
      </Card>
    </>
  );
};

Preview.propTypes = {
  categories: PropTypes.array.isRequired,
  assessmentSlug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
export default Preview;
