import PropTypes from 'prop-types';

import {
  useMemo, useCallback, useState, useEffect,
} from 'react';
import {
  NavLink, useHistory, useLocation, useParams,
} from 'react-router-dom';

import {
  Image, Layout, Menu, Tooltip, Button,
} from 'antd';
import {
  DashboardOutlined, MenuFoldOutlined, MenuUnfoldOutlined, PlusOutlined, SettingOutlined, StarOutlined,
} from '@ant-design/icons';

import { useSelector } from 'react-redux';

import classNames from 'classnames';
import { userSelector } from '../../../../store/auth/selectors';

import Logo from './Logo';
import NewCompanyModal from '../../modals/NewCompanyModal/NewCompanyModal';

import styles from '../index.module.scss';
import { assessmentsSelector } from '../../../../store/assessments/selectors';

const { Sider } = Layout;
const { SubMenu } = Menu;

const AdminSidebar = ({
  collapsed, onChange, templates, companies,
}) => {
  const [isVisible, setVisible] = useState(false);
  const [weightingAssessment, setWeightingAssessment] = useState([]);
  const openedSubMenus = [];
  const selectedKeys = [];

  const { pathname, search } = useLocation();
  const {
    customerSlug, categorySlug, userSlug, assessmentSlug,
  } = useParams();

  const history = useHistory();
  const user = useSelector(userSelector);
  const assessments = useSelector(assessmentsSelector);
  const CollapseIcon = useMemo(() => (collapsed ? MenuUnfoldOutlined : MenuFoldOutlined), [collapsed]);

  const openModal = () => {
    setVisible(true);
  };

  const onCancel = useCallback((form) => {
    form.resetFields();
    setVisible(false);
  }, []);

  const isPathContains = (regexes) => regexes.some((regex) => regex.test(pathname));

  if (isPathContains([/^\/wizard/]) && userSlug) {
    openedSubMenus.push('kunden');
    selectedKeys.push(pathname);
  }

  if (isPathContains([/^\/wizard/]) && !userSlug) {
    openedSubMenus.push('kunden');
    selectedKeys.push(pathname);
  }
  if (isPathContains([/^\/wizard/]) && search) {
    openedSubMenus.push('rating');
    selectedKeys.push(`${pathname}${search}`);
  }

  if (isPathContains([/^\/wizard/]) && !search) {
    openedSubMenus.push('kunden');
    selectedKeys.push(`${pathname}/${history.location.state?.company}`);
  }

  if (isPathContains([/^\/user-progress/])) {
    openedSubMenus.push('kunden');
    selectedKeys.push(pathname);
  }
  if (isPathContains([/^\/answers/])) {
    openedSubMenus.push('kunden');
    selectedKeys.push(`${pathname}?admin`);
  }

  if (isPathContains([/^\/project-overview/])) {
    openedSubMenus.push('kunden');
    selectedKeys.push(pathname);
  }
  if (isPathContains([/^\/questionnaire-edit/, /^\/questionnaire-configuration/])) {
    openedSubMenus.push('fragebogen-config');
    selectedKeys.push(`${pathname}${search}`);
  }

  if (isPathContains([/^\/users-answer/])) {
    openedSubMenus.push('rating');
    selectedKeys.push(`${pathname}`);
  }
  if (isPathContains([/^\/auswertung/]) && !search && !pathname.includes('compare')) {
    openedSubMenus.push('kunden');
    selectedKeys.push(`${pathname}/${history.location.state?.company}`);
  }
  if (isPathContains([/^\/auswertung/]) && search && !pathname.includes('compare')) {
    openedSubMenus.push('kunden');
    selectedKeys.push(`${pathname}${search}/${history.location.state?.company}`);
  }
  if (isPathContains([/^\/auswertung/]) && !search && pathname.includes('compare')) {
    openedSubMenus.push('kunden');
    selectedKeys.push(`${pathname}/${history.location.state?.company}`);
  }

  const renderSub = useCallback((item) => {
    const o = {
      DEEP_DIVE: [],
      PERFORMANCE_CHECK: [],
    };
    item.types.forEach((i) => {
      Object.assign(o, { [i.type]: i.questions });
    });
    const src = item.image[0] === '/' ? item.image : `/${item.image}`;
    const count = `[${o.PERFORMANCE_CHECK.length}/${o.DEEP_DIVE.length}]`;
    let link = '';

    if (pathname.includes('questionnaire-edit')) {
      link = `/questionnaire-edit/${customerSlug}?category=${item.slug}`;
    }
    if (!pathname.includes('questionnaire-edit')) {
      link = `/questionnaire-configuration?category=${item.slug}`;
    }

    return (
      <Menu.Item key={link} className={styles.navContainer}>
        <Tooltip placement='topRight' title={item.name}>
          <NavLink to={link}>
            <div style={{ height: '55px', display: 'flex' }}>
              <div className={styles.iconTable}>
                <Image preview={false} className={styles.img} src={src} alt='Icon' />
              </div>
              <div className={styles.iconTable}>{count}</div>
            </div>
          </NavLink>
        </Tooltip>
      </Menu.Item>
    );
  },
  [pathname, customerSlug]);

  const renderKeys = (company) => {
    if (pathname.includes('user-progress')) {
      return `/user-progress/${company.slug}/${assessmentSlug}`;
    }

    if (pathname.includes('answers')) {
      return `/answers/${company.slug}/${assessmentSlug}/${categorySlug}/${userSlug}?admin`;
    }

    if (userSlug && isPathContains([/^\/wizard/])) {
      return `/wizard/${assessmentSlug}/${userSlug}/${company.slug}`;
    }
    if (!userSlug && isPathContains([/^\/wizard/])) {
      return `/wizard/${assessmentSlug}/${company.slug}`;
    }
    if (isPathContains([/^\/auswertung/]) && !search && !pathname.includes('compare')) {
      return `/auswertung/${assessmentSlug}/${categorySlug}/${company.slug}`;
    }
    if (isPathContains([/^\/auswertung/]) && search && !pathname.includes('compare')) {
      return `/auswertung/${assessmentSlug}${search}/${company.slug}`;
    }
    if (isPathContains([/^\/auswertung/]) && !search && pathname.includes('compare')) {
      return `/auswertung/${assessmentSlug}/compare/${company.slug}`;
    }

    return `/project-overview/${company.slug}`;
  };

  const renderWeightingMenuItem = (item) => (
    <Menu.Item
      key={pathname.includes('wizard')
        ? `/wizard/${item.slug}?weighting`
        : `/users-answer/${item.slug}/${categorySlug}/${userSlug}`}
      className={styles.navContainer}
    >
      <NavLink
        to={`/wizard/${item.slug}?weighting`}
        className={classNames({ [styles.noneEvents]: `${pathname}?weighting` === `/wizard/${item.slug}?weighting` })}
      >
        <span>{item.title || 'no title'}</span>
      </NavLink>
    </Menu.Item>
  );

  useEffect(() => {
    if (assessments) {
      setWeightingAssessment(
        assessments.filter((item) => (item.state === 'WEIGHTING'
          && item.is_active && item?.evaluator?.role === 'ADMIN')),
      );
    }
  }, [assessments]);

  return (
    <>
      <NewCompanyModal isVisible={isVisible} onCancel={onCancel} />
      <Sider
        trigger={(
          <div className={styles.trigger}>
            <CollapseIcon
              onClick={onChange}
            />
          </div>
        )}
        collapsible
        collapsed={collapsed}
        theme='light'
        className={collapsed ? styles.closeSider : styles.sidebar}
        width={312}
        collapsedWidth={150}
      >
        <Logo collapsed={collapsed} />
        {user.role === 'ADMIN' && (
          <div className={styles.addAssessment}>
            <Button
              data-cy='add-new-company'
              onClick={openModal}
              icon={<PlusOutlined />}
            >
              Erstellen
            </Button>
          </div>
        )}
        <Menu
          defaultSelectedKeys={[pathname]}
          defaultOpenKeys={openedSubMenus}
          selectedKeys={selectedKeys}
          theme='dark'
          mode='inline'
          className={styles.menu}
        >
          <SubMenu
            key='kunden'
            icon={<DashboardOutlined />}
            title='Kunden'
            className={styles.subTitle}
            data-cy='companies'
          >
            {companies.map((comp) => (
              <Menu.Item
                key={renderKeys(comp)}
                className={styles.navContainer}
              >
                <NavLink to={`/project-overview/${comp.slug}`}>
                  {comp.name}
                </NavLink>
              </Menu.Item>
            ))}
          </SubMenu>

          <SubMenu
            key='fragebogen-config'
            icon={<SettingOutlined />}
            title='Fragebogen'
            className={styles.subTitle}
            data-cy='questionnaire'
          >
            {templates.map(renderSub)}
          </SubMenu>
          <SubMenu key='rating' icon={<StarOutlined />} title='Bewertung' className={styles.subTitle}>
            { weightingAssessment && weightingAssessment.map(renderWeightingMenuItem) }
          </SubMenu>
        </Menu>
      </Sider>
    </>
  );
};

AdminSidebar.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  templates: PropTypes.array,
  companies: PropTypes.array,
};

AdminSidebar.defaultProps = {
  templates: [],
  companies: [],
};

export default AdminSidebar;
