import { error, success } from '@redux-requests/core';

import {
  ADD_NEW_QUESTION, CHANGE_GLOBAL_LOADING, CLEAR_CUSTOMER_ASSESSMENTS,
  CREATE_ASSESSMENT,
  DELETE_TEMPLATES, FETCH_ASSESSMENT_PROGRESS_SAM_ADMIN, FETCH_ASSESSMENT_REPORT, FETCH_ASSESSMENT_USERS,
  FETCH_ASSESSMENTS,
  FETCH_NEW_ASSESSMENT, FETCH_STATUS_ASSESSMENT, FETCH_USER_ASSESSMENT,
  GET_CUSTOMER_TEMPLATES,
  GET_TEMPLATES,
  MODIFY_TEMPLATES, RESET_FORM_DATA, RESET_NEW_ASSESSMENT, RESET_STATUS_ASSESSMENT, RESET_USER_ASSESSMENT_STATUS,
  SET_DEFAULT_ASSESSMENT_STATE,
  SET_FORM_DATA, SET_USER_ASSESSMENTS,
  UPDATE_ALL_TEMPLATES, UPDATE_ASSESSMENT,
  UPDATE_ASSESSMENT_QUESTION, UPDATE_TEMPLATES, UPDATE_USER_ASSESSMENTS,
  UPDATE_QUESTION,
} from './types';
import { modifyQuestion, reverseTypes } from '../../utils/helpers';
import { TOMORROW } from '../../utils/constants';

const initialState = {
  globalLoading: false,
  loading: false,
  error: false,
  formData: {},
  project: {},
  templates: [],
  assessments: [],
  newAssessment: {},
  assessmentUsers: [],
  statusAssessment: [],
  userAssessments: [],
  companyTemplates: [],
  userAssessmentStatus: [],
  assessmentProgressSamAdmin: [],
  assessmentReport: [],
  newAssessmentFormData: {
    is_template: false,
    state: 'INITIALIZED',
    is_active: true,
    reminders: [],
    end: TOMORROW.toISOString(),
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case (UPDATE_QUESTION):
      return { ...state, loading: true };
    case (CHANGE_GLOBAL_LOADING):
      return { ...state, globalLoading: action.data };
    case (FETCH_ASSESSMENT_PROGRESS_SAM_ADMIN):
      return { ...state, loading: true };
    case (RESET_NEW_ASSESSMENT): {
      return {
        ...state, newAssessment: {}, companyTemplates: [], assessmentUsers: [], statusAssessment: [],
      };
    }
    case (RESET_USER_ASSESSMENT_STATUS):
      return { ...state, userAssessmentStatus: [], assessmentReport: [] };

    case (RESET_STATUS_ASSESSMENT):
      return { ...state, statusAssessment: [] };
    case (FETCH_STATUS_ASSESSMENT):
      return { ...state, loading: true };
    case (SET_USER_ASSESSMENTS):
      return { ...state, userAssessments: action.data };
    case (UPDATE_USER_ASSESSMENTS): {
      const result = state.userAssessments.map((assessment) => {
        if (assessment.slug === action.data.assessment) {
          return {
            ...assessment,
            categories: assessment.categories.map((category) => {
              if (category.slug === action.data.categorySlug) {
                return {
                  ...category,
                  answered_questions_count: action.data.answerCount,
                };
              }
              return category;
            }),
          };
        }
        return assessment;
      });
      return { ...state, userAssessments: result };
    }
    case (FETCH_NEW_ASSESSMENT):
      return { ...state, loading: true };
    case (UPDATE_ASSESSMENT):
      return { ...state, loading: true };
    case (UPDATE_TEMPLATES):
      return { ...state, loading: true };
    case (SET_DEFAULT_ASSESSMENT_STATE):
      return { ...state, ...initialState };
    case (CLEAR_CUSTOMER_ASSESSMENTS):
      return { ...state, customerAssessments: [] };
    case (CREATE_ASSESSMENT):
      return { ...state, loading: true };
    case (FETCH_ASSESSMENTS):
      return { ...state, loading: true };
    case (GET_CUSTOMER_TEMPLATES):
      return { ...state, loading: true };
    case (FETCH_ASSESSMENT_USERS):
      return { ...state, loading: true };
    case (SET_FORM_DATA):
      return { ...state, newAssessmentFormData: { ...state.newAssessmentFormData, ...action.data } };
    case (RESET_FORM_DATA):
      return {
        ...state,
        companyTemplates: [],
        newAssessmentFormData: {
          is_template: false,
          state: 'INITIALIZED',
          is_active: true,
          reminders: [],
          end: TOMORROW.toISOString(),
        },
      };
    case (UPDATE_ALL_TEMPLATES):
      return { ...state, templates: action.data };
    case (MODIFY_TEMPLATES): {
      const update = state.templates.map((item) => {
        if (item.slug === action.payload.category) {
          const result = item.types.map((i) => {
            if (i.slug === action.payload.assessment) {
              const filter = i.questions.filter((o) => o.slug !== action.payload.slug);
              return {
                ...i,
                questions: filter.map((it, index) => ({
                  ...it,
                  index: String(index + 1),
                  order: index + 1,
                })),
              };
            }
            return i;
          });
          return { ...item, types: result };
        }
        return item;
      });
      return { ...state, templates: update };
    }
    case (UPDATE_ASSESSMENT_QUESTION): {
      const result = modifyQuestion(state.templates, action.data);
      return { ...state, templates: result };
    }
    case (DELETE_TEMPLATES):
      return { ...state, templates: [] };
    case success(UPDATE_QUESTION):
      return { ...state, loading: false };
    case success(FETCH_ASSESSMENT_REPORT):
      return { ...state, globalLoading: false, assessmentReport: action.response.data };
    case success(FETCH_ASSESSMENT_PROGRESS_SAM_ADMIN):
      return { ...state, loading: false, assessmentProgressSamAdmin: action.response.data };
    case success(FETCH_USER_ASSESSMENT):
      return { ...state, userAssessmentStatus: action.response.data };
    case success(FETCH_STATUS_ASSESSMENT):
      return { ...state, loading: false, statusAssessment: action.response.data };
    case success(FETCH_NEW_ASSESSMENT):
      return { ...state, loading: false, newAssessment: action.response.data };
    case success(UPDATE_TEMPLATES):
      return { ...state, loading: false };
    case success(UPDATE_ASSESSMENT): {
      const updated = state.assessments.map((item) => {
        if (item.slug === action.response.data.slug) {
          return action.response.data;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        assessments: updated,
        newAssessment: { ...state.newAssessment, ...action.response.data },
      };
    }
    case success(FETCH_ASSESSMENT_USERS):
      return { ...state, loading: false, assessmentUsers: action.response.data };
    case success(CREATE_ASSESSMENT):
      return { ...state, loading: false, assessments: state.assessments.concat(action.response.data) };
    case success(FETCH_ASSESSMENTS):
      return { ...state, loading: false, assessments: action.response.data };
    case success(GET_CUSTOMER_TEMPLATES):
      return { ...state, loading: false, companyTemplates: action.response.data };
    case success(ADD_NEW_QUESTION): {
      const update = state.templates.map((item) => {
        if (item.slug === action.response.data.category) {
          const result = item.types.map((i) => {
            if (i.slug === action.response.data.assessment) {
              return {
                ...i,
                questions: i.questions.concat({
                  ...action.response.data,
                  answer_options: action.response.data?.answer_options ? action.response.data?.answer_options : [],
                  assessment_type: i.type,
                }),
              };
            }
            return i;
          });
          return { ...item, types: result };
        }
        return item;
      });
      return { ...state, templates: update };
    }
    case success(GET_TEMPLATES): {
      const templates = reverseTypes(action.response.data);
      return { ...state, templates };
    }
    case error(UPDATE_QUESTION):
      return { ...state, loading: false };
    case error(UPDATE_TEMPLATES):
      return { ...state, loading: false };
    case error(FETCH_STATUS_ASSESSMENT):
      return { ...state, loading: false };
    case error(FETCH_ASSESSMENT_USERS):
      return { ...state, loading: false };
    case error(FETCH_NEW_ASSESSMENT):
      return { ...state, loading: false };
    case error(UPDATE_ASSESSMENT):
      return { ...state, loading: false };
    case error(CREATE_ASSESSMENT):
      return { ...state, loading: false };
    case error(FETCH_ASSESSMENTS):
      return { ...state, loading: false };
    case error(GET_CUSTOMER_TEMPLATES):
      return { ...state, loading: false };
    case error(GET_TEMPLATES): {
      return { ...state, loading: false };
    }
    default: return state;
  }
};
export default reducer;
