import PropTypes from 'prop-types';

import { Col, Steps } from 'antd';

import classNames from 'classnames';
import styles from './index.module.scss';

const { Step } = Steps;

const Stepper = ({ statuses }) => {
  const renderStatuses = (status, index) => (
    <Step
      key={index}
      status={status}
      className={classNames({ [styles.skipQuestion]: status === 'skip' })}
    />
  );

  return (
    <Col>
      <Steps size='small' responsive={false}>
        {statuses.map(renderStatuses)}
      </Steps>
    </Col>
  );
};

Stepper.propTypes = {
  statuses: PropTypes.array.isRequired,
};
export default Stepper;
