import { useEffect, useRef, useState } from 'react';

import { Spin } from 'antd';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import NewAssessment from './WizardSteps/index';

import {
  resetUserAssessmentStatus,
  resetStatusAssessment,
  changeGlobalLoading,
  fetchAssessmentUsers,
  fetchNewAssessment,
  fetchStatusAssessment,
  resetNewAssessment, fetchAssessmentProgressAdminSam,
} from '../../store/assessments/actions';

import { fetchCategories } from '../../store/categories/actions';

import { globalLoadingSelector, newAssessmentSelector } from '../../store/assessments/selectors';
import { userSelector } from '../../store/auth/selectors';
import { responseHandler } from '../../utils/helpers';

import {
  CURRENT_STEP_WITH_WEIGHTING,
  CURRENT_STEP_WITHOUT_WEIGHTING,
  WIZARD_CONFIG,
} from '../../utils/constants';

const Index = () => {
  const [config, setConfig] = useState({});
  const [modifiedConfig, setModifiedConfig] = useState({});

  const { assessmentSlug } = useParams();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const assessmentData = useSelector(newAssessmentSelector);
  const loading = useSelector(globalLoadingSelector) || false;
  const user = useSelector(userSelector);

  const stepRef = useRef(-1);

  useEffect(() => {
    history.listen((loc) => {
      stepRef.current = loc?.state?.step || -1;
    });
  }, [history]);

  useEffect(() => {
    if (user) {
      (async () => {
        dispatch(changeGlobalLoading(true));
        dispatch(await resetUserAssessmentStatus());
        dispatch(await resetNewAssessment());
        dispatch(await fetchAssessmentUsers(assessmentSlug)).then((res) => responseHandler(res));
        dispatch(await resetStatusAssessment());
        await dispatch(fetchNewAssessment(assessmentSlug)).then((res) => responseHandler(res));
        await dispatch(fetchStatusAssessment(assessmentSlug)).then((res) => responseHandler(res));
        if (search) {
          // this request is necessary for the wizard step - 'rating'
          await dispatch(fetchAssessmentProgressAdminSam(assessmentSlug, true)).then((res) => responseHandler(res));
        }
        dispatch(changeGlobalLoading(false));
      })();
    }
  }, [dispatch, assessmentSlug, search, user]);

  useEffect(() => {
    if (!!Object.keys(assessmentData).length
      && (!assessmentData.has_evaluation && assessmentData.state !== 'WEIGHTING')
      && assessmentData.type === 'DEEP_DIVE') {
      stepRef.current = CURRENT_STEP_WITHOUT_WEIGHTING[assessmentData.state];
      const keys = Object.keys(modifiedConfig);
      const updated = {};
      keys.forEach((key) => {
        const arr = modifiedConfig[key].map((item) => {
          if (item.title === 'Auswertung') {
            return {
              ...item,
              type: 'assessment',
            };
          }
          return item;
        });
        Object.assign(updated, { [key]: arr.filter((item) => item.title !== 'Bewertung') });
      });
      setConfig(updated);
    } else if (!!Object.keys(assessmentData).length
      && (!assessmentData.has_evaluation && assessmentData.state !== 'WEIGHTING')
      && assessmentData.type === 'PERFORMANCE_CHECK') {
      stepRef.current = CURRENT_STEP_WITHOUT_WEIGHTING[assessmentData.state];
      const keys = Object.keys(modifiedConfig);
      const updated = {};
      keys.forEach((key) => {
        Object.assign(updated, { [key]: modifiedConfig[key].filter((item) => item.title !== 'Bewertung') });
      });
      setConfig(updated);
    } else if (!!Object.keys(assessmentData).length
      && (assessmentData.state === 'WEIGHTING' || assessmentData.has_evaluation)) {
      stepRef.current = CURRENT_STEP_WITH_WEIGHTING[assessmentData.state];
      setConfig(modifiedConfig);
    }
  }, [assessmentData, modifiedConfig, user.role]);

  useEffect(() => {
    dispatch(fetchCategories()).then((res) => {
      if (res.error) {
        return responseHandler(res);
      }
      return setModifiedConfig({
        ...WIZARD_CONFIG,
        [user.role]: WIZARD_CONFIG[user.role].map((item) => {
          if (Object.prototype.hasOwnProperty.call(item, 'tabs')) {
            return {
              ...item,
              tabs: item.tabs.map((tab) => {
                if (tab.type === 'categories') {
                  return {
                    ...tab,
                    data: res.data,
                  };
                }
                return tab;
              }),
            };
          }
          return item;
        }),
      });
    });
  }, [dispatch, user]);

  return (
    <Spin spinning={loading}>
      <NewAssessment {...assessmentData} step={stepRef.current} config={config} />
    </Spin>
  );
};
export default Index;
