import {
  useCallback, useEffect, useRef, useState,
} from 'react';

import {
  Form, Row, Col,
} from 'antd';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import RadioButtonGroup from './RadioButtonGroup';
import Slider from './Slider';
import Preview from './Preview';

import { formDataSelector } from '../../store/assessments/selectors';

import {
  createAssessment,
  fetchAssessmentCustomerConfig,
  resetForm,
} from '../../store/assessments/actions';
import { fetchCategories } from '../../store/categories/actions';
import { createPreAssessment } from '../../store/preassessments/actions';

import { responseHandler } from '../../utils/helpers';
import {
  COMPONENT_TYPES,
  CREATE_ASSESSMENT_CONFIG,
} from '../../utils/constants';

import styles from './index.module.scss';

const COMPONENTS = {
  [COMPONENT_TYPES.RADIO_BUTTON_GROUP]: RadioButtonGroup,
  [COMPONENT_TYPES.SLIDER]: Slider,
};

const TITLES = {
  DEEP_DIVE: 'Statusanalyse Ex ante Selbsteinschätzung',
  PERFORMANCE_CHECK: 'Performance Check Ex ante Selbsteinschätzung',
};

const CreateAssessment = () => {
  const [componentLevel, setComponentLevel] = useState([]);
  const [categories, setCategories] = useState([]);
  const [headline, setHeadline] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [data, setData] = useState({});
  const [preAssessmentData, setPreAssessmentData] = useState({});
  const [show, setShow] = useState(false);
  const [assessmentSlug, setSlug] = useState('');

  const newAssessmentForm = useSelector(formDataSelector);

  const updateRef = useRef({});
  const companyTemplates = useRef([]);
  const payloadRef = useRef({});
  const componentMounted = useRef(true);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();

  const handleConfirm = useCallback(async (val) => {
    const payload = {
      value: preAssessmentData[val],
      category: val,
      assessment: assessmentSlug,
    };
    await dispatch(createPreAssessment(payload)).then((res) => responseHandler(res));
    setCurrentStep(currentStep + 1);
    if (currentStep === (componentLevel.length - 1)) {
      setShow(true);
    }
  }, [preAssessmentData, assessmentSlug, dispatch, componentLevel.length, currentStep]);

  const changeComponentLevel = useCallback((update) => {
    const level = Object.keys(update).pop();
    setComponentLevel((s) => {
      const foundItem = s.find((item) => item.name === level);
      if (foundItem?.children) {
        const selected = foundItem.children.find((item) => item.value === update[level]);

        if (selected.text === 'SAM' || selected.text === 'AWB') {
          setHeadline('Statusanalyse \n Ex ante Selbsteinschätzung');
        }
        if (selected.text === 'Mit Bewertung' && selected.value) {
          setHeadline('Statusanalyse mit Bewertung\nAuswahl des Bewerters');
        }
        if (selected.text === 'Ohne Bewertung' && !selected.value) {
          setHeadline('Statusanalyse \n Ex ante Selbsteinschätzung');
        }
        if (selected?.children) {
          return selected.children;
        }
        setCurrentStep(currentStep + 1);
        return [...s];
      }
      return [...s];
    });
  }, [currentStep]);

  const onSubmit = useCallback(() => {
    dispatch(createAssessment(payloadRef.current))
      .then((resp) => {
        if (resp.error) {
          responseHandler(resp);
          history.push('/');
        } else if (payloadRef.current.has_preassessment) {
          changeComponentLevel(updateRef.current);
          setSlug(resp.data.slug);
        } else {
          history.push(`wizard/${resp.data.slug}`);
          dispatch(resetForm());
          payloadRef.current = {};
          updateRef.current = {};
        }
      });
  }, [changeComponentLevel, dispatch, history]);

  const handleValuesChange = useCallback(async (update) => {
    updateRef.current = update;
    const [[key, val]] = Object.entries(update);
    let error = false;
    setData({ ...data, ...update });
    setPreAssessmentData({ ...preAssessmentData, ...update });
    // eslint-disable-next-line camelcase
    const { assessment_type, ...rest } = data;
    const payload = {
      ...rest,
      ...newAssessmentForm,
      type: assessment_type,
    };
    if (key === 'has_preassessment' && val) {
      payloadRef.current = { ...payload, has_preassessment: true };
      const versions = companyTemplates.current[0]?.types[0];
      if (companyTemplates.current.length && +versions.version !== +versions.master_version) {
        error = true;
        onSubmit();
      } else {
        await dispatch(createAssessment({
          ...payload,
          has_preassessment: true,
        }))
          .then((res) => {
            if (res.error) {
              error = true;
              responseHandler(res);
              history.push('/');
            } else {
              setSlug(res.data.slug);
            }
          });
      }
    }
    if (key === 'has_preassessment' && !val) {
      payloadRef.current = { ...payload, has_preassessment: false };

      const versions = companyTemplates.current[0]?.types[0];
      if (companyTemplates.current.length && +versions.version !== +versions.master_version) {
        error = true;
        onSubmit();
      } else {
        await dispatch(createAssessment({ ...payload, has_preassessment: false }))
          .then((res) => {
            if (res.error) {
              error = true;
              responseHandler(res);
              history.push('/');
            } else {
              history.push(`wizard/${res.data.slug}`);
              dispatch(resetForm());
            }
          });
      }
    }

    if (key === 'has_evaluation' && !val) {
      setComponentLevel((s) => s.filter((item) => item.name !== 'evaluator_type'));
    }
    if (!error) {
      changeComponentLevel(update);
    }
  }, [data, preAssessmentData, newAssessmentForm, onSubmit, dispatch, history, changeComponentLevel]);

  useEffect(() => {
    if (newAssessmentForm?.company) {
      dispatch(fetchAssessmentCustomerConfig(newAssessmentForm?.company)).then((resp) => {
        if (resp.error) {
          responseHandler(resp);
        } else {
          companyTemplates.current = resp.data;
        }
      });
    }
    return () => {
      companyTemplates.current = [];
    };
  }, [dispatch, newAssessmentForm]);

  const setDefaultState = () => {
    componentMounted.current = false;
    setComponentLevel([]);
    setCurrentStep(0);
    setShow(false);
    setSlug('');
    setData({});
    setPreAssessmentData({});
    setHeadline('');
    setCategories([]);
  };

  useEffect(() => {
    dispatch(fetchCategories()).then((res) => responseHandler(res, () => {
      const defaultValuesPreassessment = {};
      const modified = res.data.map((item) => ({ ...item, type: 'slider' }));
      res.data.forEach((item) => {
        Object.assign(defaultValuesPreassessment, { [item.slug]: 0 }, {});
      });
      const result = CREATE_ASSESSMENT_CONFIG.map((item) => ({
        ...item,
        children: item.children.map((i) => ({
          ...i,
          children: i.children.concat(modified),
        })),
      }));
      if (componentMounted.current) {
        setCategories(res.data);
        setComponentLevel(result);
        setPreAssessmentData(defaultValuesPreassessment);
      }
      return () => {
        setDefaultState();
        componentMounted.current = false;
      };
    }));
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(newAssessmentForm).length < 6 && componentMounted.current) {
      history.push('/');
      setDefaultState();
    }
    return () => {
      setDefaultState();
      componentMounted.current = false;
    };
  }, [history, newAssessmentForm]);

  const renderComponent = useCallback((item, idx) => {
    const Component = COMPONENTS[item.type];
    const props = {
      ...item,
      ...{ headline, sliderTitle: TITLES[data.assessment_type], assessment: assessmentSlug },
    };
    return (currentStep === idx)
      ? <Component key={idx.toString()} onConfirm={handleConfirm} {...props} />
      : null;
  }, [assessmentSlug, data.assessment_type, headline, currentStep, handleConfirm]);

  return (
    <div className={styles.container}>
      <Row justify='center'>
        <Col>
          <Form form={form} onValuesChange={handleValuesChange}>
            { componentLevel.map(renderComponent) }
          </Form>
        </Col>
      </Row>
      {show && (
        <Preview
          title={TITLES[preAssessmentData.assessment_type]}
          categories={categories}
          assessmentSlug={assessmentSlug}
        />
      )}
    </div>
  );
};

export default CreateAssessment;
