import PropTypes from 'prop-types';

import {
  Card, Checkbox, DatePicker, Form,
} from 'antd';
import locale from 'antd/es/date-picker/locale/de_DE';

import moment from 'moment';

import { useEffect } from 'react';
import styles from '../../index.module.scss';

const { Item } = Form;

const options = [
  { label: 'Keine', value: '' },
  { label: '1 Tag vor geplantem Abschluss', value: '1 DAY' },
  { label: '3 Tage vor geplantem Abschluss', value: '3 DAYS' },
  { label: '1 Woche vor geplantem Abschluss', value: '1 WEEK' },
];

const Zeitrahmen = ({ formRef }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    formRef.current = form;
  }, [formRef, form]);

  return (
    <div className={styles.timeframeContainer}>
      <Form
        form={form}
        className={styles.timeframeForm}
        initialValues={{ reminders: [''] }}
      >
        <Card
          title='Geplanter Abschluss des Assessments:'
          className={styles.datePickerCard}
        >
          <Item
            name='end'
            rules={[{
              required: true,
              message: 'Bitte geben Sie das Datum ein!',
            }]}
          >
            <DatePicker
              format='DD.MM.YYYY'
              locale={locale}
              disabledDate={(current) => moment().add(-1, 'days') >= current}
            />
          </Item>
        </Card>
        <Card title='Erinnerungsfunktion'>
          <Item
            className={styles.reminders}
            name='reminders'
            getValueFromEvent={(value) => {
              const values = [].concat(value || []).filter((val) => !!val);
              return values.length === 0 ? [''] : values;
            }}
          >
            <Checkbox.Group options={options} />
          </Item>
        </Card>
      </Form>
    </div>
  );
};

Zeitrahmen.propTypes = {
  formRef: PropTypes.object,
};

Zeitrahmen.defaultProps = {
  formRef: { current: null },
};
export default Zeitrahmen;
