import { error, success } from '@redux-requests/core';
import { FETCH_ALL_ANSWERS } from './types';

const initialState = {
  loading: false,
  error: false,
  answers: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case (FETCH_ALL_ANSWERS):
      return { ...state, loading: true };
    case success(FETCH_ALL_ANSWERS):
      return { ...state, loading: false, answers: action.response.data };
    case error(FETCH_ALL_ANSWERS):
      return { ...state, loading: false };
    default: return state;
  }
};

export default reducer;
