import PropTypes from 'prop-types';

import {
  Button, Card, Col, Divider, Form, Input, List, Radio, Row, Tooltip, message, Upload,
} from 'antd';

import {
  LeftOutlined, RightOutlined, UploadOutlined, UserOutlined,
} from '@ant-design/icons';

import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { userSelector } from '../../../../store/auth/selectors';
import { statusAssessmentSelector } from '../../../../store/assessments/selectors';
import { createAnswer, updateAnswer } from '../../../../store/answers/actions';

import { responseHandler } from '../../../../utils/helpers';

import styles from '../../../../views/UserCategoryQuestions/index.module.scss';

const { TextArea } = Input;
const { Item } = Form;
const { Group } = Radio;

const YourAnswer = ({
  question, isLast, goBack, update,
}) => {
  const [primary, setPrimary] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const [form] = Form.useForm();

  const currentUser = useSelector(userSelector);
  const { assessmentSlug } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const statusAssessment = useSelector(statusAssessmentSelector);

  const onFinish = async () => {
    setLoading(true);
    try {
      let result = {};
      const none = form.getFieldValue('none');
      if (none === '-1') {
        const formData = form.getFieldsValue();
        result = { ...formData, answer: formData.none };
      } else {
        const formData = await form.validateFields();
        Object.assign(result, formData);
      }
      const payload = {
        files: result?.files?.fileList.length ? result.files.fileList : [],
        suggestion: result.suggestion || '',
        question: question.slug,
        answer: result.answer,
        reason: result.reason,
        is_answered: true,
        is_skipped: false,
      };
      let response = {};
      if (question?.answerSlug) {
        response = await dispatch(updateAnswer({
          ...payload,
          answerSlug: question.answerSlug,
        })).then((res) => {
          if (res.error) {
            responseHandler(res);
          }
          return res.data;
        });
      } else {
        response = await dispatch(createAnswer(payload)).then((res) => {
          if (res.error) {
            responseHandler(res);
          }
          return res.data;
        });
      }
      if (response) {
        form.resetFields();
        update({
          ...question,
          ...payload,
          files: response?.files || [],
          question: question.question,
          answerSlug: response.slug,
          is_answered: true,
          is_skipped: false,
        });
        if (isLast) {
          form.resetFields();
          history.push(`/wizard/${assessmentSlug}?weighting`);
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      message.error('Validation error');
    }
  };
  const renderUserData = (slug) => {
    const user = statusAssessment.find((item) => item?.user?.slug === slug);
    const firstName = user?.user?.first_name;
    const lastName = user?.user?.last_name;
    return (firstName && lastName) ? `${firstName} ${lastName}` : user?.user?.email;
  };

  const actions = [
    <Button
      icon={<LeftOutlined />}
      key='back'
      size='small'
      disabled={loading}
      onClick={() => goBack(question)}
    >
      Vorherige Frage
    </Button>,
    <Button
      disabled={!Object.keys(question).length}
      onClick={onFinish}
      icon={<RightOutlined />}
      key='next'
      type='default'
      size='small'
      loading={loading}
    >
      {!isLast ? 'Nächste Frage' : 'Fertig'}
    </Button>,
  ];

  const titleContent = () => (
    <Row justify='space-between'>
      {question?.title?.length >= 110
        ? (
          <Tooltip placement='top' title={question?.title}>
            {question?.title.slice(0, 110)}
            ...
          </Tooltip>
        )
        : <Col>{question?.title}</Col>}
      {question?.identifier && currentUser.role === 'ADMIN' && (
        <>
          <Col className={styles.container}>
            <span className={styles.questionIdentifier}>
              [
              {question.identifier}
              ]
            </span>
          </Col>
        </>
      )}
    </Row>
  );

  const renderFiles = (item, index) => (
    <div key={index.toString()}>
      <a href={item.file} download={item.name} target='_blank' rel='noreferrer'>{item.name}</a>
    </div>
  );

  const handleChangeFormValue = useCallback(() => {
    form.resetFields(['answer']);
    form.setFieldsValue({ answer: '', none: '-1' });
    setPrimary(true);
  }, [form]);

  const onFormChanged = useCallback((value) => {
    const [[key]] = Object.entries(value);
    if (key === 'answer') {
      form.setFieldsValue({ none: '' });
      setPrimary(false);
    }
    const val = form.getFieldValue('answer');
    if (val === '0' || val === '5') {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [form]);

  const renderAnswer = (answer) => {
    if (answer === '-1') {
      return 'keine Antwort möglich';
    }
    if (answer === 'false') {
      return 'Nein';
    }
    if (answer === 'true') {
      return 'Ja';
    }
    return answer || '';
  };

  useEffect(() => {
    if (Object.keys(question).length) {
      if (['0', '5'].includes(question.answer)) {
        setIsVisible(true);
      }
      if (!['0', '5'].includes(question.answer)) {
        setIsVisible(false);
      }
      if (question.answer === '-1') {
        setPrimary(true);
        form.setFieldsValue({
          none: question.answer, answer: '', suggestion: question.suggestion || '', reason: question.reason || '',
        });
      } else {
        setPrimary(false);
        form.setFieldsValue({
          answer: question.answer, none: '', suggestion: question.suggestion || '', reason: question.reason || '',
        });
      }
    }
  }, [question, form]);

  return (
    <div className={styles.questionCard}>
      {!!question?.usersAnswer?.length && (
        <Card title='Antworten' className={styles.usersCard}>
          <List
            itemLayout='horizontal'
            pagination={question.usersAnswer.length > 10}
            dataSource={question.usersAnswer}
            renderItem={(item, idx) => (
              <List.Item key={idx.toString()}>
                <List.Item.Meta
                  avatar={<UserOutlined style={{ fontSize: '30px', color: '#ddd' }} />}
                  title={renderUserData(item.user)}
                  description={renderAnswer(item.answer)}
                />
              </List.Item>
            )}
          />
        </Card>
      )}
      <Form form={form} layout='vertical' onValuesChange={onFormChanged}>
        <Card title={titleContent()} actions={actions}>
          {!!Object.keys(question).length && (
            <>
              <Row className={styles.questionContainer}>
                <Col className={styles.question}><p>{question.question}</p></Col>
                <Col className={styles.hint}>
                  <p>{question?.hint}</p>
                </Col>
              </Row>
              <Row justify='space-between'>
                <Col span={17}>
                  <Item
                    name='answer'
                    className={styles.radio}
                    rules={[{ required: true, message: 'Bitte geben Sie Ihre Antwort ein!' }]}
                  >
                    <Group>
                      <Radio value='0'>0</Radio>
                      <Radio value='5'>5</Radio>
                      <Radio value='8'>8</Radio>
                      <Radio value='10'>10</Radio>
                    </Group>
                  </Item>
                </Col>
                <Col span={6} className={styles.answerNoneContainer}>
                  <Item name='none'>
                    <Button
                      type={primary ? 'primary' : ''}
                      onClick={handleChangeFormValue}
                    >
                      keine Antwort möglich
                    </Button>
                  </Item>
                </Col>
              </Row>
            </>
          )}
          <Divider style={{ margin: 0 }} />
        </Card>
        {isVisible && (
          <Card title='Verbesserungsvorschlag' className={styles.reasonCard}>
            <Item
              name='suggestion'
              rules={[{ required: true, message: 'Bitte geben Sie einen Verbesserungsvorschlag ein!' }]}
            >
              <TextArea />
            </Item>
          </Card>
        )}
        <Card title='Anmerkung' className={styles.reasonCard}>
          <Item name='reason'><TextArea /></Item>
        </Card>
        {question.has_file_upload && (
          <Card title='Datei Anhänge' className={styles.uploadFileCard}>
            {question.files && question.files.map(renderFiles)}
            <Item
              name='files'
              valuePropName='files'
            >
              <Upload
                multiple
                accept='.pdf, .jpg, .png'
                beforeUpload={() => false}
              >
                <Button icon={<UploadOutlined />}>Hochladen</Button>
              </Upload>
            </Item>
          </Card>
        )}
      </Form>
    </div>
  );
};

YourAnswer.propTypes = {
  question: PropTypes.object,
  isLast: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

YourAnswer.defaultProps = {
  question: {},
};

export default YourAnswer;
