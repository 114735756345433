import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'antd/es/modal/Modal';
import { Button, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { confirmTwoFa, deleteTwoFa, enableTwoFa } from '../../../store/auth/actions';
import { getTwoFaLink } from '../../../store/auth/selectors';

const QRCode = require('qrcode.react');

const TwoFaDialog = ({ user, visible, setVisible }) => {
  const dispatch = useDispatch();
  const twoFaLink = useSelector(getTwoFaLink);

  useEffect(() => {
    if (!user?.is_2fa_enabled) {
      dispatch(enableTwoFa());
    }
  }, [dispatch, user.is_2fa_enabled]);

  const disableTwoFa = () => {
    setVisible(false);
    dispatch(deleteTwoFa());
  };

  const confirmCode = () => {
    dispatch(confirmTwoFa());
  };

  const onCancel = () => {
    if (!user.is_2fa_enabled) {
      dispatch(deleteTwoFa());
    }
    setVisible(false);
  };

  const changeTwoFaDevice = () => dispatch(enableTwoFa());

  return (
    <Modal
      title='2 Faktor Authentifizierung'
      visible={visible}
      onOk={() => setVisible(!visible)}
      centered
      onCancel={onCancel}
      footer={[
        <Button key='cancel' onClick={onCancel}>Abbrechen</Button>,
        <Button key='ok' type='primary' onClick={user?.is_2fa_enabled ? () => setVisible(!visible) : confirmCode}>
          {user?.is_2fa_enabled ? 'Ok' : 'Speichern'}
        </Button>,
      ]}
    >
      <div>
        {user?.is_2fa_enabled ? (
          <div>
            <Typography>Sie haben derzeit 2FA aktiviert. </Typography>
            <Button onClick={disableTwoFa}>2FA deaktivieren</Button>
            <Button onClick={changeTwoFaDevice}>2FA Gerät ändern</Button>
          </div>
        ) : (
          <div>
            <Typography>
              Sie können nun eine 2FA Authenticator App registrieren.
              Klicken Sie auf OK, wenn Sie den QR-Code zu Ihrem Gerät hinzugefügt haben.
              Wenn der QR-Code nicht funktioniert, klicken Sie auf Aktualisieren.
            </Typography>
            <div>
              <QRCode value={twoFaLink || ''} />
              <Button type='primary' onClick={() => dispatch(enableTwoFa())}>QR-Code Aktualisieren</Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

TwoFaDialog.propTypes = {
  user: PropTypes.object,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
};

TwoFaDialog.defaultProps = {
  user: {},
  visible: false,
  setVisible: null,
};

export default TwoFaDialog;
