import { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { PublicRoutes, PrivateRoutes } from '../routes';
import AuthorizedLayout from './common/Layout/AuthorizedLayout';
import DefaultLayout from './common/Layout/DefaultLayout';

const App = () => {
  const renderRoute = (route) => (
    <Route
      key={route.path}
      exact
      path={route.path}
    >
      <route.component />
    </Route>
  );

  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Route path={PublicRoutes.map((i) => i.path)} exact>
          <DefaultLayout>
            <Switch>
              { PublicRoutes.map(renderRoute) }
            </Switch>
          </DefaultLayout>
        </Route>
        <Route path={PrivateRoutes.map((i) => i.path)} exact>
          <AuthorizedLayout>
            <Switch>
              { PrivateRoutes.map(renderRoute) }
            </Switch>
          </AuthorizedLayout>
        </Route>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
