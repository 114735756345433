import { error, success } from '@redux-requests/core';
import { FETCH_CATEGORIES, SET_DEFAULT_CATEGORIES_STATE } from './types';

const initialState = {
  loading: false,
  error: false,
  categories: [],
  category: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case (SET_DEFAULT_CATEGORIES_STATE):
      return { ...state, ...initialState };
    case (FETCH_CATEGORIES):
      return { ...state, loading: true };
    case success(FETCH_CATEGORIES):
      return { ...state, loading: false, categories: action.response.data };
    case error(FETCH_CATEGORIES):
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;
