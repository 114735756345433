import PropTypes from 'prop-types';

import { Row, Col } from 'antd';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import classNames from 'classnames';

import UserAssessmentProgress from './UserAssessmentProgress/UserAssessmentProgress';

import styles from './index.module.scss';

import { statusAssessmentSelector } from '../../../store/assessments/selectors';

const StatusAssessment = ({ handleChangeLocation, title }) => {
  const statusAssessment = useSelector(statusAssessmentSelector);

  const { pathname } = useLocation();

  const goUserDetail = (user) => {
    handleChangeLocation(user);
  };

  const renderContent = (item, idx) => {
    const name = item?.user?.first_name;
    const lastName = item?.user?.last_name;
    const fullName = (name && lastName) ? `${name} ${lastName}` : item?.user?.email || '';
    const totalQuestion = item?.total_questions || 0;
    const totalAnswers = item?.answered_questions || 0;
    let percent = '';
    const result = ((totalAnswers * 100) / totalQuestion) === 0
      ? '0'
      : ((totalAnswers * 100) / totalQuestion).toFixed(2);
    if (Number.isNaN(Number(result))) {
      percent = '0';
    } else {
      percent = result;
    }
    return (
      <Col
        span={8}
        key={idx.toString()}
        onClick={() => goUserDetail(item)}
      >
        <UserAssessmentProgress fullName={fullName} percent={percent} />
      </Col>
    );
  };
  const calculateProgress = () => {
    const percents = [];
    (statusAssessment || []).forEach((item) => {
      const totalQuestion = item?.total_questions || 0;
      const totalAnswers = item?.answered_questions || 0;
      const percent = ((totalAnswers * 100) / totalQuestion) === 0
        ? '0'
        : ((totalAnswers * 100) / totalQuestion).toFixed(2);
      if (!Number.isNaN(Number(percent))) {
        percents.push(percent);
      }
    });
    if (percents.length) {
      const result = percents.reduce((all, a) => all + Number(a), 0) / percents.length;
      return result === 0 ? '0 %' : `${result.toFixed(2)} %`;
    }
    return '0 %';
  };

  return (
    <div className={classNames({
      [styles.assessmentProgress]: pathname.includes('wizard'),
    })}
    >
      <Row justify='center'>
        <Col span={24}>
          <p style={{ textAlign: 'center', fontSize: '25px' }}>{ title }</p>
          <p style={{ textAlign: 'center', fontSize: '30px' }}>{calculateProgress()}</p>
        </Col>
      </Row>
      <Row gutter={[100, 20]} style={{ marginRight: 0, marginLeft: 0 }} justify='space-around'>
        {(statusAssessment || []).map(renderContent)}
      </Row>
    </div>
  );
};

StatusAssessment.propTypes = {
  handleChangeLocation: PropTypes.func,
  title: PropTypes.string,
};
StatusAssessment.defaultProps = {
  handleChangeLocation: () => null,
  title: '',
};

export default StatusAssessment;
