import PropTypes from 'prop-types';

import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Button, Layout, Menu } from 'antd';
import {
  DashboardOutlined, MenuFoldOutlined, MenuUnfoldOutlined, PlusOutlined, StarOutlined, FolderOutlined, CommentOutlined,
} from '@ant-design/icons';

import classNames from 'classnames';
import Logo from './Logo';

import { userSelector } from '../../../../store/auth/selectors';

import styles from '../index.module.scss';
import { assessmentsSelector, globalLoadingSelector } from '../../../../store/assessments/selectors';

import NewAssessmentModal from '../../modals/NewAssessmentModal/NewAssessmentModal';

const { Sider } = Layout;
const { SubMenu } = Menu;

const SideBar = ({ collapsed, onChange, companies }) => {
  const [openKeys, setOpenKeys] = useState([]);
  const [isDisabled, setDisabled] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [archiveAssessments, setArchive] = useState([]);
  const [activeAssessments, setActive] = useState([]);
  const [weightingAssessment, setWeightingAssessment] = useState([]);

  const { categorySlug, userSlug, companySlug } = useParams();
  const { pathname, search } = useLocation();

  const selectedKeys = [];
  const openedSubMenus = [];

  const globalLoading = useSelector(globalLoadingSelector);
  const user = useSelector(userSelector);
  const assessments = useSelector(assessmentsSelector);

  const CollapseIcon = useMemo(() => (collapsed ? MenuUnfoldOutlined : MenuFoldOutlined), [collapsed]);

  const isPathContains = (regexes) => regexes.some((regex) => regex.test(pathname));

  if (isPathContains([/^\/answers/])) {
    openedSubMenus.push('assessments');
    selectedKeys.push(`${pathname}`);
  }
  if (isPathContains([/^\/wizard/]) && userSlug) {
    openedSubMenus.push('assessments');
    selectedKeys.push(pathname);
  }

  if (isPathContains([/^\/wizard/]) && !userSlug && !search) {
    openedSubMenus.push('assessments');
    selectedKeys.push(pathname);
  }
  if (isPathContains([/^\/wizard/]) && !search) {
    openedSubMenus.push('assessments');
    selectedKeys.push(pathname);
  }

  if (isPathContains([/^\/wizard/]) && search && search.includes('weighting')) {
    openedSubMenus.push('rating');
    selectedKeys.push(`${pathname}${search}`);
  }

  if (isPathContains([/^\/wizard/]) && search && search.includes('archive')) {
    openedSubMenus.push('archive');
    selectedKeys.push(`${pathname}${search}`);
  }

  if (isPathContains([/^\/users-answer/])) {
    openedSubMenus.push('rating');
    selectedKeys.push(`${pathname}`);
  }

  if (isPathContains([/^\/auswertung/]) && !search && !pathname.includes('compare')) {
    openedSubMenus.push('assessments');
    selectedKeys.push(`${pathname}`);
  }
  if (isPathContains([/^\/auswertung/]) && search && !pathname.includes('compare')) {
    openedSubMenus.push('assessments');
    selectedKeys.push(`${pathname}${search}`);
  }
  if (isPathContains([/^\/auswertung/]) && !search && pathname.includes('compare')) {
    openedSubMenus.push('assessments');
    selectedKeys.push(`${pathname}`);
  }

  if (isPathContains([/^\/auswertung/]) && search && search.includes('archive') && pathname.includes('compare')) {
    openedSubMenus.push('archive');
    selectedKeys.push(`${pathname}${search}`);
  }
  if (isPathContains([/^\/help/])) {
    openedSubMenus.push('/help');
    selectedKeys.push(pathname);
  }

  const openModal = () => {
    setVisible(true);
  };

  const onCancel = useCallback((form) => {
    form.resetFields();
    setVisible(false);
  }, []);

  const renderKeys = (item) => {
    if (isPathContains([/^\/answers/])) {
      return `/answers/${companySlug}/${item.slug}/${categorySlug}/${userSlug}`;
    }
    if (userSlug && isPathContains([/^\/wizard/])) {
      return `/wizard/${item.slug}/${userSlug}`;
    }
    if (!userSlug && isPathContains([/^\/wizard/])) {
      return `/wizard/${item.slug}`;
    }
    if (isPathContains([/^\/user-progress/])) {
      return `/user-progress/${userSlug}`;
    }
    if (isPathContains([/^\/auswertung/]) && !search && !pathname.includes('compare')) {
      return `/auswertung/${item.slug}/${categorySlug}`;
    }
    if (isPathContains([/^\/auswertung/]) && search && !pathname.includes('compare')) {
      return `/auswertung/${item.slug}${search}`;
    }
    if (isPathContains([/^\/auswertung/]) && !search && pathname.includes('compare')) {
      return `/auswertung/${item.slug}/compare`;
    }
    return `/wizard/${item.slug}`;
  };

  const renderMenuItem = (item) => (
    <Menu.Item
      disabled={isDisabled}
      key={renderKeys(item)}
      className={styles.navContainer}
    >
      <NavLink
        to={{
          pathname: `/wizard/${item.slug}`,
          state: { step: -1 },
        }}
        className={classNames({ [styles.noneEvents]: pathname === `/wizard/${item.slug}` })}
      >
        {item.title || 'no title'}
      </NavLink>
    </Menu.Item>
  );

  const renderArchiveKeys = (item) => {
    if (`${pathname}${search}` === `/auswertung/${item.slug}/${categorySlug}?archive`) {
      return `/auswertung/${item.slug}/${categorySlug}?archive`;
    }
    if (`${pathname}${search}` === `/auswertung/${item.slug}${search}`) {
      return `/auswertung/${item.slug}${search}`;
    }
    if (`${pathname}${search}` === `/auswertung/${item.slug}/compare?archive`) {
      return `/auswertung/${item.slug}/compare?archive`;
    }
    if (pathname.includes('archive')) {
      return `/auswertung/${item.slug}?archive${search}`;
    }
    return `/wizard/${item.slug}?archive`;
  };

  const renderArchiveMenuItem = (item) => (
    <Menu.Item key={renderArchiveKeys(item)} className={styles.navContainer}>
      <NavLink
        to={`/wizard/${item.slug}?archive`}
        className={classNames({ [styles.noneEvents]: `${pathname}${search}` === `/wizard/${item.slug}?archive` })}
      >
        <span>{item.title || 'no title'}</span>
      </NavLink>
    </Menu.Item>
  );

  const renderWeightingMenuItem = (item) => (
    <Menu.Item
      key={pathname.includes('wizard')
        ? `/wizard/${item.slug}?weighting`
        : `/users-answer/${item.slug}/${categorySlug}/${userSlug}`}
      className={styles.navContainer}
    >
      <NavLink
        to={`/wizard/${item.slug}?weighting`}
        className={classNames({ [styles.noneEvents]: `${pathname}?weighting` === `/wizard/${item.slug}?weighting` })}
      >
        <span>{item.title || 'no title'}</span>
      </NavLink>
    </Menu.Item>
  );

  const onOpenChange = (items) => {
    const latestOpenKey = items.find((key) => openKeys.indexOf(key) === -1);
    if (items.length) {
      setOpenKeys([latestOpenKey]);
    } else {
      setOpenKeys([]);
    }
  };

  useEffect(() => {
    if (/^\/answers/.test(pathname)) {
      setOpenKeys(['assessments']);
    }
    if (/^\/wizard/.test(pathname) && userSlug) {
      setOpenKeys(['assessments']);
    }

    if (/^\/wizard/.test(pathname) && !userSlug && !search) {
      setOpenKeys(['assessments']);
    }
    if (/^\/wizard/.test(pathname) && !search) {
      setOpenKeys(['assessments']);
    }
    if (/^\/auswertung/.test(pathname) && !search && !pathname.includes('compare')) {
      setOpenKeys(['assessments']);
    }
    if (/^\/auswertung/.test(pathname) && search && !search.includes('archive') && !pathname.includes('compare')) {
      setOpenKeys(['assessments']);
    }
    if (/^\/auswertung/.test(pathname) && !search && pathname.includes('compare')) {
      setOpenKeys(['assessments']);
    }

    if (/^\/wizard/.test(pathname) && search && search.includes('weighting')) {
      openedSubMenus.push('rating');
      setOpenKeys(['rating']);
    }

    if (/^\/wizard/.test(pathname) && search && search.includes('archive')) {
      setOpenKeys(['archive']);
    }
    if (/^\/auswertung/.test(pathname) && search && search.includes('archive') && pathname.includes('compare')) {
      setOpenKeys(['archive']);
    }
    if (/^\/auswertung/.test(pathname) && search && search.includes('archive') && !pathname.includes('compare')) {
      setOpenKeys(['archive']);
    }
    if (pathname.includes('archive')) {
      setOpenKeys(['archive']);
    }
    if (/^\/users-answer/.test(pathname)) {
      setOpenKeys(['rating']);
    }
    if (/^\/help/.test(pathname)) {
      setOpenKeys(['/help']);
    }
    // eslint-disable-next-line
  }, [pathname, search, userSlug]);

  useEffect(() => {
    setDisabled(globalLoading);
  }, [globalLoading]);

  useEffect(() => {
    if (assessments) {
      setActive(assessments.filter((item) => (item.state !== 'WEIGHTING' && item.is_active)));
      setArchive(assessments.filter((item) => !item.is_active));
      setWeightingAssessment(
        assessments.filter((item) => (item.state === 'WEIGHTING' && item.is_active)),
      );
    }
  }, [assessments]);

  return (
    <>
      <NewAssessmentModal isVisible={isVisible} onCancel={onCancel} companies={companies} />
      <Sider
        trigger={(
          <div className={styles.trigger}>
            <CollapseIcon
              onClick={onChange}
            />
          </div>
        )}
        collapsible
        collapsed={collapsed}
        theme='light'
        className={collapsed ? styles.closeSider : styles.sidebar}
        width={312}
        collapsedWidth={150}
      >
        <Logo collapsed={collapsed} />
        {user.role === 'SAM' && (
          <div className={styles.addAssessment}>
            <Button
              data-cy='add-new-assessment'
              onClick={openModal}
              icon={<PlusOutlined />}
            >
              {' '}
              Erstellen
            </Button>
          </div>
        )}
        <Menu
          defaultOpenKeys={openedSubMenus}
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          theme='dark'
          mode='inline'
          className={styles.menu}
        >
          <SubMenu key='assessments' icon={<DashboardOutlined />} title='Assessments' data-cy='assessments'>
            { activeAssessments && activeAssessments.map(renderMenuItem) }
          </SubMenu>
          <SubMenu key='rating' icon={<StarOutlined />} title='Bewertung'>
            { weightingAssessment && weightingAssessment.map(renderWeightingMenuItem) }
          </SubMenu>
          <SubMenu key='archive' icon={<FolderOutlined />} title='Archiv'>
            { archiveAssessments && archiveAssessments.map(renderArchiveMenuItem) }
          </SubMenu>
          <Menu.Item
            style={{ margin: 0 }}
            key='/help'
            className={classNames({ [styles.navHelpSam]: collapsed })}
            icon={<CommentOutlined />}
          >
            <NavLink to='/help'>Hilfe</NavLink>
          </Menu.Item>
        </Menu>
      </Sider>
    </>
  );
};

SideBar.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  companies: PropTypes.array,
};

SideBar.defaultProps = {
  companies: [],
};

export default SideBar;
