import PropTypes from 'prop-types';

import {
  Button, Col, Image, Row, Typography, message,
} from 'antd';

import {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HCMore from 'highcharts/highcharts-more';

import classNames from 'classnames';

import styles from './index.module.scss';

import { categoriesSelector } from '../../../../store/categories/selectors';
import { fetchAssessmentKPI, fetchAssessmentReport, updateAssessment } from '../../../../store/assessments/actions';
import {
  assessmentReportSelector,
  loadingSelector,
  newAssessmentSelector,
} from '../../../../store/assessments/selectors';
import { numberWithCommas, responseHandler } from '../../../../utils/helpers';
import { userSelector } from '../../../../store/auth/selectors';
import { fetchPreAssessments } from '../../../../store/preassessments/actions';

HCMore(Highcharts);

const { Title } = Typography;

const Auswertung = ({ step, config }) => {
  const [kpi, setKPI] = useState({
    highlight: 0,
    weakness: 0,
    discrepancy: 0,
    totalQuestions: 0,
    highlightPercentage: 0,
    weaknessPercentage: 0,
    discrepancyPercentage: 0,
  });

  const [preAssessments, setPreAssessments] = useState([]);
  const [data, setData] = useState([]);
  const [average, setAverage] = useState(0);
  const categories = useSelector(categoriesSelector);

  const loading = useSelector(loadingSelector);
  const assessment = useSelector(newAssessmentSelector);
  const assessmentReport = useSelector(assessmentReportSelector);
  const user = useSelector(userSelector);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { assessmentSlug } = useParams();

  const options = useMemo(() => {
    const opt = {
      chart: {
        renderTo: 'container',
        polar: true,
        height: 500,
      },
      credits: {
        enabled: false,
      },

      title: {
        text: '',
      },
      xAxis: {
        categories: (data || []).map((item) => item.name),
      },

      yAxis: {
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: 0,
        max: 100,
      },
      tooltip: {
        formatter() {
          return `<p><span style="font-size: 10px">${this.point.category}</span><br/>
                  <span style="color: ${this.point.color}">●</span>
                    ${this.point.series.name}:
                   <b>${this.point.y} %</b>
              </p>`;
        },
      },
      series: [
        {
          name: 'Bewertung',
          data: (data || []).map((item) => item.percentage),
          pointPlacement: 'on',
        },
      ],
    };
    if (preAssessments.length) {
      const ids = data.map((item) => item.slug);
      const values = [];
      preAssessments.forEach((item) => {
        if (ids.includes(item.category)) {
          values.push(item.value);
        }
      });
      opt.series.push({
        name: 'Seilbsteinschätzung',
        data: values,
        pointPlacement: 'on',
      });
    }
    return opt;
  }, [data, preAssessments]);

  const goToCompare = () => {
    const state = history.location.state ? { ...history.location.state, step, config } : { step, config };
    if (location.search) {
      history.push(`/auswertung/${assessmentSlug}/compare?archive`, state);
    } else {
      history.push(`/auswertung/${assessmentSlug}/compare`, state);
    }
  };

  const goToCategoryOverview = useCallback((item) => {
    const state = history.location.state ? { ...history.location.state, step, config } : { step, config };
    if (location.search) {
      history.push(`/auswertung/${assessmentSlug}/${item.slug}?archive`, state);
    } else {
      history.push(`/auswertung/${assessmentSlug}/${item.slug}`, state);
    }
  }, [assessmentSlug, config, history, location.search, step]);

  const goToKPIOverview = useCallback((val) => {
    const state = history.location.state ? { ...history.location.state, step, config } : { step, config };
    if (location.search) {
      history.push({ pathname: `/auswertung/${assessmentSlug}?archive`, search: val },
        state);
    } else {
      history.push({ pathname: `/auswertung/${assessmentSlug}`, search: val },
        { ...state, archive: true });
    }
  }, [assessmentSlug, config, history, location.search, step]);

  const renderCategory = useCallback((item, index) => {
    const src = item.image[0] === '/' ? item.image : `/${item.image}`;
    return (
      <Row
        key={index.toString()}
        role='presentation'
        className={styles.categoryImage}
      >
        <Col className={styles.categoryContainer} onClick={() => goToCategoryOverview(item)}>
          <Col><span>{item.name}</span></Col>
          <Col>
            <Image
              preview={false}
              src={src}
              alt='Icon'
              className={styles.img}
            />
            <Col className={classNames(styles.progress, {
              [styles.green]: item.percentage >= 90,
              [styles.red]: item.percentage <= 60,
              [styles.gray]: item.percentage > 61 && item.percentage < 89,
            })}
            >
              {item.percentage}
              {' '}
              %
            </Col>
          </Col>
        </Col>
      </Row>
    );
  }, [goToCategoryOverview]);

  const archiveAssessment = async () => {
    const payload = {
      slug: assessmentSlug,
      is_active: false,
    };
    dispatch(updateAssessment(payload)).then((res) => {
      if (res.error) {
        responseHandler(res);
      } else {
        message.success('Success!');
        setTimeout(() => {
          history.push(`/wizard/${assessmentSlug}?archive`);
        }, 500);
      }
    });
  };

  useEffect(() => {
    const slug = assessmentSlug.includes('archive') ? assessmentSlug.split('?')[0] : assessmentSlug;
    dispatch(fetchAssessmentReport(slug));
    dispatch(fetchAssessmentKPI(slug)).then((res) => {
      const highlight = +res.data?.highlight || 0;
      const weakness = +res.data?.weakness || 0;
      const discrepancy = +res.data?.discrepancy || 0;
      const totalQuestions = +res.data?.question_total || 0;
      const highlightPercentage = (highlight * 100) / totalQuestions;
      const weaknessPercentage = (weakness * 100) / totalQuestions;
      const discrepancyPercentage = (discrepancy * 100) / totalQuestions;
      setKPI({
        highlight,
        weakness,
        discrepancy,
        totalQuestions,
        highlightPercentage: !Number.isFinite(highlightPercentage)
          ? 0
          : numberWithCommas(String(highlightPercentage).length > 3
            ? highlightPercentage.toFixed(1)
            : highlightPercentage),
        weaknessPercentage: !Number.isFinite(highlightPercentage)
          ? 0
          : numberWithCommas(String(weaknessPercentage).length > 3
            ? weaknessPercentage.toFixed(1)
            : weaknessPercentage),
        discrepancyPercentage: !Number.isFinite(discrepancyPercentage)
          ? 0
          : numberWithCommas(String(discrepancyPercentage).length > 3
            ? discrepancyPercentage.toFixed(1)
            : discrepancyPercentage),
      });
    });
  }, [assessmentSlug, dispatch]);

  useEffect(() => {
    const modified = categories.reduce((source, item) => {
      const category = assessmentReport.find((report) => report.category === item.slug);
      if (!category) {
        return source.concat({
          ...item,
          percentage: 0,
        });
      }
      return source.concat({ ...item, percentage: category.percentage });
    }, []);
    const total = modified.map((item) => Number(item.percentage))
      .reduce((a, b) => a + b, 0);
    const value = (total / modified.length).toFixed(2);
    const result = numberWithCommas(value);
    setAverage(result);
    setData(modified);
  }, [assessmentReport, categories]);

  useEffect(() => {
    if (assessment?.has_preassessment) {
      dispatch(fetchPreAssessments(assessmentSlug)).then((res) => {
        if (res.error) {
          responseHandler(res);
        } else {
          setPreAssessments(res?.data || []);
        }
      });
    }
  }, [assessment, assessmentSlug, dispatch]);

  return (
    <div className={styles.container}>
      <Row className={styles.content}>
        <Col span={6} className={styles.categories}>
          {(data || []).map(renderCategory)}
        </Col>
        <Col className={styles.chart}>
          <Title level={3}>{assessment.title || ''}</Title>
          <Title level={3}>{`ICP-Performance: ${average} %`}</Title>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
          />
          <Button
            className={classNames({ [styles.hidden]: !assessment.has_preassessment })}
            onClick={goToCompare}
            type='primary'
          >
            Selbsteinschätzungsvergleich
          </Button>
        </Col>
        <Col span={2}>
          <Col className={styles.explanationGreen} onClick={() => goToKPIOverview('highlight')}>
            <p>Highlights</p>
            <p>
              {kpi.highlight}
              /
              {kpi.totalQuestions}
            </p>
            <p>
              { kpi.highlightPercentage}
              {' '}
              %
            </p>
          </Col>
          <Col className={styles.explanationRed} onClick={() => goToKPIOverview('weakness')}>
            <p>Schwachstellen</p>
            <p>
              {kpi.weakness}
              /
              {kpi.totalQuestions}
            </p>
            <p>
              { kpi.weaknessPercentage}
              {' '}
              %
            </p>
          </Col>
          <Col className={styles.explanationYellow} onClick={() => goToKPIOverview('discrepancy')}>
            <p>Diskrepanzen</p>
            <p>
              {kpi.discrepancy}
              /
              {kpi.totalQuestions}
            </p>
            <p>
              { kpi.discrepancyPercentage}
              {' '}
              %
            </p>
          </Col>
        </Col>
      </Row>
      {(!location.search && user.role !== 'ADMIN') && (
      <Row justify='end' style={{ paddingBottom: '20px' }}>
        <Col>
          <Button
            loading={loading}
            disabled={loading}
            type='primary'
            onClick={archiveAssessment}
          >
            Archive
          </Button>
        </Col>
      </Row>
      )}
    </div>
  );
};

Auswertung.propTypes = {
  step: PropTypes.number.isRequired,
  config: PropTypes.object.isRequired,
};
export default Auswertung;
