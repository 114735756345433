import PropTypes from 'prop-types';

import { useMemo } from 'react';
import { Checkbox, Input, Typography } from 'antd';
import { EditOutlined, MoreOutlined } from '@ant-design/icons';

import classNames from 'classnames';

import { sortableHandle } from 'react-sortable-hoc';

import styles from '../../../../views/Questionnaire/index.module.scss';

const DragHandle = sortableHandle(() => (
  <div className={styles.dragIcon} data-cy='dragAndDrop'>
    <MoreOutlined />
    <MoreOutlined />
  </div>
));

const useColumns = ({
  children, ids, handleChangeWeighting, handleChangeVisibleQuestion, onEditMode, onSkipMode,
  findLastSkipQuestion,
}) => useMemo(() => (children
  ? [
    {
      title: 'Identifier',
      dataIndex: 'identifier',
      key: 'identifier',
      render: (identifier) => (
        <div className={styles.sequentialNumber}>{identifier}</div>
      ),
    },
    {
      title: 'Frage/Aussage',
      dataIndex: 'question',
      key: 'question',
      render: (question, record) => (
        <div
          role='presentation'
        >
          <Typography.Text
            className={ids[record.name]?.includes(record.id) ? styles.skip : undefined}
          >
            {record.title}
          </Typography.Text>
        </div>

      ),
    },
    {
      title: 'Gewichtung',
      dataIndex: 'logic.weight',
      key: 'logic.weight',
      render: (logic, record) => (
        <>
          <Input
            data-cy='weight'
            style={{ maxWidth: '100px' }}
            step='1'
            min='0'
            type='number'
            value={record?.logic.weight}
            onChange={(e) => handleChangeWeighting(e, record)}
            onKeyDown={(e) => (['+', '-', 'e', 'E', '.'].includes(e.key)) && e.preventDefault()}
          />
        </>
      ),
    },
    {
      title: 'Sichtbar',
      dataIndex: 'is_visible',
      key: 'is_visible',
      render: (v, record) => (
        <div className={styles.textCenter}>
          <Checkbox
            checked={record.is_visible}
            onChange={(e) => handleChangeVisibleQuestion(e, record)}
          />
        </div>
      ),
    },
  ]
  : [
    {
      title: 'Identifier',
      dataIndex: 'identifier',
      key: 'identifier',
      render: (identifier) => (
        <div className={styles.dragContainer}>
          <DragHandle />
          <div
            role='presentation'
            className={styles.questionIdentifier}
          >
            {identifier}
          </div>
        </div>
      ),
    },
    {
      title: 'Frage/Aussage',
      dataIndex: 'question',
      key: 'question',
      render: (question, record) => (
        <div
          role='presentation'
          onClick={() => onEditMode(record)}
          className={styles.question}
        >
          <Typography.Text
            className={classNames({ [styles.skip]: ids[record.assessment_type]?.includes(record.slug) })}
          >
            {record.title}
          </Typography.Text>
        </div>

      ),
    },
    {
      title: 'Gewichtung',
      dataIndex: 'logic.weight',
      key: 'logic.weight',
      render: (logic, record) => (
        <>
          <Input
            style={{ maxWidth: '100px' }}
            step='1'
            min='0'
            type='number'
            value={record?.logic.weight}
            onChange={(e) => handleChangeWeighting(e, record)}
            onKeyDown={(e) => (['+', '-', 'e', 'E', '.'].includes(e.key)) && e.preventDefault()}
          />
        </>
      ),
    },
    {
      title: 'Sprungziel',
      dataIndex: 'logic.skip',
      key: 'logic.skip',
      render: (logic, record) => (
        <>
          {(record.type === 'BOOLEAN' && record?.logic.skip) && (
          <div role='presentation' className={styles.textCenter} onClick={() => onSkipMode(record)}>
            <span>
              {findLastSkipQuestion(record) || <EditOutlined />}
            </span>
          </div>
          )}
        </>
      ),
    },
    {
      title: 'Sichtbar',
      dataIndex: 'is_visible',
      key: 'is_visible',
      render: (v, record) => (
        <div className={styles.textCenter}>
          <Checkbox
            checked={record.is_visible}
            onChange={(e) => handleChangeVisibleQuestion(e, record)}
          />
        </div>
      ),
    },
  ]), [findLastSkipQuestion, onEditMode, children,
  handleChangeVisibleQuestion, ids, onSkipMode, handleChangeWeighting]);

useColumns.propTypes = {
  children: PropTypes.any,
  ids: PropTypes.object,
  handleChangeWeighting: PropTypes.func,
  handleChangeVisibleQuestion: PropTypes.func,
  onSkipMode: PropTypes.func,
  onEditMode: PropTypes.func,
  findLastSkipQuestion: PropTypes.func,
};

useColumns.defaultProps = {
  children: null,
  ids: { DEEP_DIVE: [], PERFORMANCE_CHECK: [] },
  handleChangeWeighting: () => {},
  handleChangeVisibleQuestion: () => {},
  onSkipMode: () => {},
  onEditMode: () => {},
  findLastSkipQuestion: () => {},
};
export default useColumns;
