import { useCallback, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Col, Row, message } from 'antd';

import { useDispatch } from 'react-redux';
import ResetPasswordConfirmForm from './components/ResetPasswordConfirmForm';

import { confirmResetPassword } from '../../store/auth/actions';
import { responseHandler } from '../../utils/helpers';

import styles from '../Login/index.module.scss';

const ResetPasswordConfirm = () => {
  const [submitting, setSubmitting] = useState(false);
  const form = useRef(null);
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = useCallback(async (formData) => {
    try {
      setSubmitting(true);
      // eslint-disable-next-line
      await dispatch(confirmResetPassword({ ...formData, uid: params.uid, token: params.token })).then((res) => responseHandler(res, () => {
        message.success('Das Passwort wurde erfolgreich geändert.');
        history.push('/login');
      }));
    } catch (e) {
      setSubmitting(false);
      message.error(e.response?.error || 'General server error');
    }
    setSubmitting(false);
  }, [dispatch, history, params]);

  return (
    <Row justify='center' className={styles.container}>
      <Col xs={18} sm={16} md={12} lg={10} xl={8} xxl={6}>
        <ResetPasswordConfirmForm onSubmit={handleSubmit} submitting={submitting} formRef={form} />
      </Col>
    </Row>
  );
};

export default ResetPasswordConfirm;
