import { useCallback, useRef, useState } from 'react';

import { Col, Row, message } from 'antd';

import { useDispatch } from 'react-redux';

import ResetPasswordForm from './components/ResetPasswordForm';

import { resetPassword } from '../../store/auth/actions';

import styles from '../Login/index.module.scss';
import { responseHandler } from '../../utils/helpers';

const ResetPassword = () => {
  const [submitting, setSubmitting] = useState(false);

  const form = useRef(null);
  const dispatch = useDispatch();

  const handleSubmit = useCallback(async (formData) => {
    try {
      setSubmitting(true);
      await dispatch(resetPassword(formData)).then((res) => responseHandler(res, () => {
        message.success('E-mail mit dem Passwort zurücksetzen Link wurde erfolgreich versendet.');
      }));
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      message.error(e.response?.error || 'General server error');
    }
  }, [dispatch]);

  return (
    <Row justify='center' className={styles.container}>
      <Col xs={18} sm={16} md={12} lg={10} xl={8} xxl={6}>
        <ResetPasswordForm onSubmit={handleSubmit} submitting={submitting} formRef={form} />
      </Col>
    </Row>
  );
};

export default ResetPassword;
