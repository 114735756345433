import PropTypes from 'prop-types';

import {
  Form, Input, message, Modal, Select,
} from 'antd';

import { useEffect } from 'react';

const AddUserForm = ({
  onSubmit, data, onClose, formRef, isSubmitting, isAssessment,
}) => {
  const [form] = Form.useForm();

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      try {
        const result = await form.validateFields();
        if (result) {
          onSubmit();
        }
      } catch (err) {
        message.error('Validation error');
      }
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    formRef.current = form;
  }, [form, formRef]);

  useEffect(() => {
    if (data.isVisible) {
      form.resetFields();
    }
  }, [form, data.isVisible]);

  return (
    <Modal
      centered
      title='Neuen Bewerter hinzufügen'
      visible={data.isVisible}
      onOk={onSubmit}
      onCancel={onClose}
    >
      <Form
        form={form}
      >
        <Form.Item
          name='first_name'
          rules={[{
            required: true,
            message: 'Bitte geben Sie einen Namen ein!',
          }]}
        >
          <Input
            onKeyPress={(e) => handleKeyPress(e)}
            placeholder='Vorname'
            disabled={isSubmitting}
          />
        </Form.Item>

        <Form.Item
          name='last_name'
          rules={[{
            required: true,
            message: 'Bitte geben Sie einen Namen ein!',
          }]}
        >
          <Input
            onKeyPress={(e) => handleKeyPress(e)}
            placeholder='Nachname'
            disabled={isSubmitting}
          />
        </Form.Item>

        <Form.Item
          name='email'
          rules={
            [
              {
                required: true,
                message: 'Bitte geben Sie eine E-Mail-Adresse ein!',
              },
              {
                type: 'email',
                message: 'Die Eingabe ist eine gültige E-Mail!',
              },
            ]
          }
        >
          <Input
            onKeyPress={(e) => handleKeyPress(e)}
            placeholder='E-Mail'
            disabled={isSubmitting}
          />
        </Form.Item>
        {!isAssessment && (
        <Form.Item
          name='role'
          rules={[{
            required: true,
            message: 'Bitte geben Sie user role ein!',
          }]}
        >
          <Select
            options={[{ value: 'SAM', label: 'SAM' },
              { value: 'ADMIN', label: 'Administrator' }, { value: 'CLIENT', label: 'User' }]}
            placeholder='Role'
            disabled={isSubmitting}
          />
        </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

AddUserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object,
  formRef: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
  isAssessment: PropTypes.bool.isRequired,
};

AddUserForm.defaultProps = {
  data: { isVisible: false },
  formRef: null,
};

export default AddUserForm;
