import PropTypes from 'prop-types';

import { Tabs, Alert } from 'antd';

import { useMemo } from 'react';
import { COMPONENT_TYPES } from '../../../../utils/constants';
import Categories from './Categories';
import Zeitrahmen from './Zeitrahmen';

import styles from '../index.module.scss';

const { TabPane } = Tabs;

const COMPONENTS = {
  [COMPONENT_TYPES.CATEGORIES]: Categories,
  [COMPONENT_TYPES.CARD]: Zeitrahmen,
};

const Vorbereitung = ({
  tabs, formRef, categoryRef, company,
}) => {
  const source = useMemo(() => tabs.map((item, index) => {
    const Component = COMPONENTS[item.type];
    const props = {
      ...item, formRef, categoryRef, company,
    };
    return Component ? <TabPane key={index.toString()} tab={item.text}><Component {...props} /></TabPane> : null;
  }), [categoryRef, tabs, formRef, company]);

  return (
    <>
      <Alert
        message='Info'
        description='Zuerst weisen Sie den einzelnen Kategorien bestehenden oder neue Bewerter zu.
        Im zweiten Schritt wird der Zeitrahmen festgelegt...
        Beim Starten des Assessments wird den Teilnehmer eine Einladung per E-mail geschickt.'
        type='info'
        closable
        showIcon
      />
      <div className={styles.vorbereitungContainer}>
        <Tabs tabPosition='left'>
          {source}
        </Tabs>
      </div>
    </>
  );
};

Vorbereitung.propTypes = {
  tabs: PropTypes.array.isRequired,
  formRef: PropTypes.object,
  categoryRef: PropTypes.object,
  company: PropTypes.string,
};

Vorbereitung.defaultProps = {
  formRef: { current: null },
  categoryRef: { current: null },
  company: '',
};
export default Vorbereitung;
