import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import {
  Form, Button, InputNumber, Space, Card,
} from 'antd';

import styles from '../index.module.scss';

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const TwoFaForm = ({
  onSubmit, submitting, formRef,
}) => {
  const [form] = Form.useForm();

  const handleValuesChange = useCallback((update) => {
    const [name] = Object.keys(update);
    if (!update[name]) return;
    const idx = Number(name.split('num')[1]);
    if (idx < 5) {
      const field = form.getFieldInstance([`num${idx + 1}`]);
      field.focus();
    }
  }, [form]);

  const handlePaste = useCallback((e) => {
    const code = (e.clipboardData || window.clipboardData).getData('text').replace(/\D/g, '');
    if (code.length > 1) {
      form.setFields(code.split('').map((item, idx) => ({ name: [`num${idx}`], value: item })));
      if (code.length <= 6) {
        const field = form.getFieldInstance([`num${code.length - 1}`]);
        if (field) field.focus();
      }
    }
  }, [form]);

  const handleKeyDown = useCallback((e, idx) => {
    if (e.keyCode === 8 && idx > 0) {
      setTimeout(() => {
        const field = form.getFieldInstance([`num${idx - 1}`]);
        if (field) field.focus();
      }, 10);
    }
  }, [form]);

  const renderNumber = useCallback((i, idx) => (
    <Form.Item name={`num${idx}`} rules={[{ required: true, message: '' }]}>
      <InputNumber
        autoFocus={idx === 0}
        min={0}
        max={9}
        maxLength={1}
        size='large'
        style={{ width: '40px' }}
        className={styles.inputNumber}
        onPaste={handlePaste}
        onKeyDown={(e) => handleKeyDown(e, idx)}
      />
    </Form.Item>
  ), [handlePaste, handleKeyDown]);

  const fields = useMemo(() => (new Array(6)).fill(0).map(renderNumber), [renderNumber]);

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    formRef.current = form;
  }, [formRef, form]);

  return (
    <Form
      onFinish={onSubmit}
      className={styles.align}
      form={form}
      onValuesChange={handleValuesChange}
      {...formItemLayout}
    >
      <Card
        title='Geben Sie den Code aus der Authenticator-Anwendung ein'
        level={4}
        className={styles.titleAlign}
      >

        <Space size={10}>
          { fields }
        </Space>

        <Form.Item style={{ textAlign: 'center' }}>
          <Button type='primary' htmlType='submit' className={styles.button} loading={submitting}>
            Verifizieren
          </Button>
        </Form.Item>
      </Card>

    </Form>
  );
};

TwoFaForm.propTypes = {
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  formRef: PropTypes.object,
};

TwoFaForm.defaultProps = {
  onSubmit: () => {},
  submitting: false,
  formRef: { current: null },
};

export default TwoFaForm;
