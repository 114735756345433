import {
  DELETE_INVITED_USER, FETCH_INVITES, INVITE_USER, UPDATE_INVITED_USER,
} from './types';

export const inviteUser = (data) => ({
  type: INVITE_USER,
  request: {
    url: 'invites/',
    method: 'POST',
    data,
  },
});

export const fetchInvites = () => ({
  type: FETCH_INVITES,
  request: {
    url: 'invites/',
    method: 'GET',
  },
});

export const updateInvitedUser = ({ slug, ...rest }) => ({
  type: UPDATE_INVITED_USER,
  request: {
    url: `invites/${slug}/`,
    method: 'PATCH',
    data: rest,
  },
});

export const deleteInvitedUser = (slug) => ({
  type: DELETE_INVITED_USER,
  request: {
    url: `invites/${slug}/`,
    method: 'DELETE',
  },
});
