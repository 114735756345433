import { useCallback, useEffect, useState } from 'react';
import {
  Tabs, PageHeader, message, Spin,
} from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Questionnaire from './components/Questionnaire';
import IcpAssessment from './components/IcpAssessment';
import Contact from './components/Contact';

import styles from './index.module.scss';
import { fetchAssessmentCustomerConfig } from '../../store/assessments/actions';
import { loadingSelector } from '../../store/assessments/selectors';
import { modifyQuestion, reverseTypes } from '../../utils/helpers';
import companySelector from '../../store/company/selectors';

const { TabPane } = Tabs;

const ProjectOverview = () => {
  const [templates, setTemplates] = useState([]);
  const [company, setCompany] = useState(null);
  const [activeTab, setActiveTab] = useState('fragenkatalog');

  const dispatch = useDispatch();
  const { customerSlug } = useParams();
  const loading = useSelector(loadingSelector);
  const companies = useSelector(companySelector);

  const handleChangeCustomerTemplates = useCallback((val) => {
    const result = reverseTypes(val);
    setTemplates(result);
  },

  []);

  const handleChangeTemplates = useCallback(async (val) => {
    const result = await modifyQuestion(templates, val);
    setTemplates(result);
  }, [templates]);

  useEffect(() => {
    setCompany(companies.find((el) => el.slug === customerSlug));
  }, [companies, customerSlug]);

  useEffect(() => {
    dispatch(fetchAssessmentCustomerConfig(customerSlug)).then((res) => {
      if (res.error) {
        return message.error('General server error');
      }
      const result = reverseTypes(res.data);
      return setTemplates(result);
    });
  }, [customerSlug, dispatch]);

  return (
    <>
      <PageHeader
        title={company?.name || ''}
        className={styles.pageHeader}
      />
      <Spin spinning={loading}>
        <Tabs className={styles.tabs} onTabClick={(e) => setActiveTab(e)}>
          <TabPane
            className={styles.tabPane}
            tab='Fragenkatalog'
            key='fragenkatalog'
          >
            <Questionnaire
              data={templates}
              onChangeState={handleChangeTemplates}
              updateTemplates={handleChangeCustomerTemplates}
            />
          </TabPane>
          <TabPane
            className={styles.tabPane}
            tab='ICP-Assessments'
            key='icp-assessments'
          >
            <IcpAssessment activeTab={activeTab} />
          </TabPane>
          <TabPane
            className={styles.tabPane}
            tab='Kontakt'
            key='contact'
          >
            <Contact company={company} />
          </TabPane>
        </Tabs>
      </Spin>
    </>
  );
};

export default ProjectOverview;
