import PropTypes from 'prop-types';

import { useEffect, useState } from 'react';

import {
  Col, Image, Progress, Row, Typography, Alert,
} from 'antd';

import { UserOutlined } from '@ant-design/icons';

import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

import {
  assessmentProgressSamAdminSelector, globalLoadingSelector,
  newAssessmentSelector,
} from '../../../../store/assessments/selectors';
import { userSelector } from '../../../../store/auth/selectors';

import RatingExplanation from './RatingExplanation/RatingExplanation';

import styles from '../../../../components/common/StatusAssessment/index.module.scss';

const { Title } = Typography;

const USERS = {
  SAM: 'SAM',
  ADMIN: 'AWB',
};

const Rating = ({ onChange }) => {
  const [isShowExplanation, setShow] = useState(false);
  const [progress, setProgress] = useState(null);
  const { assessmentSlug } = useParams();
  const history = useHistory();

  const loading = useSelector(globalLoadingSelector);
  const statusAssessment = useSelector(assessmentProgressSamAdminSelector);
  const assessment = useSelector(newAssessmentSelector);
  const user = useSelector(userSelector);

  const goToReview = (item) => {
    history.push(`/users-answer/${assessmentSlug}/${item.category.slug}/${user.slug}`);
    setShow(false);
  };

  const infoText = () => (
    <>
      <p>
        Die Bewertung des Assessments wird aktuell von
        {' '}
        {USERS[assessment.evaluator.role]}
        {' '}
        durchgeführt.
      </p>
      <p>Sobald die Bewertung fertig ist, können Sie hier die Auswertung einsehen.</p>
    </>
  );

  const renderCategories = (item, idx) => (
    <Row
      onClick={() => goToReview(item)}
      align='center'
      className={styles.category}
      key={idx.toString()}
    >
      <Col>
        <Image preview={false} src={item.category.image} alt='icon category' width={20} />
        <span>{item.category.name}</span>
      </Col>
      <Col>
        <Progress
          percent={Number(((item?.answered_questions * 100) / item?.total_questions).toFixed(2)) || 0}
          steps={item?.total_questions || 0}
          size='small'
          strokeColor='#1890ff'
        />
        <span className={styles.question}>
          {item?.answered_questions || 0}
          /
          {item?.total_questions || 0}
        </span>

      </Col>
    </Row>
  );

  const renderTitle = () => {
    const name = user?.first_name;
    const lastName = user?.last_name;
    return (name && lastName) ? `${name} ${lastName}` : user?.email;
  };

  useEffect(() => {
    (async () => {
      if (statusAssessment.length) {
        const totalQuestions = await statusAssessment
          .map((item) => item?.total_questions)
          .reduce((a, b) => a + b, 0);
        const totalAnswers = await statusAssessment
          .map((item) => item?.answered_questions).reduce((a, b) => a + b, 0);
        const result = Number(((totalAnswers * 100) / totalQuestions).toFixed(2));
        setProgress(result);
        if (result === 0) {
          setShow(true);
          onChange(false);
        } else {
          onChange(true);
          setShow(false);
        }
      }
    })();
  }, [onChange, statusAssessment]);

  const renderContent = () => {
    const alertContent = (
      <>
        <Row justify='center'><Title level={3} style={{ textAlign: 'center' }}>{assessment?.title}</Title></Row>
        <Alert
          message='Information'
          description={infoText()}
          type='info'
          showIcon
        />
      </>
    );
    const defaultContent = (
      <>
        {progress === 0 && isShowExplanation ? (
          <RatingExplanation handleChangeVisibleStatusButton={onChange} onChange={setShow} title={assessment?.title} />
        )
          : (
            <>
              <Row justify='center'><Title level={3} style={{ textAlign: 'center' }}>{assessment?.title}</Title></Row>
              <Row
                justify='space-around'
                className={classNames(styles.adminSamDetails, styles.userAssessmentDetails)}
              >
                <Col span={4}>
                  <UserOutlined style={{ fontSize: '80px', color: '#ddd' }} />
                  <Title level={3} style={{ textAlign: 'center' }}>{renderTitle()}</Title>
                  <Progress type='circle' percent={progress || 0} style={{ marginTop: '20px' }} />
                </Col>
                <Col span={18}>
                  <Col>
                    {(statusAssessment || []).map(renderCategories)}
                  </Col>
                  <Col />
                </Col>
              </Row>
            </>
          )}
      </>
    );
    if (user.role === 'ADMIN' && assessment?.evaluator?.role === 'ADMIN' && !!statusAssessment.length) {
      return defaultContent;
    }
    if (user.role === 'ADMIN' && assessment?.evaluator?.role === 'SAM' && !!statusAssessment.length) {
      return alertContent;
    }
    if (user.role === 'SAM' && assessment?.evaluator?.role === 'SAM' && !!statusAssessment.length) {
      return defaultContent;
    }
    if (user.role === 'SAM' && assessment?.evaluator?.role === 'ADMIN' && !!statusAssessment.length) {
      return alertContent;
    }
    return defaultContent;
  };

  return (
    <>
      <div className={styles.progressContainerSamAdmin}>
        {!loading && !!statusAssessment.length && (
          <>
            {renderContent()}
          </>
        )}
      </div>
    </>
  );
};

Rating.propTypes = {
  onChange: PropTypes.func.isRequired,
};
export default Rating;
