import PropTypes from 'prop-types';

import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import { useDispatch } from 'react-redux';

import {
  Button, Card, Form, Col, Row, message,
} from 'antd';

import styles from '../index.module.scss';
import QuestionName from './FormComponents/QuestionName';
import Formulation from './FormComponents/Formulation';
import TypeOfAnswer from './FormComponents/TypeOfAnswer';
import AnswerOptions from './FormComponents/AnswerOptions';
import { addNewQuestion } from '../../../store/assessments/actions';
import { responseHandler } from '../../../utils/helpers';

const COMPONENTS = {
  0: QuestionName,
  1: Formulation,
  2: TypeOfAnswer,
  3: AnswerOptions,
};

const AddNewQuestion = ({ payload, handleChangeVisible }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [buttonData, setData] = useState({ text: 'Weiter', action: 'next' });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const ref = useRef(payload);

  useEffect(() => {
    if (currentStep === 2) {
      if (ref.current.type !== 'MULTIPLE_CHOICE') {
        setData({ text: 'Speichern', action: 'submit' });
      } else {
        setData({ text: 'Weiter', action: 'next' });
      }
    }
    if (currentStep === 3) {
      setData({ text: 'Speichern', action: 'submit' });
    }
    if (currentStep !== 2 && currentStep !== 3) {
      setData({ text: 'Weiter', action: 'next' });
    }
  }, [currentStep]);

  const handleChangeAnswerType = useCallback((key) => {
    if (key === 'MULTIPLE_CHOICE') {
      setData({ text: 'Weiter', action: 'next' });
    } else {
      setData({ text: 'Speichern', action: 'submit' });
    }
    ref.current = { ...ref.current, type: key };
  }, []);

  const handleChangeAnswerOptions = useCallback(((options) => {
    ref.current = { ...ref.current, ...options };
  }), []);

  const onBack = useCallback(() => {
    if (currentStep === 0) handleChangeVisible();
    setCurrentStep(currentStep - 1);
  }, [handleChangeVisible, currentStep]);

  const onNext = useCallback(async () => {
    try {
      const result = await form.validateFields();
      ref.current = { ...ref.current, ...result };
      setCurrentStep(currentStep + 1);
    } catch (e) {
      message.error('Validation error');
    }
  }, [ref, form, currentStep]);

  const onSubmit = () => {
    let error = false;
    let o = { ...ref.current };
    if (o.type === 'BOOLEAN') {
      o = { ...o, logic: { ...o.logic, skip: [], weight: 1 } };
    }
    if (o.type !== 'BOOLEAN') {
      o = { ...o, logic: { ...o.logic, weight: 1 } };
    }
    if (o.type === 'MULTIPLE_CHOICE' && o.answer_options.length < 4) {
      error = true;
      message.error('Sie müssen alle vier Optionen ausfüllen.');
    }
    if (o.answer_options.length === 4 && o.type === 'MULTIPLE_CHOICE') {
      // eslint-disable-next-line camelcase
      const { answer_options, ...rest } = o;
      o = { ...rest, answer_options: [], logic: { ...rest.logic, answer_options } };
    }
    if (!error) {
      dispatch(addNewQuestion(o)).then((res) => responseHandler(res, () => {
        setCurrentStep(0);
        handleChangeVisible();
        return message.success('Success! You added new question');
      }));
    }
  };

  const actions = {
    next: onNext,
    submit: onSubmit,
  };
  const title = useMemo(() => {
    const titles = {
      0: 'Neue Frage/Aussage',
      1: `${ref.current.title} - Formulierung`,
      2: `${ref.current.title} - Auswahl des Antworttyps`,
      3: `${ref.current.title} - Antwortmöglichkeiten`,
    };
    return titles[currentStep];
  }, [currentStep]);

  const Component = COMPONENTS[currentStep];

  const onFormChanged = useCallback((val) => {
    const has = Object.prototype.hasOwnProperty;
    if (has.call(val, 'aktivieren')) {
      ref.current = { ...ref.current, ...val };
    }
  }, []);

  return (
    <div className={styles.cardContainer}>
      <Card title={title}>
        <Form form={form} layout='vertical' onValuesChange={onFormChanged} initialValues={{ formulationOptional: '' }}>
          {Component ? (
            <Component
              dataRef={ref}
              onChange={currentStep === 3 ? handleChangeAnswerOptions : handleChangeAnswerType}
            />
          ) : null}
        </Form>
        <Row justify='center'>
          <Col span={10} className={styles.actionButtonsContainer}>
            <Button type='primary' onClick={onBack}>Zurück</Button>
            <Button
              type='primary'
              onClick={actions[buttonData.action]}
            >
              {buttonData.text}
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

AddNewQuestion.propTypes = {
  payload: PropTypes.object,
  handleChangeVisible: PropTypes.func.isRequired,
};
AddNewQuestion.defaultProps = {
  payload: {},
};

export default AddNewQuestion;
