import { error, success } from '@redux-requests/core';

import {
  FETCH_INVITES,
  INVITE_USER,

} from './types';

const initialState = {
  loading: false,
  error: false,
  invites: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case (INVITE_USER):
      return { ...state, loading: true };
    case (FETCH_INVITES):
      return { ...state, loading: true };
    case success(FETCH_INVITES):
      return { ...state, loading: false, invites: action.response.data };
    case success(INVITE_USER):
      return { ...state, loading: false };
    case error(FETCH_INVITES):
      return { ...state, loading: false };
    case error(INVITE_USER):
      return { ...state, loading: false };
    default: return state;
  }
};
export default reducer;
