import { error, success } from '@redux-requests/core';

import {
  CONFIRM_2FA, DELETE_2FA, ENABLE_2FA, GET_USER, GET_USERS, LOG_OUT, LOGIN_USER, SUBMIT_2FA, UPDATE_USER,
} from './types';

const initialState = {
  loading: false,
  error: false,
  user: {
    role: '',
    email: '',
    first_name: '',
    last_name: '',
    is_2fa_enabled: false,
    slug: '',
  },
  users: [
    {
      role: '',
      email: '',
      first_name: '',
      last_name: '',
      is_2fa_enabled: false,
    },
  ],
  two_fa_link: '',
  isTwoFaVerified: false,
  isAuthorized: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case (LOGIN_USER):
      return { ...state, loading: true };
    case success(LOGIN_USER): {
      return {
        ...state, user: action.response.data, isAuthorized: true, isTwoFaVerified: false, loading: false,
      };
    }
    case error(LOGIN_USER): {
      return {
        ...state, loading: false, isAuthorized: false, isTwoFaVerified: false, error: true,
      };
    }
    case (SUBMIT_2FA):
      return { ...state, loading: true };
    case success(SUBMIT_2FA):
      return {
        ...state, user: action.response.data, isAuthorized: true, isTwoFaVerified: true, loading: false,
      };
    case error(SUBMIT_2FA):
      return {
        ...state, loading: false, isTwoFaVerified: false, error: true,
      };
    case (LOG_OUT):
      return { ...state, loading: true };
    case success(LOG_OUT):
      return {
        ...state,
        ...initialState,
      };
    case error(LOG_OUT):
      return {
        ...state, loading: false, error: true,
      };
    case (GET_USER):
      return { ...state, loading: true };
    case success(GET_USER):
      return {
        ...state, user: action.response.data, loading: false, isAuthorized: true,
      };
    case error(GET_USER):
      return { ...state, loading: false, error: true };
    case (UPDATE_USER):
      return { ...state, loading: true };
    case success(UPDATE_USER):
      return {
        ...state, user: action.response.data, loading: false,
      };
    case error(UPDATE_USER):
      return { ...state, loading: false, error: true };
    case (ENABLE_2FA):
      return { ...state, loading: true };
    case success(ENABLE_2FA):
      return {
        ...state,
        two_fa_link: action.response.data.link,
        user: { ...state.user, is_2fa_enabled: false },
        loading: false,
      };
    case error(ENABLE_2FA):
      return { ...state, loading: false, error: true };
    case (CONFIRM_2FA):
      return { ...state, loading: true };
    case success(CONFIRM_2FA):
      return {
        ...state, user: { ...state.user, is_2fa_enabled: true }, loading: false,
      };
    case error(CONFIRM_2FA):
      return { ...state, loading: false, error: true };
    case (DELETE_2FA):
      return { ...state, loading: true };
    case success(DELETE_2FA):
      return {
        ...state, user: { ...state.user, is_2fa_enabled: false }, loading: false,
      };
    case error(DELETE_2FA):
      return { ...state, loading: false, error: true };
    case (GET_USERS):
      return { ...state, loading: true };
    case success(GET_USERS):
      return {
        ...state, users: action.response.data, loading: false,
      };
    case error(GET_USERS):
      return {
        ...state, loading: false, error: true,
      };
    default: return state;
  }
};
export default reducer;
