import PropTypes from 'prop-types';

import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, message } from 'antd';
import { useHistory } from 'react-router-dom';

import { arrayMoveImmutable } from 'array-move';
import { templatesSelector } from '../../../../store/assessments/selectors';
import {
  deleteQuestion,
  modifyTemplates, updateAllTemplates,
  updateQuestion,
  updateTemplateQuestion,
} from '../../../../store/assessments/actions';
import { ANSWER_TYPES } from '../../../../utils/constants';

import { findMatch, responseHandler, updateTemplatesPayload } from '../../../../utils/helpers';
import useActions from './useActions';

const useController = ({ types, onChangeState, category }) => {
  const [version, setVersion] = useState();
  const [visible, setVisible] = useState(false);
  const [payload, setPayload] = useState({});
  const [updated, setUpdated] = useState(false);
  const [modal, setModal] = useState({ type: '', visible: false, data: { currentQuestion: { question: '' } } });
  const [state, setState] = useState({ DEEP_DIVE: [], PERFORMANCE_CHECK: [] });
  const [ids, setIds] = useState({});

  const formRef = useRef(null);
  const logicRef = useRef(null);
  const skipFormRef = useRef(null);
  const templates = useSelector(templatesSelector);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    updateAssessmentVersionsAndTemplates,
    updateOrCreateAssessmentVersionsWithFile,
    handleChangeWeighting,
  } = useActions({ onChangeState });

  const onConfirmMode = useCallback(() => {
    setModal({ type: 'confirm', visible: true, data: { currentQuestion: { question: '' } } });
  }, []);

  const handleChangeVisible = useCallback(() => {
    setVisible(false);
  }, []);

  const addNewQ = useCallback((type) => {
    const obj = types.find((item) => item.type === type);
    setPayload({
      ...payload, assessment_type: type, assessment: obj.slug, order: state[type].length + 1,
    });
    setVisible(true);
  }, [types, state, payload]);

  const onSkipMode = useCallback((record) => {
    if (ANSWER_TYPES.includes(record.type)) {
      const result = [];
      const start = (state[record.assessment_type].findIndex((item) => item.slug === record.slug) + 1);
      const items = state[record.assessment_type].slice(start);
      for (let index = 0; index < items.length; index += 1) {
        if (
          items[index].type === 'BOOLEAN'
          && items[index + 1]?.type !== 'BOOLEAN'
        ) break;

        result.push(items[index]);
      }

      setModal({
        type: 'skip',
        visible: true,
        data: {
          currentQuestion: {
            slug: record.slug,
            logic: record.logic,
            question: record.question,
            title: record.title,
            assessment_type: record.assessment_type,
          },
          lists: result,
        },
      });
    }
  }, [state]);
  const onEditMode = useCallback((record) => {
    setModal((s) => ({
      ...s,
      type: 'edit',
      data: { ...record, category },
      visible: true,
    }));
    logicRef.current = {
      data: { ...record, category },
    };
  }, [category]);

  // eslint-disable-next-line consistent-return
  const handleChangeVisibleQuestion = useCallback(async (e, record) => {
    function generate() {
      if (record.logic?.skip?.length && !e.target.checked) {
        return { ...record.logic, skip: [], defaultSkip: record.logic.skip };
      }
      if (!record.logic?.skip?.length && (record.logic.defaultSkip || []).length && e.target.checked) {
        return { ...record.logic, skip: record.logic.defaultSkip, defaultSkip: record.logic.defaultSkip };
      }
      return { ...record.logic };
    }

    dispatch(updateQuestion({
      slug: record.slug,
      is_visible: e.target.checked,
      logic: generate(),
    })).then((res) => {
      if (res.error) {
        return message.error('Genereller Fehler!');
      }
      if (onChangeState) {
        onChangeState({ ...res.data, assessment_type: record.assessment_type });
      }
      setState((s) => ({
        ...s,
        [record.assessment_type]: state[record.assessment_type].map((item) => {
          if (item.index === record.index) {
            return {
              ...item,
              is_visible: !item.is_visible,
              defaultLogic: item.logic,
              logic: generate(),
            };
          }
          return item;
        }),
      }));
      dispatch(updateTemplateQuestion({ ...res.data, assessment_type: record.assessment_type }));
      return message.success('Änderungen wurden erfolgreich gespeichert');
    });
  }, [dispatch, onChangeState, state]);

  const onCancelModal = useCallback(() => {
    setModal({ visible: false, type: '', data: { currentQuestion: { question: '' } } });
  }, []);

  const updateState = useCallback((updateData, field) => {
    dispatch(updateTemplateQuestion(updateData));
    if (field === 'generalModal') {
      setUpdated(false);
      logicRef.current = null;
      setModal({ visible: false, type: '', data: { currentQuestion: { question: '' } } });
    }
    if (field === 'skipModal') {
      setModal({ type: '', visible: false, data: { currentQuestion: { question: '' } } });
    }
    return message.success('Änderungen wurden erfolgreich gespeichert');
  }, [dispatch]);

  const onFinish = useCallback((data) => {
    const keys = Object.keys(data);
    const skip = [];
    keys.forEach((key) => {
      if (data[key]) {
        skip.push(key);
      }
    });
    const arr = [];
    state[modal.data.currentQuestion.assessment_type].forEach((item) => {
      skip.forEach((slug) => {
        if (item.slug === slug) {
          arr.push(item.slug);
        }
      });
    });
    const allIds = state[modal.data.currentQuestion.assessment_type].map(({ slug }) => slug);
    const indexes = findMatch(arr, allIds);
    const max = Math.max(...indexes);
    let search = {};
    state[modal.data.currentQuestion.assessment_type].forEach((item, index) => {
      if ((max + 1) === index) {
        search = item;
      }
    });
    if (Object.keys(search).length) {
      dispatch(updateQuestion({
        slug: search.slug,
        is_visible: true,
      })).then((res) => {
        dispatch(updateTemplateQuestion({
          ...res.data,
          assessment_type: modal.data.currentQuestion.assessment_type,
        }));
      });
    }
    const v = !modal.data.currentQuestion.is_visible ? true : modal.data.currentQuestion.is_visible;
    dispatch(updateQuestion({
      slug: modal.data.currentQuestion.slug,
      is_visible: v,
      logic: { ...modal.data.currentQuestion.logic, skip },
    })).then((res) => responseHandler(res,
      () => updateState({ ...res.data, assessment_type: modal.data.currentQuestion.assessment_type },
        'skipModal')));
  }, [state, updateState, modal, dispatch]);

  // eslint-disable-next-line consistent-return
  const saveEditedQuestion = useCallback(async () => {
    try {
      const {
        hint, question, identifier, title,
      } = await formRef.current.validateFields();

      const data = {
        slug: modal.data.slug,
        hint: hint || '',
        question,
        identifier,
        title,
        logic: logicRef.current.data.logic,
      };

      dispatch(updateQuestion(data)).then((res) => responseHandler(res,
        () => updateState({ ...res.data, assessment_type: modal.data.assessment_type },
          'generalModal')));
    } catch (e) {
      setUpdated(false);
      setModal({ visible: false, type: '', data: { currentQuestion: { question: '' } } });
      return message.error('Validation error');
    }
  }, [updateState, dispatch, modal]);

  const onChange = useCallback((e, a) => {
    const options = logicRef.current.data.logic.answer_options.map((item) => {
      if (item.idx === a.idx) {
        return {
          ...item,
          option: { letter: a.option.letter, value: e.target.value },
        };
      }
      return item;
    });
    logicRef.current = {
      data: { ...logicRef.current.data, logic: { ...logicRef.current.data.logic, answer_options: options } },
    };
  }, []);

  const delQuestion = useCallback(() => {
    let needsUpdate = {};
    state[modal.data.assessment_type].forEach((item) => {
      if (item.logic?.skip?.length) {
        if (item.logic.skip.includes(modal.data.slug)) {
          const skip = item.logic.skip.filter((slug) => slug !== modal.data.slug);
          needsUpdate = { ...item, logic: { ...item.logic, skip } };
        }
      }
      return item;
    });
    dispatch(deleteQuestion({ slug: modal.data.slug })).then(async (res) => {
      if (res.error) {
        return message.error('Genereller Fehler!');
      }
      message.success('Frage gelöscht');
      if (Object.keys(needsUpdate).length) {
        dispatch(updateQuestion({ slug: needsUpdate.slug, logic: needsUpdate.logic })).then((response) => {
          if (response.error) {
            responseHandler(response);
          }
          updateState({
            category: response.data.category,
            hint: response.data.hint,
            title: response.data.title,
            assessment_type: needsUpdate.assessment_type,
            slug: response.data.slug,
            question: response.data.question,
            is_visible: response.data.is_visible,
            logic: response.data.logic,
          }, 'generalModal');
        });
      }
      dispatch(modifyTemplates({
        assessment: modal.data.assessment,
        category: modal.data.category,
        slug: modal.data.slug,
      }));
      return setModal({ visible: false, type: '', data: { currentQuestion: { question: '' } } });
    });
  }, [updateState, dispatch, state, modal]);

  const findLastSkipQuestion = useCallback((question) => {
    const allIds = state[question.assessment_type].map(({ slug }) => (slug));
    const indexes = findMatch(question.logic.skip, allIds);
    if (indexes.length) {
      const lastQuestion = state[question.assessment_type].find((i) => i.order === Math.max(...indexes) + 2);
      return lastQuestion?.identifier || 'Ende';
    }
    return null;
  }, [state]);

  // eslint-disable-next-line consistent-return
  const onSort = useCallback(async (type, oldIndex, newIndex) => {
    if (ids[type].length) {
      // eslint-disable-next-line max-len
      return message.error('Es müssen alle Sprünge in der Kategorie entfernt werden, bevor Fragen verschoben werden können.');
    }
    if (oldIndex !== newIndex && !ids[type].length) {
      const modifiedQuestions = arrayMoveImmutable([].concat(state[type]), oldIndex, newIndex)
        .map((item, index) => ({
          ...item,
          is_visible: true,
          index: String(index + 1),
          order: index + 1,
        }));
      const pld = updateTemplatesPayload(templates, modifiedQuestions, type, category);
      const original = state[type];
      setState((s) => ({ ...s, [type]: modifiedQuestions }));
      dispatch(updateAllTemplates(pld));
      const series = async () => {
        let results;
        for (let i = 0; i < modifiedQuestions.length; i += 1) {
          results = dispatch(updateQuestion(
            { slug: modifiedQuestions[i].slug, order: modifiedQuestions[i].order },
          ));
        }
        return results;
      };
      const result = await series();
      if (result.error) {
        const originalTemplate = updateTemplatesPayload(templates, original, type, category);
        responseHandler(result);
        setState((s) => ({ ...s, [type]: original }));
        dispatch(updateAllTemplates(originalTemplate));
      } else {
        return message.success('Änderungen wurden erfolgreich gespeichert');
      }
    }
  }, [category, dispatch, ids, state, templates]);

  const changeVersionTemplate = useCallback(async () => {
    const o = {};
    const formData = form.getFieldsValue();

    Object.keys(version).forEach((key) => {
      if (formData?.file?.fileList?.length) {
        Object.assign(o, {
          [key]: (Number(version[key]) + 0.1).toFixed(1),
          file: formData.file.fileList[0].originFileObj,
        });
      } else {
        Object.assign(o, { [key]: (Number(version[key]) + 0.1).toFixed(1) });
      }
    });
    const keys = Object.keys(o).sort();

    const slugs = { deepDiveSlug: keys[0], perfomanceCheckSlug: keys[1] };
    const versions = { deepDiveVers: o[keys[0]], perfomanceCheckVers: o[keys[1]] };

    const { error } = formData?.file?.fileList?.length
      ? await updateOrCreateAssessmentVersionsWithFile(types, keys, o)
      : await updateAssessmentVersionsAndTemplates(slugs, versions);

    if (!error) {
      setVersion(o);
      setModal({ type: '', visible: false, data: { currentQuestion: { question: '' } } });
      form.resetFields();
      message.success('Fragebogenvorlage erfolgreich upgedated');
    }
  }, [form, types, updateAssessmentVersionsAndTemplates, updateOrCreateAssessmentVersionsWithFile, version]);

  useEffect(() => {
    if (category && types.length) {
      types.forEach((t) => {
        setVersion((s) => ({ ...s, [t.slug]: t.version }));
      });
      setPayload({
        has_file_upload: true,
        type: 'BOOLEAN',
        answer_options: [],
        hint: '',
        category,
        order: 0,
        is_visible: true,
        logic: {},
        identifier: '',
      });
      types.forEach((item) => {
        const sorted = item.questions.sort((a, b) => (a.order > b.order ? 1 : -1));
        setState((s) => ({
          ...s,
          [item.type]: sorted.map((o, idx) => ({
            ...o,
            index: idx + 1,
          })),
        }));
        const idsToSkip = [];

        item.questions.forEach((i) => {
          if (i.logic.skip) {
            i.logic.skip.forEach((skipId) => idsToSkip.push(skipId));
          }
        });
        setIds((s) => ({
          ...s,
          [item.type]: idsToSkip,
        }));
      });
    }
  }, [category, types]);

  useEffect(() => {
    history.listen(() => visible && setVisible(false));
  }, [visible, history]);

  return {
    formRef,
    updated,
    skipFormRef,
    onFinish,
    onCancelModal,
    form,
    visible,
    modal,
    ids,
    version,
    handleChangeWeighting,
    handleChangeVisibleQuestion,
    onEditMode,
    onSkipMode,
    onConfirmMode,
    findLastSkipQuestion,
    changeVersionTemplate,
    state,
    onSort,
    addNewQ,
    delQuestion,
    saveEditedQuestion,
    onChange,
    payload,
    handleChangeVisible,
  };
};

useController.propTypes = {
  types: PropTypes.array,
  onChangeState: PropTypes.func,
  category: PropTypes.string,
};

useController.defaultProps = {
  types: [],
  onChangeState: () => {},
  category: '',
};

export default useController;
