import PropTypes from 'prop-types';

import {
  useCallback, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button, Steps, Row, Col, message, Popconfirm,
} from 'antd';

import { useHistory, useParams } from 'react-router-dom';

import classNames from 'classnames';

import { COMPONENT_TYPES } from '../../../utils/constants';
import { userSelector } from '../../../store/auth/selectors';

import {
  fetchAssessments,
  fetchStatusAssessment,
  updateAssessment,
} from '../../../store/assessments/actions';

import {
  assessmentProgressSamAdminSelector,
  globalLoadingSelector,
  newAssessmentSelector,
} from '../../../store/assessments/selectors';
import { responseHandler } from '../../../utils/helpers';

import Vorbereitung from './Vorbereitung';
import Rating from './Rating/Rating';
import Auswertung from './Auswertung/Auswertung';
import StatusAssessment from '../../../components/common/StatusAssessment/StatusAssessment';
import ModalConfirm from '../../../components/common/modals/ModalConfirm/ModalConfirm';

import styles from './index.module.scss';

const COMPONENTS = {
  [COMPONENT_TYPES.VORBEREITUNG]: Vorbereitung,
  [COMPONENT_TYPES.ASSESSMENT]: StatusAssessment,
  [COMPONENT_TYPES.BEWERTUNG]: Rating,
  [COMPONENT_TYPES.AUSWERTUNG]: Auswertung,
};

const { Step } = Steps;

const WizardSteps = ({
  state, company, step, config,
}) => {
  const [isVisible, setVisible] = useState(false);
  const [isShow, setShow] = useState(true);

  const loading = useSelector(globalLoadingSelector);
  const statusAssessment = useSelector(assessmentProgressSamAdminSelector);
  const assessment = useSelector(newAssessmentSelector);
  const user = useSelector(userSelector);
  const history = useHistory();
  const dispatch = useDispatch();
  const { assessmentSlug } = useParams();

  const categoryRef = useRef(null);
  const formRef = useRef(null);

  const handleSubmit = useCallback(async () => {
    const err = [];
    Object.keys(categoryRef.current).forEach((key) => {
      if (!categoryRef.current[key].length) err.push(true);
    });
    if (err.includes(true)) {
      setVisible(false);
      message.error('Jeder Kategorie muss ein Bewerter zugewiesen sein.');
    } else {
      try {
        const result = await formRef.current.validateFields();
        const payload = {
          slug: assessmentSlug,
          state: 'STARTED',
          end: new Date(result.end).toISOString(),
          reminders: result.reminders.filter((n) => n).map((item) => ({ time: item, assessment: assessment.slug })),
        };
        dispatch(fetchStatusAssessment(assessmentSlug)).then((res) => responseHandler(res));
        // After this request the current step is changed
        dispatch(updateAssessment(payload)).then((res) => {
          if (res.error) {
            message.error('Genereller Fehler!');
          } else {
            setVisible(false);
            message.success('Das StatusAssessment wurde erfolgreich gestartet.');
            const historyState = history.location.state ? { ...history.location.state, step: 1 } : { step: 1 };
            history.push({ pathname: `/wizard/${assessmentSlug}` }, historyState);
          }
        });
      } catch (e) {
        setVisible(false);
        message.error('Es muss ein Zeitrahmen festgelegt sein.');
      }
    }
  }, [assessmentSlug, dispatch, assessment.slug, history]);

  const handleChangeLocation = useCallback((userData) => {
    const historyState = history.location.state ? history.location.state : null;
    history.push({ pathname: `/wizard/${assessmentSlug}/${userData?.user?.slug}` }, historyState);
  }, [history, assessmentSlug]);

  const renderContent = (item, idx) => {
    const Component = COMPONENTS[item.type];
    const props = {
      ...item,
      step,
      config,
      formRef,
      categoryRef,
      company,
      handleChangeLocation,
      title: 'Assessment Fortschritt',
      onChange: setShow,
    };
    return (
      <div key={idx.toString()}>
        {step === idx && Component && (
          <Component {...props} />)}
      </div>
    );
  };

  const openModal = () => {
    setVisible(true);
  };

  const changeStatus = async () => {
    if (config[user.role].length === 4 && step === 1) {
      await dispatch(updateAssessment({ state: 'WEIGHTING', slug: assessmentSlug }))
        .then((res) => responseHandler(res));
      await dispatch(fetchAssessments()).then((res) => {
        if (res.error) {
          responseHandler(res);
        } else {
          const historyState = history.location.state ? { ...history.location.state, step: 2 } : { step: 2 };
          history.push({ pathname: `/wizard/${assessmentSlug}`, search: 'weighting' }, historyState);
        }
      });
    }
    if (config[user.role].length === 4 && step === 2) {
      dispatch(updateAssessment({ state: 'ENDED', slug: assessmentSlug })).then((res) => {
        if (res.error) {
          responseHandler(res);
        } else {
          const historyState = history.location.state ? { ...history.location.state, step: 3 } : { step: 3 };
          history.push({ pathname: `/wizard/${assessmentSlug}` }, historyState);
        }
      });
    }
    if (config[user.role].length === 3 && step === 1) {
      dispatch(updateAssessment({ state: 'ENDED', slug: assessmentSlug })).then((res) => {
        if (res.error) {
          responseHandler(res);
        } else {
          const historyState = history.location.state ? { ...history.location.state, step: 2 } : { step: 2 };
          history.push({ pathname: `/wizard/${assessmentSlug}` }, historyState);
        }
      });
    }
  };

  return (
    <div className={styles.mainContainer} style={{ minHeight: `${window.innerHeight - 115}px` }}>
      <div style={{ marginBottom: '3px' }}>
        {!!Object.keys(config).length && (
          <Steps current={step}>
            { config[user.role].map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        )}

        <>
          <ModalConfirm
            title='Assessment starten'
            text='Möchten Sie das Assessment starten? '
            visible={isVisible}
            onOk={handleSubmit}
            onCancel={() => setVisible(false)}
          />
          {!!Object.keys(config).length && (
            <div className={styles.userAssessment}>
              { state && config[user.role].map((item, index) => renderContent(item, index)) }
            </div>
          )}

        </>
      </div>
      <div className={styles.actionButtons}>
        <Row justify='end'>
          <Col>
            {state && step === 0 && (
              <Button
                className={classNames({ [styles.hideActionButton]: step !== 0 })}
                onClick={openModal}
                type='primary'
                data-cy='startAssessment'
              >
                Assessment starten
              </Button>
            )}
            {!loading && state && step !== 0 && step !== config[user.role]?.length - 1 && (
              <Popconfirm
                className={classNames({
                  [styles.hideActionButton]: (user.role === 'SAM'
                  && assessment?.evaluator?.role === 'ADMIN' && !!statusAssessment.length) || !isShow,
                })}
                placement='topRight'
                title='Sind sie sicher?'
                onConfirm={changeStatus}
                okText='Ja'
                cancelText='Nein'
              >
                <Button type='primary'>Status ändern</Button>
              </Popconfirm>
            ) }
          </Col>
        </Row>
      </div>
    </div>
  );
};

WizardSteps.propTypes = {
  state: PropTypes.string,
  company: PropTypes.string,
  step: PropTypes.number,
  config: PropTypes.object.isRequired,
};
WizardSteps.defaultProps = {
  state: '',
  company: '',
  step: 0,
};

export default WizardSteps;
