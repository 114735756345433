import PropTypes from 'prop-types';

import {
  Button, Col, Form, Input, Modal, Row,
} from 'antd';
import { useEffect, useState } from 'react';

import styles from './index.module.scss';

const { Item } = Form;
const { TextArea } = Input;

const EditQuestion = ({
  data, onCancel, onSave, deleteQuestion, formRef, updated, onChange,
}) => {
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    formRef.current = form;
  }, [formRef, form]);

  useEffect(() => {
    if (data.data) {
      const o = {};
      o.question = data.data.question;
      o.hint = data.data?.hint ? data.data.hint : '';
      o.title = data.data.title;
      o.identifier = data.data.identifier;
      if (data.data?.logic?.answer_options?.length) {
        data.data?.logic?.answer_options.forEach((i) => {
          o[i.option.letter] = i.option.value;
        });
      }
      setInitialValues(o);
    }
  }, [data]);

  useEffect(() => {
    if (data.visible) {
      form.resetFields();
    }
  });

  return (
    <Modal
      width={900}
      visible={data.visible}
      centered
      title='Frage bearbeiten'
      onCancel={onCancel}
      footer={(
        <Row justify='space-between'>
          <Button className={styles.deleteButton} type='primary' onClick={deleteQuestion}>Frage löschen</Button>
          <Button
            type='primary'
            key='save'
            onClick={onSave}
            disabled={updated}
            loading={updated}
          >
            Speichern
          </Button>
        </Row>
)}
    >
      <Form form={form} layout='vertical' initialValues={initialValues}>
        <Row justify='space-between'>
          <Col span={10}>
            <Item
              name='identifier'
              label='Identifier'
              rules={[{ required: true, message: 'Bitte eine identifier eingeben' }]}
            >
              <Input />
            </Item>
          </Col>
          <Col span={13}>
            {/* eslint-disable-next-line max-len */}
            <Item name='title' label='Bezeichnung' rules={[{ required: true, message: 'Bitte eine Bezeichnung eingeben.' }]}>
              <TextArea placeholder='Bezeichnung der Frage/Aussage' />
            </Item>
          </Col>
        </Row>
        <Row justify='space-between'>
          <Col span={10}>
            <Item
              name='hint'
              label={(
                <>
                  <span>Hinweis</span>
                  <span>(optional)</span>
                </>
              )}
            >
              <TextArea />
            </Item>

          </Col>
          <Col span={13}>
            <Item
              name='question'
              label='Frage'
              rules={[{ required: true, message: 'Bitte eine Frage eingeben' }]}
            >
              <TextArea />
            </Item>
          </Col>
        </Row>
        <Row justify='center'>
          {(data?.data?.type === 'MULTIPLE_CHOICE' && data.data?.logic?.answer_options?.length)
            ? (
              <Col span={24}>
                <span>Antwort Auswahl</span>
                {data.data.logic.answer_options.map((item, idx) => (
                  <Item
                    key={idx.toString()}
                    name={item.option.letter}
                  >
                    <TextArea
                      onChange={(e) => onChange(e, item)}
                    />
                  </Item>
                ))}
              </Col>
            )
            : null}
        </Row>
      </Form>
    </Modal>
  );
};

EditQuestion.propTypes = {
  data: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  deleteQuestion: PropTypes.func.isRequired,
  formRef: PropTypes.object,
  updated: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

EditQuestion.defaultProps = {
  formRef: { current: null },
  onChange: () => {},
};

export default EditQuestion;
