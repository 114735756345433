import {
  Alert, Button, Col, Row, Spin,
} from 'antd';

import { useSelector } from 'react-redux';

import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { globalLoadingSelector, userAssessmentsSelector } from '../../store/assessments/selectors';

import styles from './index.module.scss';
import Help from '../Help';

export default function ClientHome() {
  const globalLoading = useSelector(globalLoadingSelector);
  const assessments = useSelector(userAssessmentsSelector);

  const history = useHistory();

  const getStarted = () => {
    history.push(`/assessment/${assessments[0].slug}`);
  };

  const title = useMemo(() => (
    <Row>
      <Col>
        <h1 className={styles.title}>{assessments[0]?.title || 'no title'}</h1>
      </Col>
      <Col>
        <p className={styles.welcome}>
          Willkommen zum
          {' '}
          {assessments[0]?.title || 'no title'}
          !
        </p>
        <p className={styles.explanation}>
          Sie wurden als Bewerter für das
          {' '}
          {assessments[0]?.title || 'no title'}
          {' '}
          ausgewählt. Bitte bewerten Sie die
          Aussagen/Fragen der Ihnen zugewiesenen Bewertungskategorien.
          Folgende Bewertungsmöglichkeiten sind Teil des Assessments:
        </p>
      </Col>
    </Row>
  ), [assessments]);

  return (
    <Row style={{ minHeight: `${window.innerHeight - 90}px`, alignItems: 'center' }} justify='center'>
      <Spin spinning={globalLoading}>
        {!globalLoading && (
        <>
          {assessments?.length
            ? (
              <>
                <Help title={title} />
                <Row justify='center' style={{ margin: '20px 35px' }}>
                  <Col>
                    <Button type='primary' onClick={getStarted}>OKAY, Los geht´s!</Button>
                  </Col>
                </Row>
              </>
            )
            : (
              <div className={styles.noAssessment}>
                <Alert
                  message='Information'
                  description='Aktuell ist kein Assessment für diesen Benutzer freigeschalten.'
                  type='info'
                  showIcon
                />
              </div>
            )}
        </>
        )}
      </Spin>
    </Row>
  );
}
