import { FETCH_ALL_QUESTIONS, FETCH_QUESTION } from './types';

export const fetchAllQuestions = ({ assessmentSlug, categorySlug }) => ({
  type: FETCH_ALL_QUESTIONS,
  request: {
    method: 'GET',
    url: 'questions/',
    params: { assessment: assessmentSlug, category: categorySlug },
  },
});

export const fetchQuestion = (slug) => ({
  type: FETCH_QUESTION,
  request: {
    method: 'GET',
    url: `questions/${slug}/`,
  },
});
