import PropTypes from 'prop-types';

import {
  Dropdown, Layout, Menu, Col, Row, message,
} from 'antd';
import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';

import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  NavLink, useHistory, useLocation,
} from 'react-router-dom';

import classNames from 'classnames';
import styles from './index.module.scss';

import SideBar from './components/Sidebar';
import AdminSidebar from './components/AdminSidebar';

import { getUser, logout } from '../../../store/auth/actions';
import { isAuthorized, userSelector } from '../../../store/auth/selectors';

import {
  changeGlobalLoading,
  fetchAssessmentConfig,
  fetchAssessments, setDefaultAssessmentState,
} from '../../../store/assessments/actions';
import { templatesSelector } from '../../../store/assessments/selectors';
import { fetchCompanies } from '../../../store/company/actions';
import companySelector from '../../../store/company/selectors';

import { setDefaultCategoriesState } from '../../../store/categories/actions';
import UserSidebar from './components/UserSidebar';

const AuthorizedLayout = ({
  auth, children, ...props
}) => {
  const [collapsed, setCollapsed] = useState(false);

  const { pathname, search } = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const history = useHistory();
  const authorized = useSelector(isAuthorized);
  const templates = useSelector(templatesSelector);
  const companies = useSelector(companySelector);

  const componentMounted = useRef(true);

  useEffect(() => {
    if (authorized) {
      if (user.role === 'CLIENT') {
        dispatch(changeGlobalLoading(true));
      }
      dispatch(fetchAssessments());
      dispatch(fetchCompanies());
    }
  }, [authorized, dispatch, user.role]);

  useEffect(() => {
    if ((user.role === 'ADMIN' || user.role === 'SAM') && componentMounted) {
      dispatch(fetchAssessmentConfig()).then((res) => {
        if (res?.error?.response?.status > 400) {
          return message.error('General server error');
        }
        return () => {
          componentMounted.current = false;
        };
      });
    }
  }, [user.role, dispatch]);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  const toggleSidebar = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const logOut = () => {
    dispatch(logout()).then(() => {
      dispatch(setDefaultAssessmentState());
      dispatch(setDefaultCategoriesState());
      history.push('/login');
    });
  };

  const renderSidebar = () => {
    if (user.role === 'ADMIN') {
      return (
        <AdminSidebar
          companies={companies}
          templates={templates}
          collapsed={collapsed}
          onChange={toggleSidebar}
        />
      );
    }
    if (user.role === 'SAM') {
      return <SideBar onChange={toggleSidebar} collapsed={collapsed} companies={companies} />;
    }
    return (
      <UserSidebar
        collapsed={collapsed}
        onChange={toggleSidebar}
      />
    );
  };

  return (
    <>
      {authorized && (
      <Layout style={{ height: '100vh', overflow: 'auto' }}>
        { renderSidebar() }
        <Layout className={styles.siteLayout}>
          <Layout.Header className={styles.siteLayoutBackground} style={{ padding: 0 }}>
            <Row>
              <Col span={4} offset={20}>
                <span className={styles.settingsMenu} data-cy='menu'>
                  <Dropdown
                    overlay={(
                      <Menu>
                        <Menu.Item key='settings'>
                          <NavLink to='/settings'>
                            <SettingOutlined />
                            {' '}
                            Einstellungen
                          </NavLink>
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item onClick={logOut} key='logout'>
                          <a>
                            <LogoutOutlined />
                            {' '}
                            Abmelden
                          </a>
                        </Menu.Item>
                      </Menu>
                    )}
                    placement='bottomRight'
                  >
                    <span className={styles.userNamePlacement}>
                      <UserOutlined />
                      {' '}
                      {user.first_name || ''}
                    </span>
                  </Dropdown>
                </span>
              </Col>
            </Row>
          </Layout.Header>
          <Layout.Content
            className={classNames(styles.mainContent,
              { [styles.scrollMainContent]: `${pathname}${search}` === `/questionnaire-configuration${search}` })}
            {...props}
          >
            { children }
          </Layout.Content>
          <div />
        </Layout>
      </Layout>
      )}
    </>
  );
};

AuthorizedLayout.propTypes = {
  auth: PropTypes.bool,
  children: PropTypes.any,
};

AuthorizedLayout.defaultProps = {
  auth: false,
  children: null,
};

export default AuthorizedLayout;
