import PropTypes from 'prop-types';
import {
  Button, Col, Radio, Row, Space, Typography,
} from 'antd';

import styles from './index.module.scss';

const { Title, Paragraph, Text } = Typography;

const RatingExplanation = ({ handleChangeVisibleStatusButton, onChange, title }) => (
  <div className={styles.main}>
    <Row>
      <Title level={3}>
        {`Willkommen zum ${title}!`}
      </Title>
    </Row>
    <Paragraph style={{ fontSize: '18px' }}>
      {`Sie wurden als Bewerter für das ${title} ausgewählt. Bitte bewerten Sie die Antworten der Befragten
      anhand folgender Bewertungslogik:
    `}
    </Paragraph>
    <Radio.Group defaultValue='0' disabled className={styles.radio}>
      <Space direction='vertical'>
        <Row className={styles.container}>
          <Col><Radio value='0'>0</Radio></Col>
          <Col className={styles.explanation}>
            <Text>
              Die Anforderungen werden nicht erfüllt. Es gibt akuten Handlungsbedarf. Es ist
              zwingend eine entsprechende Begründung und Verbesserungsmaßnahme zu notieren.
            </Text>
          </Col>
        </Row>
        <Row className={styles.container}>
          <Col>
            <Radio value='5'>5</Radio>
          </Col>
          <Col className={styles.explanation}>
            <Text>
              Die Anforderungen werden teilweise erfüllt. Es gibt Verbesserungsbedarf. Es ist
              zwingend eine entsprechende Begründung und Verbesserungsmaßnahme zu notieren.
            </Text>
          </Col>
        </Row>
        <Row className={styles.container}>
          <Col><Radio value='8'>8</Radio></Col>
          <Col className={styles.explanation}>
            <Text>
              Die Anforderungen werden weitestgehend erfüllt. Kleine Details können noch
              ausgebessert werden.
            </Text>
          </Col>
        </Row>
        <Row className={styles.container}>
          <Col><Radio value='10'>10</Radio></Col>
          <Col className={styles.explanation}>
            <Text>Die Anforderungen werden vollumfänglich erfüllt oder sogar übertroffen.</Text>
          </Col>
        </Row>
      </Space>
    </Radio.Group>
    <Row className={styles.container}>
      <Col>
        <Button disabled size='small'>
          Keine Antwort möglich
        </Button>
      </Col>
      <Col className={styles.explanation}><Text>Wenn Antworten unzureichend für Bewertung</Text></Col>
    </Row>
    <Row className={styles.actionBtn}>
      <Button
        type='primary'
        onClick={() => {
          handleChangeVisibleStatusButton(true);
          onChange(false);
        }}
      >
        OKAY, Los geht´s!
      </Button>
    </Row>
  </div>

);

RatingExplanation.propTypes = {
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  handleChangeVisibleStatusButton: PropTypes.func.isRequired,
};
export default RatingExplanation;
