import PropTypes from 'prop-types';

import { useCallback, useEffect, useState } from 'react';

import { Form, Checkbox, Button } from 'antd';

import classNames from 'classnames';
import styles from './SkipForm.module.scss';

const SkipForm = ({
  formRef, skipOptions, onFinish, initialValues,
}) => {
  const [form] = Form.useForm();
  const [skips, setSkips] = useState([]);

  const renderSkipOption = useCallback((item, idx) => (
    <Form.Item
      key={idx.toString()}
      name={item.slug}
      label={item.title}
      valuePropName='checked'
      className={classNames(styles.question, { [styles.skipQuestion]: skips.includes(item.slug) })}
    >
      <Checkbox />
    </Form.Item>
  ), [skips]);

  const handleChange = useCallback((update, state) => {
    const [[key, value]] = Object.entries(update);
    const keys = Object.keys(state);
    const idx = keys.findIndex((i) => i === key);
    if (value) {
      form.setFields(keys.slice(0, idx).map((name) => ({ name: [name], value })));
    } else {
      form.setFields(keys.slice(idx).map((name) => ({ name: [name], value })));
    }
    const values = form.getFieldsValue();
    setSkips(Object.keys(values).filter((k) => values[k]));
  }, [form]);

  useEffect(() => {
    if (initialValues) setSkips(Object.keys(initialValues));
  }, [initialValues]);

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    formRef.current = form;
  }, [form, formRef]);

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onValuesChange={handleChange}
    >
      { skipOptions.map(renderSkipOption) }
      {skipOptions.length === 0 && (
        <span>Von dieser Frage gibt es keine geeigneten Sprungziele</span>
      )}
      {!!skipOptions.length && (
      <Form.Item>
        <Button type='primary' onClick={() => onFinish(form.getFieldsValue())}>
          Speichern
        </Button>
      </Form.Item>
      )}
    </Form>
  );
};

SkipForm.propTypes = {
  formRef: PropTypes.shape({ current: PropTypes.object }).isRequired,
  onFinish: PropTypes.func,
  skipOptions: PropTypes.array,
  initialValues: PropTypes.object,
};

SkipForm.defaultProps = {
  onFinish: () => null,
  skipOptions: [],
  initialValues: null,
};

export default SkipForm;
