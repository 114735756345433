import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  Col, Image, Progress, Row, Spin, Typography,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';

import classNames from 'classnames';

import { globalLoadingSelector, userAssessmentsSelector } from '../../store/assessments/selectors';
import { userSelector } from '../../store/auth/selectors';

import styles from './index.module.scss';

const { Title } = Typography;

const UserAssessmentProgress = () => {
  const [assessment, setAssessment] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const assessments = useSelector(userAssessmentsSelector);
  const user = useSelector(userSelector);
  const loading = useSelector(globalLoadingSelector);

  const history = useHistory();
  const { slug } = useParams();

  const renderTitle = () => {
    const name = user?.first_name;
    const lastName = user?.last_name;
    return (name && lastName) ? `${name} ${lastName}` : user?.email;
  };

  const getStarted = (category) => {
    if (category.is_assigned) {
      history.push(`/assessment/${slug}/${category.slug}`);
    }
  };

  const renderCategories = (item, idx) => (
    <Row
      align='center'
      key={idx.toString()}
      className={classNames(styles.category, { [styles.disabled]: !item.is_assigned })}
      role='presentation'
      onClick={() => getStarted(item)}
    >
      <Col>
        <Image preview={false} src={item.image} alt='icon category' width={20} />
        <span>{item.name}</span>
      </Col>
      <Col>
        {item.is_assigned && (
          <>
            <Progress
              percent={Number(((item?.answered_questions_count * 100) / item?.questions_count).toFixed(2)) || 0}
              steps={item?.questions_count || 0}
              size='small'
              strokeColor='#1890ff'
            />
            <span className={styles.question}>
              {item?.answered_questions_count || 0}
              /
              {item?.questions_count || 0}
            </span>
          </>
        )}
      </Col>
    </Row>
  );

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    const currentAssessment = assessments.find((item) => item.slug === slug);
    const totalQuestions = currentAssessment?.categories.filter((i) => i.is_assigned)
      .map((item) => item?.questions_count)
      .reduce((a, b) => a + b, 0);
    const totalAnswers = currentAssessment?.categories.filter((i) => i.is_assigned)
      .map((item) => item?.answered_questions_count).reduce((a, b) => a + b, 0);
    const result = Number(((totalAnswers * 100) / totalQuestions).toFixed(2));
    setAssessment(currentAssessment);
    setProgress(result);
  }, [assessments, slug]);

  return (
    <Spin spinning={isLoading}>
      <div className={styles.userAssessment}>
        {!!assessment?.categories?.length && (
          <>
            <Title level={3} style={{ textAlign: 'center' }}>{assessment?.title}</Title>
            <Row justify='space-around'>
              <Col span={4} className={styles.userProgress}>
                <UserOutlined className={styles.userIcon} />
                <Title level={3} style={{ textAlign: 'center' }}>{renderTitle()}</Title>
                <Progress type='circle' percent={progress} style={{ marginTop: '20px' }} />
              </Col>
              <Col span={18}>
                {(assessment?.categories || []).map(renderCategories)}
              </Col>
            </Row>
          </>
        )}
      </div>
    </Spin>
  );
};

export default UserAssessmentProgress;
