import PropTypes from 'prop-types';

import {
  Input, Form, Row, Col, Radio, Card, Switch,
} from 'antd';
import { CheckOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons';

import { useState } from 'react';
import styles from '../../index.module.scss';

const { TextArea } = Input;
const { Item } = Form;

const TypeOfAnswer = ({ onChange, dataRef }) => {
  const [activeType, setActiveType] = useState(dataRef.current.type);

  const handleChangeType = (type) => {
    onChange(type);
    setActiveType(type);
  };

  return (
    <>
      <Row justify='center'>
        <Col xs={18} sm={16} md={12} lg={10} xl={8} xxl={6}>
          <Row justify='space-around'>
            <Col>
              <UploadOutlined style={{ fontSize: '27px' }} />
            </Col>
            <Col>
              Datenupload aktivieren
            </Col>
            <Col>
              <Item name='has_file_upload' valuePropName='checked' initialValue={dataRef.current.has_file_upload}>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Item>
            </Col>
          </Row>
        </Col>
      </Row>
      {dataRef.current.assessment_type !== 'PERFORMANCE_CHECK'
        ? (
          <Row justify='space-around' className={styles.statusAnalyseCardsContainer}>
            <Col span={5} md={14} sm={24} xs={24} xxl={5} xl={7} lg={9}>
              <Card
                title='Freitext'
                bordered
                className={`${styles.answerCard} ${activeType === 'TEXT' && styles.active}`}
                onClick={() => handleChangeType('TEXT')}
                data-cy='text'
              >
                <TextArea
                  readOnly
                  placeholder='Freitext Anwort. Die Höhe der Eingabebox kann individuell angepasst werden.'
                />
              </Card>
            </Col>
            <Col span={5} md={14} sm={24} xs={24} xxl={5} xl={7} lg={9}>
              <Card
                title='Antwortauswahl'
                className={`${styles.answerCard} ${activeType === 'MULTIPLE_CHOICE' && styles.active}`}
                onClick={() => handleChangeType('MULTIPLE_CHOICE')}
                data-cy='multipleChoice'
              >
                <Radio.Group defaultValue='0' disabled>
                  <Row>
                    <Col span={12}>
                      <Radio value='0'>Option A</Radio>
                      <Radio value='1'>Option B</Radio>
                    </Col>
                    <Col span={12}>
                      <Radio value='2'>Option C</Radio>
                      <Radio value='3'>Option D</Radio>
                    </Col>
                  </Row>
                </Radio.Group>
              </Card>
            </Col>
            <Col span={5} md={14} sm={24} xs={24} xxl={5} xl={7} lg={9}>
              <Card
                title='Ja/Nein'
                className={`${styles.answerCard} ${activeType === 'BOOLEAN' && styles.active}`}
                onClick={() => handleChangeType('BOOLEAN')}
              >
                <Radio.Group defaultValue='0' disabled>
                  <Radio value='0'>JA</Radio>
                  <Radio value='1'>NEIN</Radio>
                </Radio.Group>
              </Card>
            </Col>
          </Row>
        )
        : (
          <Row justify='center' className={styles.performanceCardsContainer}>
            <Col span={5} md={14} sm={24} xs={24} xxl={5} xl={7} lg={9} style={{ marginRight: '50px' }}>
              <Card
                title='Wertung'
                className={`${styles.answerCard} ${activeType === 'NUMERIC' && styles.active}`}
                onClick={() => handleChangeType('NUMERIC')}
                data-cy='numeric'
              >
                <Radio.Group defaultValue='0' disabled>
                  <Row gutter={[10, 5]}>
                    <Col md>
                      <Radio value='0'>0</Radio>
                    </Col>
                    <Col><Radio value='1'>5</Radio></Col>
                    <Col>
                      <Radio value='2'>8</Radio>
                    </Col>
                    <Col>
                      <Radio value='3'>10</Radio>
                    </Col>
                  </Row>
                </Radio.Group>
              </Card>
            </Col>
            <Col span={5} md={14} sm={24} xs={24} xxl={5} xl={7} lg={9}>
              <Card
                title='Ja/Nein'
                className={`${styles.answerCard} ${activeType === 'BOOLEAN' && styles.active}`}
                onClick={() => handleChangeType('BOOLEAN')}
              >
                <Radio.Group defaultValue='0' disabled>
                  <Radio value='0'>JA</Radio>
                  <Radio value='1'>NEIN</Radio>
                </Radio.Group>
              </Card>
            </Col>
          </Row>
        )}
    </>
  );
};
TypeOfAnswer.propTypes = {
  onChange: PropTypes.func.isRequired,
  dataRef: PropTypes.object.isRequired,
};
export default TypeOfAnswer;
