export const LOGIN_USER = 'LOGIN_USER';
export const SUBMIT_2FA = 'SUBMIT_2FA';
export const LOG_OUT = 'LOG_OUT';
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const ENABLE_2FA = 'ENABLE_2FA';
export const CONFIRM_2FA = 'CONFIRM_2FA';
export const DELETE_2FA = 'DELETE_2FA';
export const GET_USERS = 'GET_USERS';
export const CREATE_USER = 'CREATE_USER';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_CONFIRM = 'RESET_PASSWORD_CONFIRM';
