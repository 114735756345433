import { CREATE_PRE_ASSESSMENT, FETCH_PRE_ASSESSMENTS, UPDATE_PRE_ASSESSMENT } from './types';

export const fetchPreAssessments = (slug) => {
  const params = slug ? { assessment: slug } : null;
  return {
    type: FETCH_PRE_ASSESSMENTS,
    request: {
      method: 'GET',
      url: 'preassessments/',
      params,
    },
  };
};

export const createPreAssessment = (data) => ({
  type: CREATE_PRE_ASSESSMENT,
  request: {
    method: 'POST',
    url: 'preassessments/',
    data,
  },
});

export const updatePreAssessment = ({ slug, ...rest }) => ({
  type: UPDATE_PRE_ASSESSMENT,
  request: {
    method: 'PATCH',
    url: `preassessments/${slug}/`,
    data: rest,
  },
});
