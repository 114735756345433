import {
  Col, Form, Row, Input,
} from 'antd';

const { Item } = Form;

const QuestionName = () => (
  <Row justify='center'>
    <Col span={10}>
      <Item name='title' rules={[{ required: true, message: 'Bitte eine Bezeichnung eingeben.' }]}>
        <Input placeholder='Bezeichnung der Frage/Aussage' />
      </Item>
    </Col>
  </Row>
);

export default QuestionName;
