import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Button, Card, Col, Form, Input, Row,
} from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import styles from '../index.module.scss';
import { isSubmitting } from '../../../store/auth/selectors';

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const ProfileForm = ({ user, onSubmit }) => {
  const [form] = Form.useForm();
  const submitting = useSelector(isSubmitting);
  const [touched, setTouched] = useState(false);

  useEffect(() => form.resetFields(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]);

  return (
    <>
      <Form
        {...formItemLayout}
        initialValues={user}
        className={styles.settingsForm}
        onFinish={onSubmit}
        onFieldsChange={() => setTouched(true)}
        form={form}
        layout='vertical'
      >
        <Card title='Profil' bordered={false} level={4} className={styles.title}>
          <Row gutter={[16, 16]} className={styles.row}>
            <Col>
              <Form.Item
                name='email'
                style={{ textAlign: 'left' }}
                className={styles.formItemAlignment}
              >
                <Input
                  readOnly
                  placeholder='Email'
                  className={styles.input}
                  prefix={<UserOutlined />}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name='first_name'
                style={{ textAlign: 'left' }}
                className={styles.formItemAlignment}
              >
                <Input
                  placeholder='Name'
                  className={styles.input}
                  prefix={<UserOutlined />}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name='last_name'
                style={{ textAlign: 'left' }}
                className={styles.formItemAlignment}
              >
                <Input
                  placeholder='Nachname'
                  className={styles.input}
                  prefix={<UserOutlined />}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                className={styles.formItemAlignment}
                name='password1'
              >
                <Input.Password
                  className={styles.input}
                  placeholder='Passwort'
                  prefix={<LockOutlined className='icon' />}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                className={styles.formItemAlignment}
                name='password2'
                dependencies={['password1']}
                hasFeedback
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password1') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <Input.Password
                  className={styles.input}
                  placeholder='Passwort bestätigen'
                  prefix={<LockOutlined className='icon' />}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  className={styles.buttonAlign}
                  loading={submitting}
                  disabled={!touched}
                >
                  Speichern
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </>
  );
};

ProfileForm.propTypes = {
  user: PropTypes.object,
  onSubmit: PropTypes.func,
};

ProfileForm.defaultProps = {
  user: {},
  onSubmit: () => {},
};

export default ProfileForm;
