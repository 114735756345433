import PropTypes from 'prop-types';

import { useState } from 'react';

import {
  Slider as AntSlider, Form, Button, Card,
} from 'antd';

import styles from './index.module.scss';

const Slider = ({
  name, onConfirm, sliderTitle, slug,
}) => {
  const [value, setValue] = useState(0);

  const formatter = (val) => `${val} %`;

  return (
    <Card title={sliderTitle} className={styles.container}>
      <p>{name}</p>
      <Form.Item name={slug}>
        <AntSlider
          tipFormatter={formatter}
          className={styles.sliderRail}
          marks={{
            0: '0',
            25: '25',
            50: '50',
            75: '75',
            100: '100',
          }}
          trackStyle={{ backgroundColor: 'transparent' }}
          onChange={(e) => setValue(e)}
        />
      </Form.Item>

      <p id='valueResult'>
        {value}
        {' '}
        %
      </p>
      { onConfirm && <Button type='primary' onClick={() => onConfirm(slug)}>Weiter</Button> }
    </Card>
  );
};

Slider.propTypes = {
  name: PropTypes.string.isRequired,
  onConfirm: PropTypes.func,
  sliderTitle: PropTypes.string,
  slug: PropTypes.string,
};

Slider.defaultProps = {
  onConfirm: null,
  sliderTitle: '',
  slug: '',
};

export default Slider;
