import { error, success } from '@redux-requests/core';

import {
  ADD_COMPANY, GET_COMPANIES, GET_COMPANY, UPDATE_COMPANY,
} from './types';

const initialState = {
  loading: false,
  error: false,
  companies: [],
  company: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANIES:
      return { ...state, loading: true };
    case success(GET_COMPANIES):
      return {
        ...state, companies: action.response.data, loading: false, error: false,
      };
    case error(GET_COMPANIES):
      return { ...state, loading: false, error: true };
    case ADD_COMPANY:
      return { ...state, loading: true };
    case success(ADD_COMPANY):
      return {
        ...state, companies: [...state.companies, action.response.data], loading: false, error: false,
      };
    case error(ADD_COMPANY):
      return { ...state, loading: false, error: true };
    case GET_COMPANY:
      return { ...state, loading: true };
    case success(GET_COMPANY):
      return {
        ...state,
        companies: [...state.companies.filter((el) => el.slug !== action.response.data.slug),
          action.response.data],
        loading: false,
        error: false,
      };
    case error(GET_COMPANY):
      return { ...state, loading: false, error: true };
    case UPDATE_COMPANY:
      return { ...state, loading: true };
    case success(UPDATE_COMPANY):
      return {
        ...state,
        companies: [...state.companies.filter((el) => el.slug !== action.response.data.slug),
          action.response.data],
        loading: false,
        error: false,
      };
    case error(UPDATE_COMPANY):
      return { ...state, loading: false, error: true };
    default: return state;
  }
};
export default reducer;
