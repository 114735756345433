import PropTypes from 'prop-types';
import {
  Radio, Form, Card, Row, Col, Image,
} from 'antd';
import styles from './index.module.scss';

const RadioButtonGroup = ({
  name, children, title, headline,
}) => {
  const renderCard = (item, idx) => (
    <Radio.Button
      key={idx.toString()}
      value={item.value}
    >
      {item.img
        ? (
          <>
            <p style={{ marginTop: 45 }}>{item.text}</p>
            <div>
              <Image preview={false} src={item.img} alt={item.alt} />
              {' '}
            </div>
          </>
        )
        : <p style={{ marginTop: 80 }}>{item.text}</p>}
    </Radio.Button>
  );

  return (
    <Card title={title || headline || ''} bordered level={4} className={styles.group}>
      <Row gutter={[16, 16]}>
        <Col span={24} style={{ height: 205 }}>
          <Form.Item name={name}>
            <Radio.Group>
              { children.map(renderCard) }
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

RadioButtonGroup.propTypes = {
  children: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  headline: PropTypes.string,
};

RadioButtonGroup.defaultProps = {
  headline: '',
};

export default RadioButtonGroup;
