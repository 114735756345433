import PropTypes from 'prop-types';

import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  Button,
  Col, Collapse, message, Row, Select,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { deleteInvitedUser } from '../../../../../store/invite/actions';
import { getCompany } from '../../../../../store/company/actions';
import {
  deleteAssessmentUser,
  createAssessmentUser,
} from '../../../../../store/assessments/actions';

import { assessmentUsersSelector, newAssessmentSelector } from '../../../../../store/assessments/selectors';
import { responseHandler } from '../../../../../utils/helpers';

import ModalConfirm from '../../../../../components/common/modals/ModalConfirm/ModalConfirm';
import Tags from './Tags/Tags';
import AddUserForm from './AddUserForm/AddUserForm';

import styles from './index.module.scss';

const { Panel } = Collapse;
const { Option } = Select;

const Categories = ({ data, categoryRef }) => {
  const [allUsers, setAllUsers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [addedUsers, setAddedUsers] = useState({});
  const [categoryUsers, setCategoryUsers] = useState({});
  const [addUserModal, setAddUserModal] = useState({ isVisible: false });
  const [deleteModal, setDeleteModal] = useState({ isVisible: false });
  const [isSubmitting, setSubmitting] = useState(false);
  const [isDisabled, setDisabled] = useState(false);

  const assessmentUsers = useSelector(assessmentUsersSelector);
  const assessment = useSelector(newAssessmentSelector);
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleSubmit = useCallback(async () => {
    try {
      const result = await formRef?.current?.validateFields();
      setSubmitting(true);
      dispatch(createAssessmentUser({
        first_name: result.first_name,
        last_name: result.last_name,
        user: result.email,
        slug: assessment.slug,
        category: addUserModal.slug,
        assessment: assessment.slug,
      })).then((res) => responseHandler(res, () => {
        const name = res.data?.first_name;
        const lastName = res.data?.last_name;
        const label = (name && lastName) ? `${name} ${lastName}` : res.data?.user;
        setEmployees((s) => [...s, ...[{
          label,
          value: res.data?.slug,
          slug: res.data.user_slug,
          user_slug: res.data.user_slug,
          user: res.data.user,
          email: res.data.user,
          first_name: res.data?.first_name,
          last_name: res.data?.last_name,
        }]]);
        setAllUsers((s) => [...s, ...[res.data]]);
        setAddUserModal({ isVisible: false });
        formRef?.current?.resetFields();
      }));
      setSubmitting(false);
    } catch (e) {
      message.error('Validation error');
    }
  }, [addUserModal.slug, dispatch, assessment.slug]);

  const onClose = useCallback(() => {
    setAddUserModal({ isVisible: false });
    formRef?.current?.resetFields();
  }, []);

  const handleChangeAddUserModal = useCallback((category) => {
    if (category) {
      setAddUserModal({ isVisible: true, slug: category });
    } else {
      setAddUserModal({ isVisible: true });
    }
  }, []);

  /* const handleChangeDeleteModal = useCallback((e, option) => {
    e.stopPropagation();
    e.preventDefault();
    setDeleteModal({
      isVisible: true,
      option,
    });
  }, []); */

  const closeDeleteModal = useCallback(() => {
    setDeleteModal({ isVisible: false });
  }, []);

  const deleteUser = useCallback((payload) => {
    const [[, val]] = Object.entries(payload);
    setDisabled(true);
    dispatch(deleteAssessmentUser({
      userSlug: val.slug,
      slug: val.assessment,
    })).then((res) => {
      responseHandler(res, () => setAllUsers((s) => s.filter((item) => item.slug !== val.slug)));
      setDisabled(false);
    });
  }, [dispatch]);

  const deleteUserFromDataBase = useCallback(() => {
    dispatch(deleteInvitedUser(deleteModal.option.slug)).then((res) => responseHandler(res, () => {
      setAllUsers((s) => s.filter((item) => item.slug !== deleteModal?.option?.slug));
      Object.keys(addedUsers).forEach((key) => {
        setAddedUsers((s) => ({
          ...s,
          [key]: s[key].filter((slug) => slug !== deleteModal?.option?.slug),
        }));
      });
      setDeleteModal({ isVisible: false });
    }));
  }, [addedUsers, deleteModal, dispatch]);

  const getSelectedUsers = useCallback((categorySlug) => {
    const result = [];

    categoryUsers[categorySlug]?.forEach((i) => {
      if (addedUsers[categorySlug]?.includes(i.user_slug)) {
        result.push(i);
      }
    });
    return result;
  }, [addedUsers, categoryUsers]);

  const handleChangeDeselected = useCallback(() => false, []);

  const renderOptions = useCallback((option, idx) => (
    <Option
      value={option.slug}
      key={idx.toLocaleString()}
      email={option.email}
      firstname={option.first_name}
      lastname={option.last_name}
    >
      <div className={styles.option}>
        <div>
          { option.label}
        </div>
        {/* <Button
          onClick={(e) => handleChangeDeleteModal(e, option)}
          type='primary'
          danger
          size='small'
          icon={<DeleteOutlined style={{ fontSize: '11px' }} />}
        >
          Delete user
        </Button> */}
      </div>
    </Option>
  ), []);

  const onSelected = useCallback((option, category) => {
    dispatch(createAssessmentUser({
      first_name: option.firstname ? option.firstname : 'dev',
      last_name: option.lastname ? option.lastname : 'dev',
      user: option.email,
      slug: assessment.slug,
      category,
      assessment: assessment.slug,
    })).then((res) => responseHandler(res, () => {
      setAllUsers((s) => [...s, ...[res.data]]);
      setAddUserModal({ isVisible: false });
      formRef?.current?.resetFields();
    }));
  }, [assessment.slug, dispatch]);

  // eslint-disable-next-line max-len
  const filterOption = (input, option) => `${option.firstname}${option.lastname}`.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const source = useMemo(() => data.map((item, idx) => (
    <Panel
      className={styles.panel}
      showArrow={false}
      key={idx.toString()}
      header={(
        <>
          <img
            src={item?.image[0].includes('/') ? item.image : `/${item.image}`}
            alt='icon category'
            width={20}
            height={20}
          />
          <span>{item.name}</span>
        </>
      )}
    >
      <Row justify='space-between'>
        <Col span={18}>
          <Select
            optionFilterProp='children'
            filterOption={(input, option) => filterOption(input, option)}
            disabled={isDisabled}
            value={addedUsers[item.slug] || []}
            mode='multiple'
            showArrow
            style={{ width: '100%' }}
            tagRender={() => null}
            menuItemSelectedIcon={() => null}
            onDeselect={handleChangeDeselected}
            onSelect={(val, option) => onSelected(option, item.slug)}
          >
            { (employees || []).map(renderOptions) }
          </Select>
        </Col>
        <Col span={5}>
          <Button
            onClick={() => handleChangeAddUserModal(item.slug)}
            type='dashed'
            icon={<PlusOutlined />}
          >
            Bewerter
          </Button>
        </Col>
      </Row>
      <Tags
        tags={getSelectedUsers(item.slug)}
        deleteUser={deleteUser}
        category={item.slug}
      />
    </Panel>
  )), [onSelected, data, isDisabled, addedUsers, employees, renderOptions, getSelectedUsers,
    deleteUser, handleChangeDeselected, handleChangeAddUserModal]);

  const sortUsers = useCallback((items) => {
    const initial = data.map((item) => item.slug).reduce((all, val) => ({ ...all, [val]: [] }), {});
    const { local } = (items || []).reduce((all, user) => {
      if (all.local[user.category]) {
        const name = user.first_name;
        const lastName = user.last_name;
        const label = (name && lastName) ? `${name} ${lastName}` : user.user;
        all.local[user.category].push({ label, value: user.user, ...user });
      }
      return all;
    }, { local: initial });

    const o = {};
    Object.keys(local).forEach((key) => {
      Object.assign(o, { [key]: local[key].map((item) => item?.user_slug) });
    });
    setCategoryUsers(local);
    setAddedUsers(o);
  }, [data]);

  useEffect(() => {
    setAllUsers(assessmentUsers);
  }, [assessmentUsers]);

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    categoryRef.current = addedUsers;
  }, [categoryRef, addedUsers]);

  useEffect(() => {
    dispatch(getCompany(assessment?.company)).then((res) => {
      if (res.error) {
        return message.error('General server error');
      }
      const modified = res.data.employees.map((item) => {
        const name = item?.first_name;
        const lastName = item?.last_name;
        const label = (name && lastName) ? `${name} ${lastName}` : item?.email;
        return { label, value: item?.slug, ...item };
      });
      return setEmployees(modified);
    });
    return () => {
      setEmployees([]);
      setAllUsers([]);
      setCategoryUsers({});
      setAddedUsers({});
    };
  }, [assessment.company, id, dispatch]);

  useEffect(() => {
    sortUsers(allUsers);
  }, [assessment.slug, sortUsers, allUsers]);

  return (
    <>
      <ModalConfirm
        visible={deleteModal.isVisible}
        onOk={deleteUserFromDataBase}
        onCancel={closeDeleteModal}
        text='Sind sie sicher ?'
        title='Benutzer löschen'
      />
      {addUserModal?.isVisible && (
      <AddUserForm
        onClose={onClose}
        formRef={formRef}
        data={addUserModal}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
        isAssessment
      />
      )}

      <Collapse>
        { source }
      </Collapse>
    </>
  );
};

Categories.propTypes = {
  data: PropTypes.array.isRequired,
  categoryRef: PropTypes.object,
};
Categories.defaultProps = {
  categoryRef: { current: null },
};
export default Categories;
