import PropTypes from 'prop-types';

import { UserOutlined } from '@ant-design/icons';

import styles from '../index.module.scss';

const UserAssessmentProgress = ({
  fullName,
  percent,
}) => (
  <>
    <div style={{ textAlign: 'center', marginBottom: '5%' }}>
      <UserOutlined className={styles.userIcon} />
    </div>
    <p style={{ textAlign: 'center', fontSize: '15px', color: 'black' }}>{fullName}</p>
    <p style={{ textAlign: 'center', fontSize: '20px', color: 'black' }}>
      {percent}
      {' '}
      %
    </p>
  </>
);

UserAssessmentProgress.propTypes = {
  fullName: PropTypes.string,
  percent: PropTypes.string,
};
UserAssessmentProgress.defaultProps = {
  fullName: '',
  percent: '',
};

export default UserAssessmentProgress;
