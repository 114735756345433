import PropTypes from 'prop-types';

import {
  useCallback, useEffect, useState,
} from 'react';
import {
  NavLink, useHistory, useLocation, useParams,
} from 'react-router-dom';

import {
  Card, Col, List, Breadcrumb, Image, Table, Checkbox,
} from 'antd';

import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import styles from '../index.module.scss';

// eslint-disable-next-line max-len
import QuestionnaireConfiguration from '../../../components/common/QuestionnaireConfiguration/QuestionnaireConfiguration';
import { updateAssessment } from '../../../store/assessments/actions';
import { responseHandler } from '../../../utils/helpers';

const Questionnaire = ({ data, onChangeState, updateTemplates }) => {
  const { customerSlug } = useParams();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [props, setProps] = useState({});
  const [visible, setVisible] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([{
    to: `/project-overview/${customerSlug}`,
    title: 'Fragenkatalog',
  }]);

  const [dataSource, setSource] = useState([]);
  const [hideWeight, setWeight] = useState({
    PERFORMANCE_CHECK: false,
    DEEP_DIVE: false,
  });

  const handleChangeWeight = useCallback((value) => {
    const [[key, val]] = Object.entries(value);
    const payload = { is_weighting_active: val };
    Object.assign(payload, { slug: data?.[0]?.types.find((item) => key === item.type).slug });
    dispatch(updateAssessment(payload)).then((res) => {
      if (res.error) {
        responseHandler(res);
      } else {
        setWeight((s) => ({ ...s, ...value }));
      }
    });
  }, [data, dispatch]);
  const onValueChanged = (e, assessmentType) => {
    handleChangeWeight({ [assessmentType]: e.target.checked });
    const templates = data.map((category) => {
      const types = category.types.map((typ) => {
        if (typ.type === assessmentType) {
          return { ...typ, is_weighting_active: e.target.checked };
        }
        return typ;
      });
      return { ...category, types };
    });
    updateTemplates(templates);
  };

  const handleChangeCategory = useCallback((obj) => {
    history.push(`/project-overview/${customerSlug}?category=${obj.category || obj.slug}`,
      { category: obj.category || obj.slug, title: obj.title || obj.name });
  }, [customerSlug, history]);

  const renderPerformanceCheck = () => (
    <div className={styles.customerTableHeader}>
      <span>Performance Check</span>
      <Checkbox
        checked={hideWeight.PERFORMANCE_CHECK}
        onChange={(e) => onValueChanged(e, 'PERFORMANCE_CHECK')}
      >
        Gewichtung
      </Checkbox>
    </div>
  );
  const renderStatusAnalise = () => (
    <div className={styles.customerTableHeader}>
      <span>Statusanalyse</span>
      <Checkbox
        checked={hideWeight.DEEP_DIVE}
        onChange={(e) => onValueChanged(e, 'DEEP_DIVE')}
      >
        Gewichtung
      </Checkbox>
    </div>
  );

  const columns = [
    {
      title: '',
      dataIndex: 'index',
      key: 'index',
      width: '5%',
      render: (number, record) => (
        <Image
          preview={false}
          src={record.image[0] === '/' ? record.image : `/${record.image}`}
          alt='Icon'
          className={styles.img}
        />
      ),
    },
    {
      title: 'Kategorien',
      dataIndex: 'title',
      key: 'title',
      render: (kategorien) => (
        <span>{kategorien}</span>
      ),
    },
    {
      title: renderPerformanceCheck,
      dataIndex: 'performance_check',
      key: 'performance_check',
      width: '20%',
      render: (performanceCheck) => (
        <span>{performanceCheck}</span>
      ),
    },
    {
      title: renderStatusAnalise,
      dataIndex: 'deepdive',
      key: 'deepdive',
      width: '20%',
      render: (deepdive) => (
        <span>{deepdive}</span>
      ),
    },
  ];

  const renderCategory = useCallback((item, index) => {
    const src = item.image[0] === '/' ? item.image : `/${item.image}`;
    return (
      <div
        key={index.toString()}
        role='presentation'
        onClick={() => handleChangeCategory(item)}
        className={classNames(styles.categoryImage,
          { [styles.activeCategory]: location?.state?.category === item.slug })}
      >
        <Col>
          <Image
            preview={false}
            src={src}
            alt='Icon'
            className={styles.img}
          />
        </Col>
      </div>
    );
  }, [location, handleChangeCategory]);

  useEffect(() => {
    if (data.length) {
      data[0].types.forEach((item) => {
        setWeight((s) => ({
          ...s, [item.type]: item?.is_weighting_active || false,
        }));
      });
    }
  }, [data]);

  useEffect(() => {
    setSource(data.map((item, index) => ({
      index,
      image: item.image,
      title: item.name,
      category: item.slug,
      performance_check: item.types.find((i) => i.type === 'PERFORMANCE_CHECK').questions.length,
      deepdive: item.types.find((i) => i.type === 'DEEP_DIVE').questions.length,
    })));
  }, [data]);

  useEffect(() => {
    if (location.search.length && data.length) {
      const searchParams = new URLSearchParams(location.search);
      const categorySlug = searchParams.get('category');
      const category = data.find((item) => item.slug === categorySlug);

      setProps({
        ...category, category: categorySlug, hideWeight, onChangeState,
      });

      const result = [{ to: `/project-overview/${customerSlug}`, title: 'Fragenkatalog' },
        { to: '#', title: category.name }];
      setBreadcrumbs(result);
      setVisible(true);
    } else {
      setVisible(false);
      setBreadcrumbs([{ to: `/project-overview/${customerSlug}`, title: 'Fragenkatalog' }]);
    }
  }, [onChangeState, hideWeight, data, customerSlug, location]);

  return (
    <div style={{ minHeight: `${window.innerHeight - 200}px` }}>
      <Breadcrumb className={styles.breadcrumbs}>
        {breadcrumbs.map((item, idx) => (
          <Breadcrumb.Item key={idx.toString()}>
            { item.to !== '#'
              ? <NavLink to={`${item.to}`}>{item.title}</NavLink>
              : <span>{item.title}</span>}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
      {!visible
        ? (
          <Card title='Bewertungskategorien' bordered={false} className={styles.assessmentCard}>
            <List>
              <Table
                className={styles.questionnaireTable}
                dataSource={dataSource}
                columns={columns}
                rowKey='index'
                pagination={false}
                onRow={(record) => ({
                  onClick: () => handleChangeCategory(record),
                })}
              />
            </List>
          </Card>
        )
        : (
          <QuestionnaireConfiguration {...props}>
            <Col span={2} className={styles.categories}>
              <Col style={{ height: '58px' }} />
              {data.map(renderCategory)}
            </Col>
          </QuestionnaireConfiguration>
        )}
    </div>
  );
};

Questionnaire.propTypes = {
  data: PropTypes.array,
  headline: PropTypes.string,
  onChangeState: PropTypes.func,
  updateTemplates: PropTypes.func,
};
Questionnaire.defaultProps = {
  data: [],
  headline: '',
  onChangeState: () => {},
  updateTemplates: () => [],
};

export default Questionnaire;
