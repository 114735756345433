import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Col, PageHeader, Row,
} from 'antd';

import ProfileForm from './components/ProfileForm';
import { updateUser } from '../../store/auth/actions';
import { userSelector } from '../../store/auth/selectors';
import TwoFaDialog from './components/TwoFaDialog';

import styles from './index.module.scss';

const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [visible, setVisible] = useState(false);

  const handleSubmit = useCallback(async (formData) => {
    dispatch(updateUser(formData));
  }, [dispatch]);

  return (
    <>
      <PageHeader title='Einstellungen' className={styles.settingsPageHeader}>
        <Button type='primary' onClick={() => setVisible(true)}>
          {user?.is_2fa_enabled ? '2FA Einstellungen' : '2FA aktivieren'}
        </Button>
      </PageHeader>
      <Row justify='center' style={{ backgroundColor: 'white' }} className={styles.form}>
        <Col xs={24} sm={24} md={16} lg={12} xl={12} xxl={12}>
          <ProfileForm
            user={user}
            onSubmit={handleSubmit}
          />
          <TwoFaDialog
            user={user}
            visible={visible}
            setVisible={setVisible}
          />
        </Col>
      </Row>
    </>

  );
};

export default Profile;
