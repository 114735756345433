import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { message } from 'antd';
import {
  createAssessmentVersionWithFile,
  fetchAssessmentConfig, updateAssessment,
  updateAssessmentVersion, updateQuestion, updateTemplateQuestion, updateTemplates,
} from '../../../../store/assessments/actions';
import { responseHandler } from '../../../../utils/helpers';

export default function useActions({ onChangeState }) {
  const dispatch = useDispatch();

  const updateOrCreateAssessmentVersionsWithFile = useCallback(async (types, keys, o) => {
    let error = false;
    const performanceCheck = types.find((item) => item.slug === keys[0]);
    const deepDive = types.find((item) => item.slug === keys[1]);
    if (performanceCheck.versions.length) {
      const fileSlug = performanceCheck.versions[0].slug;
      await dispatch(updateAssessmentVersion({
        slug: keys[0], version: o[keys[0]], file: o[keys[2]], fileSlug,
      })).then((res) => {
        if (res.error) {
          responseHandler(res);
          error = true;
        }
      });
    } else {
      await dispatch(createAssessmentVersionWithFile({
        slug: keys[0],
        version: o[keys[0]],
        file: o[keys[2]],
      })).then((res) => {
        if (res.error) {
          responseHandler(res);
          error = true;
        }
      });
    }
    if (deepDive.versions.length) {
      const fileSlug = deepDive.versions[0].slug;
      !error && await dispatch(updateAssessmentVersion({
        slug: keys[1], version: o[keys[1]], file: o[keys[2]], fileSlug,
      })).then((res) => {
        if (res.error) {
          responseHandler(res);
          error = true;
        }
      });
    } else {
      !error && await dispatch(createAssessmentVersionWithFile({
        slug: keys[1],
        version: o[keys[1]],
        file: o[keys[2]],
      })).then((res) => {
        if (res.error) {
          responseHandler(res);
          error = true;
        }
      });
    }

    !error && await dispatch(updateTemplates()).then((response) => {
      if (response.error) {
        responseHandler(response);
        error = true;
      }
    });
    !error && await dispatch(fetchAssessmentConfig()).then((responseConf) => {
      if (responseConf.error) {
        responseHandler(responseConf);
        error = true;
      }
    });
    return { error };
  }, [dispatch]);

  const updateAssessmentVersionsAndTemplates = useCallback(async (slugs, versions) => {
    let error = false;
    const { deepDiveSlug, perfomanceCheckSlug } = slugs;
    const { deepDiveVers, perfomanceCheckVers } = versions;

    await dispatch(updateAssessment({ slug: deepDiveSlug, version: deepDiveVers })).then((res) => {
      if (res.error) {
        responseHandler(res);
        error = true;
      }
    });

    !error && await dispatch(updateAssessment({ slug: perfomanceCheckSlug, version: perfomanceCheckVers }))
      .then(async (resp) => {
        if (resp.error) {
          error = true;
          responseHandler(resp);
        }
      });

    !error && await dispatch(updateTemplates()).then((updatedResp) => {
      if (updatedResp.error) {
        error = true;
        responseHandler(updatedResp);
      }
    });
    return { error };
  }, [dispatch]);

  const handleChangeWeighting = useCallback((e, record) => {
    const number = (e.target.value.charAt(0) === '0' && e.target.value.length >= 2)
      ? e.target.value.slice(1)
      : e.target.value;
    dispatch(updateQuestion({
      slug: record.slug,
      logic: {
        ...record.logic,
        weight: e.target.value === ''
          ? 0
          : number,
      },
    })).then((res) => {
      if (res.error) {
        return message.error('Genereller Fehler!');
      }
      if (onChangeState) {
        onChangeState({ ...res.data, assessment_type: record.assessment_type });
      }
      dispatch(updateTemplateQuestion({ ...res.data, assessment_type: record.assessment_type }));
      return message.success('Änderungen wurden erfolgreich gespeichert');
    });
  }, [onChangeState, dispatch]);

  return {
    updateAssessmentVersionsAndTemplates,
    updateOrCreateAssessmentVersionsWithFile,
    handleChangeWeighting,
  };
}
