import PropTypes from 'prop-types';

import { useState } from 'react';

import {
  Row, Col, Table, Button, Input,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { ALPHABET } from '../../../../utils/constants';

import styles from '../../index.module.scss';

const AnswerOptions = ({ onChange, dataRef }) => {
  const [source, setSource] = useState(dataRef.current.answer_options);

  const handleChangeValue = (e, record) => {
    const result = source.map((item) => {
      if (item.idx === record.idx) {
        return {
          ...item,
          option: { ...item.option, value: e.target.value },
        };
      }
      return item;
    });
    setSource(result);
    onChange({ answer_options: result });
  };

  const columns = [
    {
      title: 'Antwortmöglichkeiten',
      dataIndex: 'option',
      key: 'option',
      render: (a, record) => (
        <Row align='middle' className={styles.answerOptions}>
          <Col>{record.option?.letter}</Col>
          <Col span={10}>
            <Input
              data-cy={`answer${record.option?.letter}`}
              type='text'
              value={record.option?.value}
              onChange={(e) => handleChangeValue(e, record)}
            />
          </Col>
        </Row>
      ),
    },
  ];

  const addAnswer = () => {
    setSource(source.concat({
      idx: source.length,
      option: { letter: ALPHABET[source.length], value: '' },
      skip: false,
    }));
  };

  return (
    <div>
      <Row align='middle'>
        <Col span={24}>
          <Table
            dataSource={source}
            columns={columns}
            rowKey='idx'
            pagination={false}
            className={styles.noBorder}
          />
        </Col>
      </Row>
      <Row justify='center'>
        <Col>
          <Button
            data-cy='addAnswer'
            disabled={source.length === 4}
            className={styles.addBtn}
            onClick={addAnswer}
            icon={<PlusOutlined />}
          />
        </Col>
      </Row>
    </div>
  );
};
AnswerOptions.propTypes = {
  onChange: PropTypes.func.isRequired,
  dataRef: PropTypes.object,
};
AnswerOptions.defaultProps = {
  dataRef: {},
};
export default AnswerOptions;
